import { ButtonGroup, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from '@mui/material/Button';
import {
    AddToCartButton,
    AddToCartButtonGroup,
    AddToCartContainer,
    ButtonQuantity,
    ButtonRemoveIcon,
} from './ProductStyle';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

const AddToCart = ({
    productQuantity,
    setProductQuantity,
    cartLoading,
    selectedVariantIsAvailable,
    handleAddToCart,
}: any) => {
    const isMobile = useCheckMobileScreen();
    return (
        <Stack spacing={2} sx={{ ...AddToCartContainer, marginTop: isMobile ? '' : '1rem !important' }}>
            <ButtonGroup
                variant="text"
                disableElevation
                size="small"
                color="inherit"
                sx={AddToCartButtonGroup}
            >
                <Button
                    aria-label="reduce"
                    variant="text"
                    sx={ButtonRemoveIcon}
                    onClick={() => {
                        if (productQuantity > 1) {
                            setProductQuantity(productQuantity - 1);
                        }
                    }}
                >
                    <RemoveIcon fontSize="small" sx={{ color: 'rgba(20, 20, 30, 1)' }} />
                </Button>
                <Button disableTouchRipple size="large" variant="text" sx={ButtonQuantity}>
                    {productQuantity}
                </Button>
                <Button
                    aria-label="increase"
                    variant="text"
                    onClick={() => {
                        setProductQuantity(productQuantity + 1);
                    }}
                >
                    <AddIcon fontSize="small" sx={{ color: 'rgba(20, 20, 30, 1)' }} />
                </Button>
            </ButtonGroup>
            <Button
                size="large"
                startIcon={<ShoppingCartIcon />}
                fullWidth
                disabled={cartLoading || !selectedVariantIsAvailable}
                variant="contained"
                color={'primary'}
                disableElevation
                onClick={handleAddToCart}
                sx={AddToCartButton}
            >
                {cartLoading ? 'Adding...' : 'Add to cart'}
            </Button>
        </Stack>
    );
};

export default AddToCart;
