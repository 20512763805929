import { Box, Grid, Stack, Fab, Typography } from '@mui/material';
import Icon from '../../component/icon';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Column,
    Copyright,
    Divider,
    Dot,
    FooterContainer,
    NavItem,
    Line,
    FooterItem,
    Support,
    Title,
    VhDivider,
    Social,
    SupportLink,
    SupportTime,
    MainContainer,
} from './footerStyle';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isPhone = useCheckMobileScreen();

    const handleNavItem = () => {
        handleScrollToTop();
    };

    const handleCustomerSupport = () => {
        navigate('/support');
        handleScrollToTop();
    };

    const isTermsOrPrivacyPage =
        location.pathname === '/terms-conditions' || location.pathname === '/privacy-policy';

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <MainContainer>
                {!(isPhone && location.pathname.includes('order')) && (
                    <FooterContainer>
                        <Icon name="logo-yellow" />
                        <Title>
                            Sniffy Nose is one stop solution for all your pet needs, providing all
                            the services at your door-step.
                        </Title>
                        <FooterItem></FooterItem>

                        <Grid
                            container
                            maxWidth={'md'}
                            spacing={3}
                            justifyContent={'center'}
                            justifyItems={'center'}
                            alignContent={'center'}
                            alignItems={'center'}
                        >
                            <Grid container item maxWidth={'sm'}>
                                <Grid
                                    item
                                    xs={5}
                                    md
                                    display={'flex'}
                                    justifyContent={isPhone ? 'center' : 'flex-end'}
                                >
                                    <Column
                                        justifyContent={'center'}
                                        justifyItems={'center'}
                                        alignContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <NavItem>Visit our</NavItem>
                                        <Support onClick={handleCustomerSupport}>
                                            Customer support
                                        </Support>
                                    </Column>
                                </Grid>
                                <Grid item xs={2} md={2} display={'flex'} justifyContent={'center'}>
                                    <VhDivider />
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    md
                                    display={'flex'}
                                    justifyContent={isPhone ? 'center' : 'flex-start'}
                                >
                                    <Column
                                        justifyContent={'center'}
                                        justifyItems={'center'}
                                        alignContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <NavItem>
                                            <Typography>Call us: </Typography>
                                            <Typography sx={SupportTime}>
                                                9AM - 7PM support
                                            </Typography>
                                        </NavItem>
                                        <SupportLink to={'tel:9359939912'}>
                                            +91 93599 - 39912
                                        </SupportLink>
                                    </Column>
                                </Grid>
                            </Grid>
                            <Grid container item maxWidth={'sm'}>
                                <Grid
                                    item
                                    xs={5}
                                    md
                                    display={'flex'}
                                    justifyContent={isPhone ? 'center' : 'flex-end'}
                                    justifyItems={'center'}
                                    alignContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Column
                                        justifyContent={'center'}
                                        justifyItems={'center'}
                                        alignContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <NavItem
                                            component={Link}
                                            to={'/terms-conditions'}
                                            onClick={handleNavItem}
                                        >
                                            Terms & Conditions
                                        </NavItem>
                                    </Column>
                                </Grid>
                                <Grid item xs={2} md={2} display={'flex'} justifyContent={'center'}>
                                    <VhDivider />
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    md
                                    display={'flex'}
                                    justifyContent={isPhone ? 'center' : 'flex-start'}
                                >
                                    {' '}
                                    <Column
                                        justifyContent={'center'}
                                        justifyItems={'center'}
                                        alignContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <NavItem
                                            component={Link}
                                            to={'/privacy-policy'}
                                            onClick={handleNavItem}
                                        >
                                            Privacy Policy
                                        </NavItem>
                                    </Column>
                                </Grid>
                            </Grid>
                            {isTermsOrPrivacyPage && (
                                <Grid
                                    item
                                    width={'100%'}
                                    justifyContent={'flex-end'}
                                    display={'flex'}
                                >
                                    <Fab
                                        size="small"
                                        sx={{ zIndex: 100 }}
                                        aria-label="scroll back to top"
                                        onClick={handleScrollToTop}
                                    >
                                        <KeyboardArrowUpIcon />
                                    </Fab>
                                </Grid>
                            )}
                        </Grid>
                        <Grid xs={12} item width={'100%'}>
                            <Divider>
                                <Dot />
                                <Line />
                                <Dot />
                            </Divider>
                        </Grid>

                        <Copyright>
                            <Social>
                                Follow us on:
                                <Link
                                    to={'https://www.facebook.com/sniffynose.sn/'}
                                    target="_blank"
                                >
                                    <Icon name="facebook" />
                                </Link>
                                <Link
                                    to={'https://www.youtube.com/channel/UCIL2YBN6TXEOSh8SctqMkGA'}
                                    target="_blank"
                                >
                                    <Icon name="youtube" />
                                </Link>
                                <Link
                                    to={'https://www.instagram.com/sniffynose.sn/'}
                                    target="_blank"
                                >
                                    <Icon name="instagram" />
                                </Link>
                            </Social>
                            <Box style={{ paddingLeft: '10px' }}>
                                Copyright © 2023 - sniffynose.com | All Rights Reserved
                            </Box>
                        </Copyright>
                    </FooterContainer>
                )}
            </MainContainer>
        </>
    );
};

export default Footer;
