import request from './request';

export function getCoupons(params?: any) {
    return request({
        url: `coupons`,
        method: 'get',
        params
    });
}

export function getPublicCoupons(params?: any) {
    return request({
        url: `coupons/search`,
        method: 'get',
        params
    });
}

export function getCartCoupons(params?: any) {
    return request({
        url: `carts/coupons`,
        method: 'get',
        params
    });
}