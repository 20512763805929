import {
    Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { errorStyle } from '../view/bookService/addressDetails/addressStyle';
import { Field } from 'formik';
import { states } from '../view/order/utils';

function AddAddress({ touched, errors, values }: any) {
    const isMobile = useCheckMobileScreen();

    return (
        <>
            <Collapse in={values.is_delivery}>
                <Grid
                    container
                    gap={isMobile ? '20px' : ''}
                    spacing={2}
                    mt={2.5}
                    ml={0}
                    width={'100%'}
                >
                    <Grid xs={12} md={6}>
                        <Field
                            name="address.address_line_one"
                            label="Flat, House no, Apartment"
                            required={values.is_delivery}
                            as={TextField}
                            size={isMobile ? 'small' : 'medium'}
                            sx={{
                                ...errorStyle,
                                width: '100%',
                                paddingRight: isMobile ? '' : '20px',
                            }}
                            error={
                                touched.address?.address_line_one &&
                                Boolean(errors.address?.address_line_one)
                            }
                            helperText={
                                touched.address?.address_line_one &&
                                errors.address?.address_line_one
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Field
                            name="address.address_line_two"
                            label="Area, Street, Village"
                            required={values.is_delivery}
                            as={TextField}
                            size={isMobile ? 'small' : 'medium'}
                            error={
                                touched.address?.address_line_two &&
                                Boolean(errors.address?.address_line_two)
                            }
                            helperText={
                                touched.address?.address_line_two &&
                                errors.address?.address_line_two
                            }
                            fullWidth
                            sx={errorStyle}
                        />
                    </Grid>
                    <Grid xs={12} md={3} sx={{ paddingTop: isMobile ? '' : '20px' }}>
                        <Field
                            name="address.pincode"
                            label="Pin Code"
                            required={values.is_delivery}
                            as={TextField}
                            size={isMobile ? 'small' : 'medium'}
                            error={touched.address?.pincode && Boolean(errors.address?.pincode)}
                            helperText={touched.address?.pincode && errors.address?.pincode}
                            fullWidth
                            sx={{ ...errorStyle, paddingRight: isMobile ? '' : '20px' }}
                        />
                    </Grid>
                    <Grid xs={12} md={4} sx={{ paddingTop: isMobile ? '' : '20px' }}>
                        <Field
                            name="address.city"
                            label="Town/City"
                            required={values.is_delivery}
                            as={TextField}
                            size={isMobile ? 'small' : 'medium'}
                            error={touched.address?.city && Boolean(errors.address?.city)}
                            helperText={touched.address?.city && errors.address?.city}
                            fullWidth
                            sx={{ ...errorStyle, paddingRight: isMobile ? '' : '20px' }}
                        />
                    </Grid>
                    <Grid xs={12} md={5} sx={{ paddingTop: isMobile ? '' : '20px' }}>
                        <FormControl fullWidth size={isMobile ? 'small' : 'medium'}>
                            <InputLabel id="state-select" required={values.is_delivery}>
                                State
                            </InputLabel>
                            <Field
                                labelId="state-select"
                                label="State"
                                name="address.state"
                                as={Select}
                                error={touched.address?.state && Boolean(errors.address?.state)}
                                helpText={touched.address?.state && errors.address?.state}
                            >
                                {states.map(state => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
}

export default AddAddress;
