import Image from "../../component/image/image";
import {
  PetContainer,
  PetBox,
  PetIndex,
  PetName,
  DateAndAddressBox,
  NameAndType,
  ServiceDetails,
  ServiceDetailsTitle,
  PetService,
  TextDetails,
  PriceDetailsBox,
  PriceDetailsTitle,
  PriceBoxs,
  PriceTotalText,
  PriceSlightly,
  DateBox,
  AddressBox,
  PricingItem,
  SpanButton,
} from "./confirmStyle";
import calendar from "../../assets/images/calendar.svg";
import address from "../../assets/images/address.svg";
import info from "../../assets/images/info.svg";
import { cities, states } from "../bookService/utils";
import moment from "moment";

const PetDetails = ({ serviceDetails, isMobile }: any) => {
  const getAddress = () => {
    const { address } = serviceDetails;
    const { locationType, selectedAddress } = address;

    if (locationType === "grooming_center") {
      return selectedAddress.address;
    } else if (locationType === "my_place") {
      const fonudState = states.find(
        (value: any) => value.id === address.selectedState
      );
      const fonudCity = cities.find(
        (value: any) => value.id === address.selectedCity
      );
      return `${address.address_1}, ${address.locality}, ${fonudState?.name}, ${fonudCity?.name}, ${address.pinCode}`;
    }
  };

  const getTotalCostForPet = (pet: any) => {
    const serviceCost = Number(pet.service.cost);
    const addOnsCost = pet.addOns.reduce(
      (acc: number, item: any) => acc + Number(item.cost),
      0
    );
    return serviceCost + addOnsCost;
  };

  const getTotalCostForAllPets = () => {
    return serviceDetails.pets?.reduce(
      (acc: number, pet: any) => acc + getTotalCostForPet(pet),
      0
    );
  };

  const getTotalItem = () =>
    serviceDetails?.pets?.reduce(
      (acc: number, pet: any) => acc + (pet?.addOns?.length || 0),
      0
    );

  return (
    <div style={{ width: "100%" }}>
      {serviceDetails.pets?.map((pet: any, i: any) => {
        return (
          <>
            <PetContainer>
              <PetBox>
                <PetIndex>#{1 + i} Pet</PetIndex>
                <PetName>
                  {pet.name || pet.petType.name || pet.petBreed.name}
                </PetName>
                <DateAndAddressBox>
                  <Image src={calendar} alt="image" />
                  <NameAndType>Date & Time</NameAndType>
                  {!isMobile && (
                    <DateBox>
                      {moment(pet.date).format("ll")} & {pet.timeSlot.time}
                    </DateBox>
                  )}
                </DateAndAddressBox>
                {isMobile && (
                  <DateBox>
                    {moment(pet.date).format("ll")} & {pet.timeSlot.time}
                  </DateBox>
                )}
                <DateAndAddressBox>
                  <Image src={address} alt="image" />
                  <NameAndType>Address</NameAndType>
                  {!isMobile && <AddressBox>{getAddress()}</AddressBox>}
                </DateAndAddressBox>
                {isMobile && <AddressBox>{getAddress()}</AddressBox>}
                <ServiceDetails>
                  <ServiceDetailsTitle>Services</ServiceDetailsTitle>
                  <PetService>
                    <TextDetails>{pet.service.name}</TextDetails>
                    <TextDetails>₹{pet.service.cost}</TextDetails>
                  </PetService>
                  <div style={{ width: "100%" }}>
                    {pet.addOns.map((item: any) => (
                      <PetService>
                        <TextDetails>
                          {item.name}

                          <SpanButton>Add Ons</SpanButton>
                        </TextDetails>
                        <TextDetails>₹{item.cost}</TextDetails>
                      </PetService>
                    ))}
                  </div>
                </ServiceDetails>
              </PetBox>
            </PetContainer>
          </>
        );
      })}

      <PriceDetailsBox>
        <div style={{ margin: "0 1rem" }}>
          <PriceDetailsTitle>
            Price Details ({getTotalItem()} item)
          </PriceDetailsTitle>
          <PriceTotalText>
            {serviceDetails.pets?.map((pet: any, i: any) => {
              return (
                <PriceBoxs>
                  <PricingItem>
                    #{1 + i} Pet {pet.name}
                  </PricingItem>
                  <PricingItem>₹{getTotalCostForPet(pet)} </PricingItem>
                </PriceBoxs>
              );
            })}
          </PriceTotalText>
          <PriceBoxs>
            <PriceTotalText>Total Price</PriceTotalText>
            <PriceTotalText>₹{getTotalCostForAllPets()}</PriceTotalText>
          </PriceBoxs>
          <PriceSlightly>
            <Image src={info} alt="image" />* prices might slightly change
          </PriceSlightly>
        </div>
      </PriceDetailsBox>
    </div>
  );
};

export default PetDetails;
