import { Box, Typography } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { filtersStyle } from "./productStyle";
const FilterTag = ({removeFilter, title, filterType}:any) =>{

    return(
        <Box sx={filtersStyle}>
            <Typography>{title}</Typography>
            <CloseIcon
                sx={{
                    color: '#4C4C4C',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                }}
                onClick={e => removeFilter(filterType || '')}
            />
        </Box>
    )
}

export default FilterTag;