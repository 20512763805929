export const appColor = {
    textColor: "#14141E",
    black: "#000000",
    black1: "#18141c",
    black2: "#252535",
    black3: "#808490",
    black4: "#191A25",
    black5: "#1D1D2D",
    black6: "#201c1c",
    black7: "#333333",
    black8: "#14141E",
    white: "#FFFFFF",
    yellow: "#FDDC2E",
    yellow1: "#FFD600",
    grey1: "#F6F6F6",
    grey2: "#8D98B1",
}