import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress, InputAdornment, Typography } from '@mui/material';
import { consumeSignInUpCode, getSignInUpCode } from '../../service/api/auth';
import { useMutation } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import {
    ChangePhoneBox,
    ChangePhoneIcon,
    CloseBox,
    DisclaimerText,
    IndianNumber,
    InputField,
    LogoBox,
    UserLoginModalStyle,
    OtpInfo,
    UserLoginPhoneInfo,
    ResendBox,
    ResendButton,
    ResendSpan,
    TermAndPolicy,
    WelcomeText,
    UserLoginModal,
    StyledLink,
} from './LoginStyle';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserAction } from '../../service/action/userActions';
import LoginFrame from '../../assets/icons/login-frame.svg';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { getCartsAction } from '../../service/action/cart/cartActions';

export default function LoginSignup({ handleClose, setOpen, open, setAuthToken }: any) {
    const [isInvalidOTP, setIsInvalidOTP] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const [canResend, setCanResend] = useState(false);
    const [deviceId, setDeviceId] = useState('');
    const [preAuthSessionId, setPreAuthSessionId] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [showOTP, setShowOTP] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        if (!canResend) {
            timer = setInterval(() => {
                setResendTimer(prev => {
                    if (prev === 1) {
                        clearInterval(timer);
                        setCanResend(true);
                        return 30;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [canResend]);

    const sendOTPFormMut = useMutation({
        mutationFn: (data: any) => getSignInUpCode(data),
        onSuccess: (res: any) => {
            res = res.data;
            if (res.status === 'OK') {
                setDeviceId(res.deviceId);
                setPreAuthSessionId(res.preAuthSessionId);
                setShowOTP(true);
                setCanResend(false);
                setOpen(true);
            } else {
                throw new Error('Failed to send OTP');
            }
        },
    });

    const sendOTP = ({ phoneNumber }: any) => {
        setPhoneNumber(phoneNumber);
        phoneNumber = `+91${phoneNumber}`;
        const result = sendOTPFormMut.mutate({ phoneNumber });
        return result;
    };

    const resendOTP = ({ phoneNumber }: any) => {
        if (!verifyOTPFormMut.isPending) {
            sendOTP({ phoneNumber });
        }
    };

    const verifyOTPFormMut = useMutation({
        mutationFn: (data: any) => consumeSignInUpCode(data),
        onSuccess: (res: any) => {
            const { data, headers } = res;

            if (res?.data?.status == 'OK') {
                // Store tokensFF
                localStorage.setItem('accessToken', headers['st-access-token']);
                localStorage.removeItem('tempAccessToken');

                setIsInvalidOTP(false);
                setOpen(false);
                setAuthToken(headers['st-access-token']);
                dispatch(getUserAction({})); // Get user details after login
                dispatch(getCartsAction({})); // Get cart details after user login
                navigate('/products');
            } else {
                setIsInvalidOTP(true);
                if (res.data.status === 'RESTART_FLOW_ERROR') {
                }
            }
        },
        onError: (res: any) => {
            setIsInvalidOTP(true);
        },
    });

    const submitVerifyOtp = async (values: any) => {
        const payload = {
            userInputCode: values.otp,
            deviceId,
            preAuthSessionId,
        };

        const result = verifyOTPFormMut.mutate(payload);
        return;
    };

    const closeModal = () => {
        if (!verifyOTPFormMut.isPending) {
            setOpen(false);
        }
    };

    const changePhoneNumber = () => {
        if (!verifyOTPFormMut.isPending) {
            setShowOTP(false);
        }
    };

    return (
        <Modal
            disableEscapeKeyDown
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Box sx={UserLoginModalStyle}>
                <Box sx={{ width: isMobile ? '100%' : 'auto' }}>
                    <img
                        src={LoginFrame}
                        alt=""
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: isMobile ? '' : '24px 0 0 24px',
                        }}
                    />
                    {isMobile && (
                        <Grid item xs={12} sx={LogoBox}>
                            <Box
                                sx={{
                                    ...CloseBox,
                                    background: '#fff',
                                    borderRadius: '18px',
                                    padding: '8px',
                                    height: '40px',
                                }}
                            >
                                <CloseIcon
                                    sx={{ color: 'rgba(9, 18, 31, 1)' }}
                                    onClick={closeModal}
                                />
                            </Box>
                        </Grid>
                    )}
                </Box>

                <Box sx={UserLoginModal}>
                    {!isMobile && (
                        <Grid item xs={12} sx={LogoBox}>
                            <Box sx={CloseBox}>
                                <CloseIcon
                                    sx={{ color: 'rgba(9, 18, 31, 1)' }}
                                    onClick={closeModal}
                                />
                            </Box>
                        </Grid>
                    )}
                    <Box>
                        {showOTP ? (
                            <Box sx={OtpInfo}>
                                <Typography sx={WelcomeText}>
                                    Get verified, Enter the code sent to +91-
                                    {phone}
                                </Typography>

                                <Typography sx={UserLoginPhoneInfo}>
                                    Verify contact number to access rewards and offers.
                                </Typography>

                                <Button
                                    onClick={changePhoneNumber}
                                    sx={{
                                        ...ChangePhoneBox,
                                        gap: '5px',
                                        justifyContent: 'start',
                                        width: '160px',
                                        paddingLeft: 0,
                                    }}
                                >
                                    <Icon color="#0A69D9" icon="mynaui:edit-one" />
                                    Change Phone no.
                                </Button>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    ...OtpInfo,
                                    mb: { xs: 5, sm: 0.5, md: '2.5rem' },
                                }}
                            >
                                <Typography sx={WelcomeText}>
                                    Welcome to India’s one stop pet solution
                                </Typography>
                                <Typography sx={UserLoginPhoneInfo}>
                                    Log in or Sign up, no password required
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <Formik
                        initialValues={{
                            phone: '',
                            otp: '',
                            submit: null,
                        }}
                        validationSchema={Yup.object().shape({
                            phone: Yup.string()
                                .matches(/^[0-9]{10}$/, 'Must be a valid phone number')
                                .required('Phone number is required'),
                            otp: showOTP
                                ? Yup.string()
                                      .matches(/^[0-9]{6}$/, 'Must be a valid OTP')
                                      .required('OTP is required')
                                : Yup.string(),
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                if (!showOTP) {
                                    /* remove localstorage */
                                    localStorage.removeItem('accessToken');
                                    localStorage.removeItem('refreshToken');
                                    values.otp = '';
                                    setIsInvalidOTP(false);

                                    sendOTP({ phoneNumber: values.phone });
                                    setSubmitting(false);
                                    return;
                                }

                                // Submit phone and OTP for verification
                                const res = submitVerifyOtp(values);
                            } catch (err: any) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid
                                    container
                                    spacing={3}
                                    sx={{
                                        marginTop: showOTP ? 0 : '',
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            paddingTop: '1.5rem !important',
                                            paddingBottom: '1.5rem !important',
                                        }}
                                    >
                                        {showOTP ? (
                                            <Stack spacing={1}>
                                                <OutlinedInput
                                                    id="otp-login"
                                                    type="text"
                                                    value={values.otp}
                                                    name="otp"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter OTP"
                                                    fullWidth
                                                    autoFocus={true}
                                                    error={Boolean(touched.otp && errors.otp)}
                                                    sx={InputField}
                                                />
                                                {touched.otp && errors.otp && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-otp-login"
                                                    >
                                                        {errors.otp}
                                                    </FormHelperText>
                                                )}
                                                {isInvalidOTP && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-otp-login"
                                                    >
                                                        Entered OTP is not valid.
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        ) : (
                                            <Stack spacing={1}>
                                                <OutlinedInput
                                                    id="phone-number-login"
                                                    type="text"
                                                    autoFocus={true}
                                                    value={values.phone}
                                                    name="phone"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Enter Phone Number"
                                                    fullWidth
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Typography sx={IndianNumber}>
                                                                +91
                                                            </Typography>
                                                        </InputAdornment>
                                                    }
                                                    error={Boolean(touched.phone && errors.phone)}
                                                    sx={InputField}
                                                />
                                                {touched.phone && errors.phone && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-phone-login"
                                                    >
                                                        {errors.phone}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        )}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            paddingTop: '0rem !important',
                                            paddingBottom: '0.5rem !important',
                                        }}
                                    >
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            {showOTP ? (
                                                verifyOTPFormMut.isPending ? (
                                                    <>
                                                        <CircularProgress
                                                            size={15}
                                                            sx={{
                                                                mr: 1,
                                                                padding: '14x',
                                                            }}
                                                            color="inherit"
                                                        />
                                                        <Typography
                                                            sx={{
                                                                ...ResendSpan,
                                                                padding: '5px',
                                                            }}
                                                        >
                                                            Processing
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    'Verify OTP'
                                                )
                                            ) : sendOTPFormMut.isPending ? (
                                                <>
                                                    <CircularProgress
                                                        size={15}
                                                        sx={{ mr: 1, padding: '14x' }}
                                                        color="inherit"
                                                    />
                                                    <Typography
                                                        sx={{
                                                            ...ResendSpan,
                                                            padding: '5px',
                                                        }}
                                                    >
                                                        Sending...
                                                    </Typography>
                                                </>
                                            ) : (
                                                'Send OTP'
                                            )}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {showOTP ? (
                                            <Box sx={ResendBox}>
                                                <Typography sx={ResendSpan}>
                                                    Didn’t get the OTP?
                                                </Typography>
                                                <Button
                                                    onClick={() =>
                                                        resendOTP({
                                                            phoneNumber: phone,
                                                        })
                                                    }
                                                    variant="text"
                                                    color="primary"
                                                    sx={ResendButton}
                                                >
                                                    {canResend ? (
                                                        sendOTPFormMut.isPending ? (
                                                            <>
                                                                <CircularProgress
                                                                    size={15}
                                                                    sx={{ mr: 1 }}
                                                                    color="inherit"
                                                                />
                                                                <Typography sx={ResendSpan}>
                                                                    Sending...
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            'Resend OTP'
                                                        )
                                                    ) : (
                                                        `Retry in 00:${
                                                            resendTimer < 10
                                                                ? '0' + resendTimer
                                                                : resendTimer
                                                        }`
                                                    )}
                                                </Button>
                                            </Box>
                                        ) : (
                                            <>
                                                <Typography sx={DisclaimerText}>
                                                    By continuing, you agree to our
                                                </Typography>
                                                <Box sx={TermAndPolicy}>
                                                    <StyledLink
                                                        to={'/terms-conditions'}
                                                        target="_blank"
                                                    >
                                                        Terms and Conditions
                                                    </StyledLink>{' '}
                                                    &{' '}
                                                    <StyledLink
                                                        to={'/privacy-policy'}
                                                        target="_blank"
                                                    >
                                                        Privacy Policy
                                                    </StyledLink>
                                                </Box>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
}
