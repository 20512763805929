import { useMemo, useState } from 'react';
import {
    InputField,
    InputFieldContainer,
    CommentBox,
    TimeBoxs,
    TimeDay,
    CommentBoxTitle,
    ViewDetails,
    ViewDetailsButton,
    CalendarConatiner,
    WeekScheduleConatiner,
    WeekScheduleRow,
    CalendarMonthAndYear,
    CalendarMonth,
    CalendarYear,
    WeekScheduleDate,
    DropDownBox,
    DropDownField,
    DropDownBoxMargin,
    AutoCompleteStyle,
    ServicePlanBox,
    ServicePlantitleBox,
    ServicePlanDes,
    ServicePlanCost,
    ServicePlanTitleLabel,
    TimeBoxsContainer,
    AutoCompleteBox,
    AutoCompleteCost,
    RadioStyle,
    TimeSlot,
    Package,
    ServicePlan,
    ServicePlanCostBox,
    ServiceTime,
    IncludesText,
    MainServicePlan,
} from './serviceDetailsStyle';
import Arrow from '../../../assets/images/arrow.svg';
import previousArrow from '../../../assets/images/previousArrow.svg';
import nextArrow from '../../../assets/images/nextArrow.svg';
import Image from '../../../component/image/image';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    getWeekRange,
    getShortWeekDay,
    getDay,
    getMonth,
    getYear,
    AddOns,
    BreedType,
    PetType,
    timeSlot,
    servicePlans,
    isPast,
} from '../utils';
import moment from 'moment';
import Icon from '../../../component/icon';
import Popover from '@mui/material/Popover';

const PetForm = ({ pet, index, handlePetFieldChange, isMobile }: any) => {
    const numberOfDays = isMobile ? 5 : 7;

    const [addOnCategoryPrice, setAddOnCategoryPrice] = useState({});
    const [expandedServiceId, setExpandedServiceId] = useState(false);

    const [weekRange, setWeekRange] = useState(getWeekRange(pet.date, numberOfDays));

    const petBreed = useMemo(
        () => BreedType.filter((breed: any) => breed.pet_id === pet.petType.id),
        [pet.petType.id]
    );

    const filteredServicePlans = useMemo(
        () =>
            servicePlans.filter(
                (plan: any) =>
                    (plan.is_for_dog && pet.petBreed.type.toLowerCase() === 'dog') ||
                    (plan.is_for_cats && pet.petBreed.type.toLowerCase() === 'cat')
            ),
        [pet.petBreed.type]
    );

    const handleNavigateWeek = (isNext: boolean) => {
        if (isNext) {
            let lastDayOfWeek = weekRange[weekRange.length - 1];
            const firstDayOfNextWeek = moment(lastDayOfWeek).add(2, 'day');
            const nextWeekRange = getWeekRange(firstDayOfNextWeek, numberOfDays);

            // set the state values to reflect at the view
            setWeekRange(nextWeekRange);
            handlePetFieldChange(index, 'date', nextWeekRange[0]);
        } else {
            let firstDayOfWeek = weekRange[0];
            if (isPast(firstDayOfWeek)) return;
            const lastDayOfPreviousWeek = moment(firstDayOfWeek).subtract(numberOfDays - 1, 'day');
            const previousWeekRange = getWeekRange(lastDayOfPreviousWeek, numberOfDays);

            // set the state values to reflect at the view
            setWeekRange(previousWeekRange);
            const isFirstDayOfWeekPast = isPast(previousWeekRange[0]);
            const selectedDate = isFirstDayOfWeekPast ? previousWeekRange[1] : previousWeekRange[0];
            handlePetFieldChange(index, 'date', selectedDate);
        }
    };

    const getAddonCategoryCost = (addOn: any) => {
        // @ts-ignore
        if (
            addOnCategoryPrice &&
            // @ts-ignore
            addOnCategoryPrice[pet.petBreed.id + '-' + pet.petBreed.category + '-' + addOn.id]
        ) {
            // @ts-ignore
            return addOnCategoryPrice[
                pet.petBreed.id + '-' + pet.petBreed.category + '-' + addOn.id
            ];
        }
        let price = addOn.categories.find(
            (category: any) => category.name === pet.petBreed.category
        )?.price;
        setAddOnCategoryPrice({
            ...addOnCategoryPrice,
            [pet.petBreed.id + '-' + pet.petBreed.category + '-' + addOn.id]: price || addOn.cost,
        });
        console.log(addOnCategoryPrice);

        return price || addOn.cost;
    };

    const handleViewDetail = () => {
        window.open(
            'https://sniffynose.s3.ap-south-1.amazonaws.com/sniffynose-grooming-menu.pdf',
            '_blank'
        );
    };

    return (
        <>
            <InputFieldContainer>
                <InputField
                    label="Pet Name"
                    value={pet.name}
                    size={isMobile ? 'small' : 'medium'}
                    onChange={e => handlePetFieldChange(index, 'name', e.target.value)}
                />
            </InputFieldContainer>
            {!isMobile ? (
                <InputFieldContainer>
                    <DropDownBox>
                        <FormControl sx={DropDownField}>
                            <InputLabel id="pet-type-select-label">Pet Type</InputLabel>
                            <Select
                                labelId="pet-type-select-label"
                                id="pet-type-select"
                                label="Pet Type"
                                sx={DropDownBoxMargin}
                                value={pet.petType}
                                onChange={e => {
                                    handlePetFieldChange(index, 'petType', e.target.value);
                                    handlePetFieldChange(
                                        index,
                                        'petBreed',
                                        BreedType.find(
                                            (breed: any) => breed.pet_id === e.target.value?.id
                                        )
                                    );
                                    let plans = servicePlans.filter(
                                        (plan: any) =>
                                            (plan.is_for_dog &&
                                                e.target.value.name.toLowerCase() === 'dog') ||
                                            (plan.is_for_cats &&
                                                e.target.value.name.toLowerCase() === 'cat')
                                    );
                                    handlePetFieldChange(index, 'service', plans[0]);
                                }}
                            >
                                {PetType.map((item: any) => (
                                    <MenuItem value={item}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DropDownBox>
                    <DropDownBox>
                        <FormControl sx={DropDownField}>
                            <InputLabel id="breed-type-select-label">Breed Type</InputLabel>
                            <Select
                                labelId="breed-type-select-label"
                                id="breed-type-select"
                                label="Breed Type"
                                value={pet.petBreed}
                                onChange={e => {
                                    handlePetFieldChange(index, 'petBreed', e.target.value);
                                }}
                            >
                                {petBreed.map((item: any) => (
                                    <MenuItem value={item}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DropDownBox>
                </InputFieldContainer>
            ) : (
                <>
                    <DropDownBox>
                        <FormControl sx={DropDownField}>
                            <InputLabel id="pet-type-select-label">Pet Type</InputLabel>
                            <Select
                                labelId="pet-type-select-label"
                                id="pet-type-select"
                                label="Pet Type"
                                value={pet.petType}
                                size={'small'}
                                onChange={e => {
                                    handlePetFieldChange(index, 'petType', e.target.value);
                                    handlePetFieldChange(
                                        index,
                                        'petBreed',
                                        BreedType.find(
                                            (breed: any) => breed.pet_id === e.target.value?.id
                                        )
                                    );
                                }}
                            >
                                {PetType.map((item: any) => (
                                    <MenuItem value={item}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DropDownBox>
                    <DropDownBox>
                        <FormControl sx={DropDownField}>
                            <InputLabel id="breed-type-select-label">Breed Type</InputLabel>
                            <Select
                                labelId="breed-type-select-label"
                                id="breed-type-select"
                                label="Breed Type"
                                value={pet.petBreed}
                                size={'small'}
                                onChange={e =>
                                    handlePetFieldChange(index, 'petBreed', e.target.value)
                                }
                            >
                                {petBreed.map((item: any) => (
                                    <MenuItem value={item}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DropDownBox>
                </>
            )}
            <CalendarConatiner>
                <CalendarMonthAndYear>
                    <CalendarMonth>{getMonth(pet.date)}</CalendarMonth>
                    <CalendarYear>{getYear(pet.date)}</CalendarYear>
                </CalendarMonthAndYear>

                <WeekScheduleConatiner>
                    <Image
                        src={previousArrow}
                        alt="image"
                        onClick={() => {
                            handleNavigateWeek(false);
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                    {weekRange.map(date => {
                        const isDateSelected = date.isSame(pet.date, 'day');
                        const isPastDate = isPast(date);

                        return (
                            <WeekScheduleRow>
                                <WeekScheduleDate
                                    style={{
                                        backgroundColor: isDateSelected
                                            ? '#FDDC2E'
                                            : isPastDate
                                            ? '#f5f5f5'
                                            : '',
                                        border: isDateSelected
                                            ? 'none'
                                            : isPastDate
                                            ? 'none'
                                            : '1px solid rgba(204, 204, 204, 0.50)',
                                    }}
                                    onClick={() => {
                                        if (isPastDate) return;
                                        handlePetFieldChange(index, 'date', date);
                                    }}
                                >
                                    <div>{getShortWeekDay(date)}</div>
                                    <div>{getDay(date)}</div>
                                </WeekScheduleDate>
                            </WeekScheduleRow>
                        );
                    })}

                    <Image
                        src={nextArrow}
                        alt="image"
                        onClick={() => {
                            handleNavigateWeek(true);
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </WeekScheduleConatiner>
            </CalendarConatiner>
            <TimeBoxsContainer>
                <Grid container spacing={2}>
                    {timeSlot.map((item: any) => {
                        return (
                            <Grid item xs={4} md={4}>
                                <TimeBoxs
                                    onClick={() => handlePetFieldChange(index, 'timeSlot', item)}
                                    style={{
                                        border:
                                            pet.timeSlot === item
                                                ? '1px solid #FDDC2E'
                                                : '1px solid rgba(204, 204, 204, 0.5)',
                                        background:
                                            pet.timeSlot === item
                                                ? 'rgba(253, 220, 46, 0.10)'
                                                : '#fff',
                                    }}
                                >
                                    <TimeDay>{item.label}</TimeDay>
                                    <TimeSlot>({item.time})</TimeSlot>
                                </TimeBoxs>
                            </Grid>
                        );
                    })}
                </Grid>
            </TimeBoxsContainer>
            <MainServicePlan>
                {filteredServicePlans.map((item: any, i: any) => {
                    return (
                        <div style={{ width: '100%' }}>
                            <ServicePlanBox
                                onClick={() => {
                                    let price =
                                        item.categories.find(
                                            (category: any) =>
                                                category.name === pet.petBreed.category
                                        )?.price || item.cost;
                                    handlePetFieldChange(index, 'service', {
                                        ...item,
                                        cost: price,
                                    });
                                }}
                                style={{
                                    border: pet.service.id === item.id ? '1px solid #FDDC2E' : '',
                                    transform: pet.service.id === item.id ? 'scale(1.03)' : '',
                                    borderRadius: pet.service.id === item.id ? '0.5rem' : '',
                                }}
                            >
                                <ServicePlantitleBox>
                                    <ServicePlan>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    name="select_service"
                                                    checked={pet.service.id === item.id}
                                                    sx={RadioStyle}
                                                />
                                            }
                                            value={i}
                                            label={
                                                <ServicePlanTitleLabel>
                                                    {item.name}
                                                </ServicePlanTitleLabel>
                                            }
                                            labelPlacement="end"
                                        />
                                    </ServicePlan>
                                    <ServicePlanCostBox>
                                        {`₹${
                                            item.categories.find(
                                                (category: any) =>
                                                    category.name === pet.petBreed.category
                                            )?.price || item.cost
                                        }`}
                                        <ServicePlanCost></ServicePlanCost>
                                        <ServiceTime>{item.time}</ServiceTime>
                                    </ServicePlanCostBox>
                                </ServicePlantitleBox>
                                <Package
                                    isOpen={true}
                                    sx={{
                                        color: pet.service.id === item.id ? '#4e00ff' : '#5F5F5F',
                                    }}
                                >
                                    {item.usp}
                                </Package>
                                <ServicePlanDes>
                                    {pet.service.id !== item.id && (
                                        <div
                                            style={{ display: 'flex', cursor: 'pointer' }}
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setExpandedServiceId(
                                                    expandedServiceId === item.id ? null : item.id
                                                );
                                            }}
                                            id={`service-plan-${item.id}`}
                                        >
                                            <Icon
                                                name={
                                                    item.id === expandedServiceId
                                                        ? 'arrow-up-small'
                                                        : 'arrow-down-small'
                                                }
                                            />
                                            <IncludesText>
                                                What includes
                                                {item.id !== expandedServiceId && '?'}
                                            </IncludesText>
                                            <Popover
                                                open={expandedServiceId === item.id}
                                                anchorEl={document.getElementById(
                                                    `service-plan-${item.id}`
                                                )}
                                                onClose={() => setExpandedServiceId(false)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                aria-haspopup="true"
                                            >
                                                <ul style={{ paddingRight: '30px' }}>
                                                    {pet.petBreed.type.toLowerCase() === 'cat'
                                                        ? item.short_description_cat_array.map(
                                                              (desc: any, index: any) => (
                                                                  <li key={index}>{desc}</li>
                                                              )
                                                          )
                                                        : item.short_description_array.map(
                                                              (desc: any, index: any) => (
                                                                  <li key={index}>{desc}</li>
                                                              )
                                                          )}
                                                </ul>
                                            </Popover>
                                        </div>
                                    )}
                                    {pet.service.id === item.id &&
                                        (pet.petBreed.type.toLowerCase() === 'cat'
                                            ? item.short_description_cat
                                            : item.short_description)}
                                </ServicePlanDes>
                            </ServicePlanBox>
                        </div>
                    );
                })}
            </MainServicePlan>

            <ViewDetails>
                <ViewDetailsButton onClick={handleViewDetail}>
                    <Image src={Arrow} alt="image" />
                    View Detail
                </ViewDetailsButton>
            </ViewDetails>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={AddOns}
                sx={AutoCompleteStyle}
                getOptionLabel={(option: any) => `${option.name} ₹${getAddonCategoryCost(option)}`}
                defaultValue={pet.addOns || []}
                renderOption={(props: object, option: any) => {
                    return (
                        <div {...props} style={AutoCompleteBox}>
                            <div>{option.name}</div>
                            <div style={AutoCompleteCost}>₹{getAddonCategoryCost(option)}</div>
                        </div>
                    );
                }}
                onChange={(e, v) => {
                    handlePetFieldChange(index, 'addOns', v);
                }}
                renderInput={params => {
                    return (
                        <InputField
                            {...params}
                            label="Add Ons"
                            size={isMobile ? 'small' : 'medium'}
                            value={pet.addOns.map(
                                (item: any) => `${item.name} ${getAddonCategoryCost(item)}`
                            )}
                            placeholder="Type Here.."
                        />
                    );
                }}
            />
            <CommentBoxTitle>Is there anything else you’d like to add?</CommentBoxTitle>
            <CommentBox
                value={pet.notes}
                onChange={e => handlePetFieldChange(index, 'notes', e.target.value)}
            />
        </>
    );
};

export default PetForm;
