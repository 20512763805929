import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { ArrowBackIosNew } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReactPlayer from 'react-player';
import { Stack } from '@mui/material';
import { CarouselArrowStyles, CarouselContainer, CarouselImage } from './ProductStyle';

const CarouselView = ({
    selectedItem,
    updateCurrentSlide,
    customRenderThumb,
    media,
    setPreviewImage,
}: any) => {
    const isMobile = useCheckMobileScreen();
    return (
        <Carousel
            selectedItem={selectedItem}
            onChange={updateCurrentSlide}
            autoPlay={false}
            showThumbs={isMobile ? false : true}
            showStatus={false}
            renderThumbs={customRenderThumb}
            emulateTouch={true}
            swipeable={true}
            onClickItem={index => {
                const previewMedia = media[index];

                if (previewMedia.type === 'image') {
                    setPreviewImage(previewMedia ? previewMedia.url : '');
                }
            }}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <span
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...CarouselArrowStyles, left: 0 }}
                    >
                        <ArrowBackIosNew />
                    </span>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <span
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...CarouselArrowStyles, right: 15 }}
                    >
                        <ArrowForwardIosIcon />
                    </span>
                )
            }
        >
            {media?.map(({ type, url }: any, idx: number) => (
                <Stack key={idx} sx={CarouselContainer}>
                    {type === 'video' ? (
                        <ReactPlayer
                            style={{ zIndex: 100 }}
                            url={url}
                            width="100%"
                            height="300px"
                            fallback={<div>Video not supported</div>}
                            controls
                            muted={true}
                            onBuffer={() => console.log('onBuffer')}
                            onSeek={e => console.log('onSeek', e)}
                            onError={e => console.log('onError', e)}
                        />
                    ) : (
                        <img src={url} alt={`product-${idx}`} style={CarouselImage} />
                    )}
                </Stack>
            ))}
        </Carousel>
    );
};

export default CarouselView;
