import request from './request';

export function createOrder(data?: any) {
    return request({
        url: `orders`,
        method: 'post',
        data,
        headers: localStorage.getItem('tempAccessToken')? {
            requiresTempAccessToken: true,
        }: {requiresTempAccessToken: false},
    });
}

export function getOrders(params?: any) {
    return request({
        url: `orders`,
        method: 'get',
        params,
    });
}

export function getOrderDetails(params?: any) {
    return request({
        url: `orders/${params.orderId}`,
        method: 'get',
        headers: localStorage.getItem('tempAccessToken')? {
            requiresTempAccessToken: true,
        }: {requiresTempAccessToken: false},
    });
}

export function getPickupAddresses(params?: any) {
    return request({
        url: `addresses/pickup`,
        method: 'get',
        params,
    });
}
