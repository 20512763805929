import ProductInfo from '../view/product/product';
import Container from '@mui/material/Container';
import { Box, styled, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import CustomHero, { HeroWrapper } from '../component/CustomHero';
import Layout from '../component/layout/layout';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { getDealImageForDay, getDealsLabel } from '../common/utils/dealsNavigationLabel';

export const PageWrapper = styled(Container)(({ theme }) => ({
    borderRadius: '1.25rem',
    zIndex: 1,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
}));

const Products = ({ page }: any) => {
    const theme = useTheme();
    const [query, setQuery] = useState('');
    const isMobile = useCheckMobileScreen();
    
    return (
        <Fragment>
            <Layout>
                <Box sx={{ backgroundColor: theme.palette.background.default }}>
                    <HeroWrapper
                        sx={{
                            paddingTop: isMobile ? 0 : '1.7rem',
                            paddingBottom: isMobile ? 0 : '2rem',
                        }}
                    >
                        {page === 'deals' ? (
                            <CustomHero
                                onSearch={(e: any) => setQuery(e.target.value)}
                                title={'Deals of the day'}
                                fontSize="1.75rem"
                                heroImage={getDealImageForDay()}
                                heroImageHeight={'3.5rem'}
                            />
                        ) : (
                            <CustomHero
                                onSearch={(e: any) => setQuery(e.target.value)}
                                title="Our Best Selling Products"
                                fontSize="2.5rem"
                            />
                        )}
                    </HeroWrapper>
                    <PageWrapper
                        maxWidth={false}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <ProductInfo searchQuery={query} page={page} />
                    </PageWrapper>
                </Box>
            </Layout>
        </Fragment>
    );
};

export default Products;
