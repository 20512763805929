import { Box, Container, Typography, useTheme } from '@mui/material';
import { PageWrapper } from './products';
import scribbleBottomSVG from '../assets/images/bookServiceHeightLight.svg';
import {
    HeaderContainer,
    HeightLightImage,
    Title,
} from '../view/bookService/serviceHeader/headerStyle';
import Image from 'mui-image';
import Header from '../view/header/header';
import Footer from '../view/footer/footer';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { useEffect } from 'react';
import { animateScroll } from 'react-scroll';

type Props = {};

export default function PrivacyPolicyPage({}: Props) {
    const theme = useTheme();
    const date = 'June 12, 2024';
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        animateScroll.scrollToTop();
    }, []);

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            <Header variant="fluid" />
            <Box
                sx={{
                    color: theme.palette.primary.contrastText,
                    textAlign: 'center',
                    py: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {!isMobile && (
                    <div>
                        <Title>Privacy Policy</Title>
                        <HeaderContainer>
                            <HeightLightImage>
                                <Image src={scribbleBottomSVG} duration={0} alt="image" />
                            </HeightLightImage>
                        </HeaderContainer>
                    </div>
                )}
            </Box>
            <Box>
                <PageWrapper maxWidth={'xl'}>
                    <Container sx={{ py: 4 }}>
                        <Typography variant="body2" sx={{ marginBottom: 3, textAlign: 'end' }}>
                            Last updated : {date}
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            Welcome to Sniffy Nose Private Limited's privacy policy. Sniffy Nose
                            Private Limited ("Sniffy Nose," "we," "us," or "our") operates the
                            SniffyNose mobile/website (the "App/Web") and is committed to protecting
                            your privacy. This Privacy Policy outlines the types of information we
                            may collect from you when you use our App, how we use and protect that
                            information, and your choices regarding your information.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            Please read this Privacy Policy carefully to understand our policies and
                            practices regarding your information. By accessing or using the App, you
                            agree to the terms of this Privacy Policy. We may update this Privacy
                            Policy from time to time, and we will notify you of any changes by
                            updating the "Last updated" date at the top of this Privacy Policy. We
                            encourage you to review this Privacy Policy periodically for any
                            updates.
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Interpretation and Definitions
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Interpretation:</strong> Capitalized terms used in this Privacy
                            Policy have the meanings defined under this section. The definitions
                            provided here apply regardless of whether the terms appear in singular
                            or plural form.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Definitions:</strong>
                            <ul>
                                <li>
                                    <strong>Account:</strong> A unique account voluntarily created
                                    by you to access our Service or parts of our Services.
                                </li>
                                <li>
                                    <strong>App:</strong> The mobile application provided by Sniffy
                                    Nose Private Limited, known as SniffyNose.
                                </li>
                                <li>
                                    <strong>Cookies:</strong> Small pieces of data stored on your
                                    device, including but not limited to, cookies, permissions, and
                                    other trackers, to collect and process data about you, enhancing
                                    your online experience and improving our services.
                                </li>
                                <li>
                                    <strong>Device:</strong> Any electronic device capable of
                                    accessing the Service, such as a computer, cell phone, or
                                    tablet.
                                </li>
                                <li>
                                    <strong>Personal Data:</strong> Any information relating to an
                                    identified or identifiable individual.
                                </li>
                                <li>
                                    <strong>Usage Data:</strong> Data collected automatically,
                                    either generated by the use of the Service or from the Service
                                    infrastructure itself, such as the duration of a page visit.
                                </li>
                                <li>
                                    <strong>You:</strong> The individual accessing or using the App.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Collection of Data
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We may collect information about you in various ways.
                        </Typography>
                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                            Types of Data Collected
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Personal Data:</strong> We may collect demographic and other
                            personally identifiable information, such as your name, email address,
                            phone number, address, and details about your pets, that you voluntarily
                            provide to us when installing the App, engaging in online chat with our
                            representatives, or using our services, such as booking spa
                            appointments, viewing products, selecting products, making purchases,
                            and providing feedback. Any data you disclose in interactive areas of
                            the App, such as your profile or online chat, is considered private.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Financial Data:</strong> You may choose to provide
                            payment-related financial information (such as credit card or bank
                            account details) on the App. We will only use your financial information
                            to complete transactions and send you relevant offers from our partner
                            banks.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Data from Social Networks:</strong> If you connect your account
                            to social networking sites such as Facebook, Instagram, or Twitter, we
                            may collect user information from those platforms, including your name,
                            username, location, gender, birth date, email address, profile picture,
                            and public data for contacts.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Location Data:</strong> We may request access to and track
                            location-based information from your mobile device to provide
                            location-based services. You can manage location permissions in your
                            device settings.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Push Notifications:</strong> We may request to send you push
                            notifications regarding your account. You can opt-out of these
                            communications in your device settings.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Device Information:</strong> We may collect information about
                            your device, such as operating system name, mobile network, and device
                            identifier, to optimize your experience and improve our services.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Data from Contests, Giveaways, and Surveys:</strong> We may
                            collect personal and other information you provide when participating in
                            contests, giveaways, or surveys.
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <strong>Data Usage:</strong> Usage Data is collected automatically when
                            using the App, including information such as your device's IP address,
                            browser type, pages visited, time and date of visit, and unique device
                            identifiers.
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Use of Your Data
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We use the information collected about you to provide you with a smooth,
                            efficient, and customized experience. This may include:
                        </Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>Creating and managing your Account</li>
                                <li>Fulfilling purchase contracts and processing payments</li>
                                <li>
                                    Contacting you for updates or informative communications related
                                    to our services
                                </li>
                                <li>
                                    Providing news, special offers, and general information about
                                    goods, services, and events similar to those you have shown
                                    interest in
                                </li>
                                <li>Managing your requests and improving our services</li>
                                <li>
                                    Analyzing usage trends and evaluating the effectiveness of our
                                    promotional campaigns
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Retention of Your Data
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We will retain your information for as long as necessary to fulfill the
                            purposes outlined in this Privacy Policy unless a longer retention
                            period is required or permitted by law.
                        </Typography>{' '}
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Disclosure of Your Data
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We may share information about you in certain situations, including:
                        </Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>When required by law or to protect rights</li>
                                <li>
                                    With third-party service providers for services such as payment
                                    processing
                                </li>
                                <li>
                                    With other third parties for business analysis or marketing
                                    purposes
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Children's Privacy
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            Our App is not directed toward minors, and we do not knowingly collect
                            Personal Information from anyone under the age of 18.
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Tracking Technologies
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We use cookies and similar technologies to store certain types of
                            information each time you visit the App. You may choose to decline
                            cookies, but doing so may limit your experience on the App.
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Consent
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            By using the App or providing your information, you consent to the
                            collection and use of your information in accordance with this Privacy
                            Policy.
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Data Security
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We are committed to protecting your data and have implemented reasonable
                            physical, technical, and managerial safeguards to prevent unauthorized
                            access, alteration, transmission, and deletion of your data.
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Options Regarding Your Data
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You may review, change, or terminate your account and information at any
                            time by logging into your account settings or contacting us using the
                            information provided below. We will deactivate or delete your account
                            and information upon request, although some information may be retained
                            for certain purposes.
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                            Emails and Communications
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            If you no longer wish to receive correspondence or communications from
                            us, you may opt-out by adjusting your preferences in your account
                            settings or contacting us directly.
                        </Typography>
                        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                                Contact Us
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                If you have any questions, concerns, or complaints regarding this
                                Privacy Policy or our privacy practices, reach out to us at <br />
                                support@sniffynose.com
                            </Typography>
                        </Box>
                    </Container>
                </PageWrapper>
                <Footer />
            </Box>
        </Box>
    );
}
