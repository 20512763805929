import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import {
    BillDetailsBox,
    BillFinalAmount,
    BillItemAmount,
    BillItemName,
    BillItemNameText,
    BillItems,
    BillToPay,
    Heading,
} from './OrderStyles';
import { CURRENCY_SIGN } from '../../common/constants';
import { useSelector } from 'react-redux';
import { selectCartIsLoading, selectCartItems } from '../../service/selectors/cartSelectors';
import { CouponStyle } from '../confirmation/ConfirmationStyles';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

function BillDetails() {
    const cartItems = useSelector(selectCartItems);
    const IsCartLoading = useSelector(selectCartIsLoading);
    const isMobile = useCheckMobileScreen();

    return (
        <>
            <Grid xs={12}>
                <Heading>Bill Details</Heading>
            </Grid>
            <Grid xs={12} sx={{ paddingTop: '0px', marginTop: '1rem' }}>
                <Grid container sx={BillDetailsBox}>
                    <Grid xs={12} sx={BillItems}>
                        <Typography sx={BillItemName}>
                            {`Subtotal (${cartItems ? cartItems?.items?.length : 0} items)`}
                            <Typography
                                sx={BillItemName}
                                style={{
                                    overflow: 'hidden',
                                    color: '#747474',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Euclid-Circular-A-Regular',
                                    fontSize: '0.85rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    marginTop: '.2rem',
                                }}
                            >
                                inclusive of all taxes
                            </Typography>
                        </Typography>
                        <Typography sx={BillItemAmount}>
                            {CURRENCY_SIGN}
                            {cartItems.subtotal?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} sx={BillItems}>
                        <Typography sx={BillItemName}>
                            Delivery Fee
                            <Typography sx={BillItemNameText}>
                                {cartItems?.deliveryFeeMessage}
                            </Typography>
                        </Typography>
                        <Typography
                            sx={BillItemAmount}
                            style={{
                                textDecoration:
                                    cartItems?.deliveryFeeWaiver === cartItems?.deliveryFee
                                        ? 'line-through'
                                        : 'none',
                            }}
                        >
                            {CURRENCY_SIGN}
                            {cartItems?.deliveryFee?.toFixed(2)}
                        </Typography>
                    </Grid>

                    {cartItems?.coupon?.applicableDiscountInr > 0 && (
                        <Grid xs={12} sx={BillItems}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: isMobile ? 'start' : 'center',
                                    gap: '5px',
                                    flexDirection: isMobile ? 'column' : 'row',
                                }}
                            >
                                <Typography sx={BillItemName}>Coupon Discount</Typography>
                                <CouponStyle>{`${cartItems?.coupon?.code}`}</CouponStyle>
                            </Box>

                            <Typography sx={{ color: '#159600' }}>
                                -{CURRENCY_SIGN}
                                {cartItems?.coupon?.applicableDiscountInr.toFixed(2)}
                            </Typography>
                        </Grid>
                    )}

                    <Divider
                        sx={{
                            width: '100%',
                            borderStyle: 'dashed',
                            marginY: 0.5,
                        }}
                    />
                    <Grid xs={12} sx={BillItems} mb={2}>
                        <Typography sx={BillToPay}>Total To Pay</Typography>
                        <Typography sx={BillFinalAmount}>
                            {!IsCartLoading ? (
                                <>
                                    {CURRENCY_SIGN}
                                    {cartItems?.total?.toFixed(2)}
                                </>
                            ) : (
                                <>
                                    <Typography sx={{ color: 'gray' }}>
                                        <CircularProgress
                                            size={15}
                                            sx={{ mr: 1 }}
                                            color="inherit"
                                        />{' '}
                                        Calculating
                                    </Typography>
                                </>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default BillDetails;
