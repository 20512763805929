export const SpendText = {
    fontSize: '12px',
    color: '#14141E',
    lineHeight: '19px',
};

export const SpendBox = {
    padding: '4px 22px 30px 22px',
    background: '#F1ECFF',
    borderRadius: '16px',
    width: '380px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '@media (max-width: 768px)': {
        width: '100%',
        justifyContent: 'center',
    },
};

export const ShopNowButton = {
    color: '#0A69D9',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
    padding: 0,
    gap: '2px',
};

export const Points = {
    color: '#FFE7AA',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
};

export const PointExp = {
    color: 'rgba(255, 255, 255, 0.60)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const Container = {
    marginBottom: '1rem',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.14)',
    borderRadius: '10px',
};

export const Main = {
    width: '100%',
    borderRadius: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: 0,
    },
};

export const PointMainGrid = {
    padding: '1rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%',
};

export const PointContainer = {
    padding: '0px',
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    px: 0,
    alignItems: 'center',
};

export const PointGrid = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
};

export const PointText = {
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    fontFamily: 'Cambon-Bold',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: 'normal',
};

export const TermsConditionsCard = {
    width: '100%',
    borderRadius: '1rem',
    background: 'rgba(204, 204, 204, 0.08)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
};

export const TermsConditionsCardContent = {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
};

export const TermsConditionsText = {
    color: '#231F20',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.13px',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    '@media (max-width: 768px)': {
        alignItems: 'start',
    },
};

export const TermsConditionsTextIcon = {
    ...TermsConditionsText,
    color: '#FFD600',
    fontWeight: '700',
    fontSize: '28px',
    letterSpacing: '0.16px',
    height: '10px',
};

export const RewardsListSkeletonStyles = {
    height: '130px',
    transformOrigin: '0 0%',
    borderRadius: '10px',
    marginBottom: '-30px',
};
