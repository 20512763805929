import { Box, Container, Skeleton, Stack, Typography } from '@mui/material';
import { Headline } from '../confirmation/ConfirmationStyles';
import Grid from '@mui/material/Grid';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import TermsConditions from './TermsConditions';
import RewardCard from './RewardCard';
import RewardPoints from './RewardPointsCard';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingDataSelector, rewardsSelector } from '../../service/selectors/userSelector';
import { getRewardsAction } from '../../service/action/userActions';
import { useEffect } from 'react';
import { AllRewards } from './Utils';
import { RewardsListSkeletonStyles } from './RewardStyle';

export default function RewardsList({ isMyRewards }: any) {
    const isMobile = useCheckMobileScreen();
    const allRewards = useSelector(rewardsSelector);
    const isLoadingData = useSelector(isLoadingDataSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            dispatch(getRewardsAction({}));
        }
    }, []);

    let rewards = isMyRewards ? allRewards : AllRewards;

    return (
        <Grid container gap={'1rem'}>
            <Grid
                display={'flex'}
                width={'100%'}
                gap={isMobile ? '1rem' : '2.5rem'}
                alignItems={!isMyRewards ? 'center' : ''}
                justifyContent={!isMyRewards ? 'center' : ''}
                flexDirection={isMobile ? 'column-reverse' : 'row'}
            >
                <Box
                    width={isMyRewards || isMobile ? '100%' : '65%'}
                    sx={{
                        paddingTop: isMyRewards ? '0' : isMobile ? '1.75rem' : '3rem',
                        paddingBottom: isMyRewards ? '0' : isMobile ? '1.75rem' : '1.5rem',
                    }}
                >
                    {isLoadingData ? (
                        <Stack spacing={0}>
                            <Skeleton
                                width={160}
                                height={60}
                                sx={{ transformOrigin: '0 0', marginBottom: '3px' }}
                            />
                        </Stack>
                    ) : (
                        <Typography variant="h5" sx={{ fontWeight: '600' }} mb={2}>
                            Rewards
                        </Typography>
                    )}

                    {isLoadingData && (
                        <Stack spacing={0}>
                            {[...Array(10)].map((_, index) => (
                                <Skeleton key={index} sx={RewardsListSkeletonStyles} />
                            ))}
                        </Stack>
                    )}
                    {rewards?.length > 0 ? (
                        rewards?.map((reward: any, index: any) => (
                            <RewardCard key={index} reward={reward} isMyRewards={isMyRewards} />
                        ))
                    ) : (
                        <Container maxWidth="md" sx={{ pt: '2rem', justifyContent: 'center' }}>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Headline>No rewards available.</Headline>
                            </Box>
                        </Container>
                    )}
                    <Grid mt={isMobile ? 7 : 10}>
                        {isLoadingData ? (
                            <Skeleton
                                sx={{
                                    width: isMobile ? '100%' : '520px',
                                    height: '217px',
                                }}
                            />
                        ) : (
                            <TermsConditions />
                        )}
                    </Grid>
                </Box>
                {isMyRewards && (
                    <Box
                        width={isMobile ? '100%' : '35%'}
                        sx={{ marginTop: isMobile ? null : '2rem' }}
                    >
                        <Stack spacing={0}>
                            {isLoadingData ? (
                                <Skeleton
                                    sx={{
                                        height: '260px',
                                        transformOrigin: '0 0%',
                                        borderRadius: '24px',
                                        marginTop: '1rem',
                                        transform: 'none',
                                    }}
                                />
                            ) : (
                                <RewardPoints />
                            )}
                        </Stack>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
