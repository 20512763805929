import { Fragment } from "react";
import Layout from "../component/layout/layout";
import Confirm from "../view/confirmation/confirm";

const BookService = () => {
  return (
    <Fragment>
      <Layout>
        <Confirm />
      </Layout>
    </Fragment>
  );
};

export default BookService;
