import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface MyStyledComponentProps {
  boxShadow?: string;
  gap?: string;
  borderRadius?: string;
  background?: string;
}

export const MainContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled("div")<MyStyledComponentProps>((props) => ({
  display: "flex",
  flexDirection: "row",
  gap: props.gap,
  width: "60.5rem",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    width: "100%",
  },
}));

export const Cards = styled("div")<MyStyledComponentProps>((props) => ({
  display: "flex",
  padding: "2.5rem 3rem",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.875rem",
  flex: "1 0 0",
  borderRadius: props.borderRadius,
  background: props.background,
  boxShadow: props.boxShadow,
  "@media (max-width: 768px)": {
    padding: "1.125rem",
    gap: "1rem",
    flexDirection: "row",
    alignItems: "start",
  },
}));

export const TextConatainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: start;
    justify-content: start;
  }
`;

export const CardTextBox = styled(Box)`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 0.5rem;
    align-items: start;
    justify-content: start;
  }
`;

export const CardTitle = styled(Box)`
  color: var(--Black, #14141e);
  text-align: center;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.175rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const CardText = styled(Box)`
  align-self: stretch;
  color: #14141e;
  text-align: center;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 0.875rem;
    text-align: start;
  }
`;

export const CardButtonBox = styled(Box)`
  display: flex;
  padding: 0.62rem 0.7rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  background: var(--white, #fff);
  cursor: pointer;
`;

export const CardButtonText = styled(Box)`
  color: #14141e;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const NavCard = styled(Box)`
  padding: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem 0;
  }
`;

export const NavBorder = styled(Box)`
  width: var(--Cart, 0.0625rem);
  height: 20rem;
  flex-shrink: 0;
  background: #e6e6e6;
  @media (max-width: 768px) {
    width: 100%;
    height: 1px;
  }
`;
