import { styled } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";

export const Container = styled(Box)`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled(Box)`
  color: var(--Black, #14141e);
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const StepSpan = styled(Box)`
  color: #7f808c;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const InputFieldContainer = styled(Box)`
  display: flex;
  margin-top: 1.88rem;
  width: 100%;
`;

export const InputFieldMargin = {
  marginRight: "1rem",
};

export const InputField = styled(TextField)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 100% */
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 1.25rem;
  }
`;

export const SpanText = styled(Box)`
  color: #333;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  margin-top: 0.25rem;
`;
