import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { appColor } from '../../common/appColor/appColor';
import { Link } from 'react-router-dom';

export const MainContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`

export const FooterContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    justify-content: center;
    align-items: center;
    background-color: #18141c;
    gap: 20px;
    padding: 50px 40px 12px 40px;
    box-sizing: border-box;
    border-radius: 0px 0px 22px 22px;
    ${props => props.theme.breakpoints.down('md')} {
        padding: 50px 40px 25px 40px;
    }
`;

export const Title = styled(Box)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-align: center;
    font-family: Euclid-Circular-A-Light;
    color: #ffffffcc;
    ${props => props.theme.breakpoints.down('md')} {
        font-size: 12px;
    }
`;

const Row = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const FooterItem = styled(Row)`
    gap: 52px;
    ${props => props.theme.breakpoints.down('md')} {
        flex-direction: row;
        align-items: flex-start;
        gap: 28px;
        text-align: justify;
    }
`;

export const Social = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    paddingLeft: 45px;
    color: #ffffffcc;
    text-align: center;
    font-family: Euclid-Circular-A-Light;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const Column = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Item = styled(Box)`
    font-size: 16px;
    line-height: 19.36px;
    text-align: center;
    display: flex;
    flex-direction: row;
    font-family: Euclid-Circular-A-Light;
`;

export const NavItem = styled<any>(Item)`
    font-weight: 400;
    color: #ffffffcc;
    text-align: center;
    font-style: normal;
    text-decoration: none;
    line-height: normal;
    align-items: center;
    ${props => props.theme.breakpoints.down('md')} {
        font-size: 12px;
        flex-direction: column;
    }
`;

export const Copyright = styled(Box)`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    font-family: Euclid-Circular-A-Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ffffffcc;
    ${props => props.theme.breakpoints.down('md')} {
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        text-align: center;
        font-size: 12px;
        align-items: center;
    }
`;

export const Support = styled(Item)`
    font-weight: 500;
    color: ${appColor.yellow};
    cursor: pointer;
    font-size: 20px;
    font-family: Euclid-Circular-A-Regular;
    ${props => props.theme.breakpoints.down('md')} {
        font-size: 16px;
    }
`;

export const SupportTime = {
    backgroundColor: '#2b2b34',
    p: 0.5,
    borderRadius: 2,
    fontFamily: "Euclid-Circular-A-Light",
    marginLeft: "4px",
    fontSize: "14px",
    "@media (max-width: 768px)": {
        fontSize: "12px",
    }
}

export const SupportLink = styled(Link)`
    font-weight: 500;
    color: ${appColor.yellow};
    cursor: pointer;
    font-family: Euclid-Circular-A-Regular;
    font-size: 20px;
    text-Decoration: none;
    margin-Top: -0.5rem;
    ${props => props.theme.breakpoints.down('md')} {
        font-size: 12px;
    }
`;

export const Divider = styled(Row)`
    width: 100%;
    gap: 0px;
    align-items: center;
`;

export const VhDivider = styled(Row)`
    height: 64px;
    width: 1px;
    align-self: center;
    background-color: rgba(128, 132, 144, 0.20);
`;

export const Dot = styled(Box)`
    height: 10px;
    width: 10px;
    background-color: ${appColor.black2};
    border-radius: 5px;
`;

export const Line = styled(Box)`
    height: 2px;
    background-color: ${appColor.black2};
    width: 100%;
`;

