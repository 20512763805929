import defaultImage from '../../assets/images/defaultImage.svg';

interface MediaPreviewProps {
    media: any;
}

const MEDIA_STYLE = {
    aspectRatio: 1,
    borderRadius: '1rem',
};

export default function MediaPreview({ media }: MediaPreviewProps) {
    const mediaItems = Array.isArray(media) ? media : [media];
    const [mediaItem] = mediaItems;

    if (!mediaItem) {
        return (
            <img
                style={{ ...MEDIA_STYLE, objectFit: 'contain' }}
                src={defaultImage}
                alt="default"
            />
        );
    }

    return (
        <>
            {mediaItem.type === 'image' && (
                <img
                    src={mediaItem.url}
                    style={{ ...MEDIA_STYLE, objectFit: 'contain' }}
                    alt="image"
                />
            )}

            {mediaItem.type === 'video' && (
                <img
                    src="https://sniffynose.s3.ap-south-1.amazonaws.com/media/yxd2onYhZTNp1YzbkYR6O_1718964973970_images%281%29.png"
                    style={{ ...MEDIA_STYLE, objectFit: 'contain' }}
                    alt="video"
                />
            )}
        </>
    );
}
