import Image from '../../../component/image/image';
import {
    AddressBox,
    GroomingAddressDetails,
    Container,
    GroomingTitle,
    Title,
    GroomingAddress,
    InputFieldContainer,
    InputField,
    DropDownInputField,
    GroomingAddressBorder,
    InputFieldMargin,
    InputFieldMarginAndWidth,
    DropDownField,
    TitleBox,
    StepSpan,
    RadioStyle,
    AddressDetailsRadioStyle,
} from './addressStyle';
import Group from '../../../assets/images/Group.svg';
import { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { availableAddresses, cities, states } from '../utils';
import Stepper from '../../../common/stepper/stepper';

const Address = ({ address, handleAddressFieldChange, isMobile, step, errors }: any) => {
    const stateCities = useMemo(
        () => cities.filter(city => city.state_id === address.selectedState),
        [address.selectedState]
    );

    return (
        <Container>
            <TitleBox>
                <Title>Location Preference</Title>
                {isMobile && <StepSpan>Step {step}/3</StepSpan>}
            </TitleBox>

            {isMobile && <Stepper progress={100} margin={'0.75rem 0 1.75rem'} />}

            <AddressBox>
                <Radio
                    value="grooming_center"
                    name="location_type"
                    checked={address.locationType === 'grooming_center'}
                    onChange={e => handleAddressFieldChange('locationType', 'grooming_center')}
                    sx={RadioStyle}
                />
                <GroomingTitle>At Grooming Center</GroomingTitle>
            </AddressBox>
            {address.locationType === 'grooming_center' && (
                <GroomingAddress>
                    {availableAddresses.map((item, i) => {
                        return (
                            <GroomingAddressDetails sx={GroomingAddressBorder}>
                                <Image src={Group} alt="image" />
                                <FormControlLabel
                                    value={i}
                                    control={
                                        <Radio
                                            name="select_address"
                                            checked={address.selectedAddress === item}
                                            onChange={e =>
                                                handleAddressFieldChange('selectedAddress', item)
                                            }
                                            sx={AddressDetailsRadioStyle}
                                        />
                                    }
                                    sx={{
                                        '.MuiFormControlLabel-label': {
                                            fontSize: '0.8rem',
                                        },
                                    }}
                                    label={item.address}
                                    labelPlacement="start"
                                />
                            </GroomingAddressDetails>
                        );
                    })}
                </GroomingAddress>
            )}
            <AddressBox>
                <Radio
                    value="at_home"
                    name="location_type"
                    checked={address.locationType === 'at_home'}
                    onChange={e => handleAddressFieldChange('locationType', 'at_home')}
                    sx={RadioStyle}
                />
                <GroomingTitle>At Home</GroomingTitle>
            </AddressBox>
            {address.locationType === 'at_home' &&
                (!isMobile ? (
                    <>
                        <InputFieldContainer>
                            <InputField
                                type="text"
                                label="House no., Building, Road, Area"
                                required
                                sx={InputFieldMargin}
                                helperText={errors.address1 ? errors.address1 : ''}
                                error={!!errors.address1}
                                onChange={e => handleAddressFieldChange('address1', e.target.value)}
                                value={address.address1}
                            />
                            <InputField
                                type="text"
                                label="Locality/Town"
                                helperText={errors.locality ? errors.locality : ''}
                                error={!!errors.locality}
                                required
                                onChange={e => handleAddressFieldChange('locality', e.target.value)}
                                value={address.locality}
                            />
                        </InputFieldContainer>
                        <InputFieldContainer>
                            <InputField
                                label="Pin Code"
                                required
                                type="text"
                                sx={InputFieldMarginAndWidth}
                                helperText={errors.pinCode ? errors.pinCode : ''}
                                error={!!errors.pinCode}
                                onChange={e => handleAddressFieldChange('pinCode', e.target.value)}
                                value={address.pinCode}
                            />
                            <DropDownInputField sx={InputFieldMargin}>
                                <FormControl required sx={DropDownField}>
                                    <InputLabel id="state-select-label">State</InputLabel>
                                    <Select
                                        labelId="state-select-label"
                                        id="state-select"
                                        label="State"
                                        onChange={e =>
                                            handleAddressFieldChange(
                                                'selectedState',
                                                e.target.value
                                            )
                                        }
                                        value={address.selectedState}
                                        disabled
                                    >
                                        {states.map(item => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </DropDownInputField>
                            <DropDownInputField>
                                <FormControl required sx={DropDownField}>
                                    <InputLabel id="city-select-label">City</InputLabel>
                                    <Select
                                        labelId="city-select-label"
                                        id="city-select"
                                        label="City"
                                        onChange={e =>
                                            handleAddressFieldChange('selectedCity', e.target.value)
                                        }
                                        value={address.selectedCity}
                                        disabled
                                    >
                                        {stateCities.map((item: any) => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </DropDownInputField>
                        </InputFieldContainer>
                    </>
                ) : (
                    <>
                        <InputField
                            type="text"
                            label="House no., Building, Road, Area"
                            required
                            helperText={errors.address1 ? errors.address1 : ''}
                            error={!!errors.address1}
                            size={'small'}
                            sx={InputFieldMargin}
                            onChange={e => handleAddressFieldChange('address1', e.target.value)}
                            value={address.address1}
                        />
                        <InputField
                            type="text"
                            label="Locality/Town"
                            size={'small'}
                            helperText={errors.locality ? errors.locality : ''}
                            error={!!errors.locality}
                            required
                            onChange={e => handleAddressFieldChange('locality', e.target.value)}
                            value={address.locality}
                        />

                        <InputField
                            label="Pin Code"
                            required
                            size={'small'}
                            type="text"
                            helperText={errors.pinCode ? errors.pinCode : ''}
                            error={!!errors.pinCode}
                            onChange={e => handleAddressFieldChange('pinCode', e.target.value)}
                            value={address.pinCode}
                        />
                        <DropDownInputField>
                            <FormControl required sx={DropDownField}>
                                <InputLabel id="state-select-label">State</InputLabel>
                                <Select
                                    labelId="state-select-label"
                                    id="state-select"
                                    label="State"
                                    size={'small'}
                                    onChange={e =>
                                        handleAddressFieldChange('selectedState', e.target.value)
                                    }
                                    value={address.selectedState}
                                    disabled
                                >
                                    {states.map(item => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </DropDownInputField>
                        <DropDownInputField>
                            <FormControl required sx={DropDownField}>
                                <InputLabel id="city-select-label">City</InputLabel>
                                <Select
                                    labelId="city-select-label"
                                    id="city-select"
                                    label="City"
                                    size={'small'}
                                    onChange={e =>
                                        handleAddressFieldChange('selectedCity', e.target.value)
                                    }
                                    value={address.selectedCity}
                                    disabled
                                >
                                    {stateCities.map((item: any) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </DropDownInputField>
                    </>
                ))}
        </Container>
    );
};

export default Address;
