import { removeOneDupe } from '../../../common/utils/productUtils';
import { appActions } from '../../action';

const initialState: any = {
  timestamp: Date.now(),
};

export const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${appActions.GET_PRODUCT_CATEGORY}_START`:
      return { ...state, productCategory: null, isProductCateLoading: true };
    case `${appActions.GET_PRODUCT_CATEGORY}_SUCCESS`:
      return { ...state, productCategory: action.payload, isProductCateLoading: false };
    case `${appActions.GET_PRODUCT_CATEGORY}_FAIL`:
      return { ...state, productCategory: null, isProductCateLoading: false };

    case `${appActions.GET_PRODUCT}_START`:
      return { ...state, products: null, isProductLoading: true };
    case `${appActions.GET_PRODUCT}_SUCCESS`:
      return { ...state, products: action.payload, isProductLoading: false };
    case `${appActions.GET_PRODUCT}_FAIL`:
      return { ...state, products: null, isProductLoading: false };

    case `${appActions.GET_UNIQUE_PRODUCT}_START`:
      return { ...state, uniqueProducts: null, isUniqueProductsLoading: true };
    case `${appActions.GET_UNIQUE_PRODUCT}_SUCCESS`:
      return { ...state, uniqueProducts: action.payload, isUniqueProductsLoading: false };
    case `${appActions.GET_UNIQUE_PRODUCT}_FAIL`:
      return { ...state, uniqueProducts: null, isUniqueProductsLoading: false };

    case `${appActions.GET_EXCLUSIVE_OFFERS}_START`:
      return { ...state, exclusiveOffers: null, isExclusiveOffersLoading: true };
    case `${appActions.GET_EXCLUSIVE_OFFERS}_SUCCESS`:
      return { ...state, exclusiveOffers: action.payload, isExclusiveOffersLoading: false };
    case `${appActions.GET_EXCLUSIVE_OFFERS}_FAIL`:
      return { ...state, exclusiveOffers: null, isExclusiveOffersLoading: false };
    
    default:
      return state;
  }
};
