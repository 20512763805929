import {
  Container,
  Title,
  AddAnotherPetText,
  AddAnotherBox,
  TitleBox,
  StepSpan,
} from './serviceDetailsStyle';
import AddIcon from '../../../assets/images/AddIcon.svg';
import Image from '../../../component/image/image';
import PetDetails from './petDetails';
import Stepper from '../../../common/stepper/stepper';

const ServiceDetails = ({ addAnotherPet, handlePetFieldChange, pets, isMobile, step }: any) => {
  return (
    <Container>
      <TitleBox>
        <Title>Service Details</Title>
        {isMobile && <StepSpan>Step {step}/3</StepSpan>}
      </TitleBox>

      {isMobile && <Stepper progress={33.33} margin={'0.75rem 0 0'} />}

      {pets.map((item: any, index: any) => (
        <PetDetails
          pet={item}
          index={index}
          handlePetFieldChange={handlePetFieldChange}
          petsCount={pets.length}
          isMobile={isMobile}
        />
      ))}
      <AddAnotherBox>
        <AddAnotherPetText onClick={addAnotherPet}>
          <Image src={AddIcon} alt="image" />
          Add Another Pet
        </AddAnotherPetText>
      </AddAnotherBox>
    </Container>
  );
};

export default ServiceDetails;
