import Stepper from '../../../common/stepper/stepper';
import {
    Container,
    InputField,
    InputFieldContainer,
    InputFieldMargin,
    SpanText,
    StepSpan,
    Title,
    TitleBox,
} from './contactStyle';

const Contact = ({ contact, handleContactFieldChange, step, isMobile, errors }: any) => {
    return (
        <Container>
            <TitleBox>
                <Title>Contact Details</Title>
                {isMobile && <StepSpan>Step {step}/3</StepSpan>}
            </TitleBox>

            {isMobile && <Stepper progress={66.66} margin={'0.75rem 0 0'} />}

            {!isMobile ? (
                <>
                    <InputFieldContainer>
                        <InputField
                            label="First Name"
                            required
                            type="text"
                            sx={InputFieldMargin}
                            value={contact.firstName}
                            helperText={errors.firstName ? errors.firstName : ''}
                            error={!!errors.firstName}
                            onChange={e => handleContactFieldChange('firstName', e.target.value)}
                        />
                        <InputField
                            type="text"
                            label="Last Name"
                            value={contact.lastName}
                            onChange={e => handleContactFieldChange('lastName', e.target.value)}
                        />
                    </InputFieldContainer>
                    <InputFieldContainer>
                        <InputField
                            label="Whatsapp Phone Number"
                            type="text"
                            required
                            sx={InputFieldMargin}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber ? errors.phoneNumber : ''}
                            value={contact.phoneNumber}
                            onChange={e => handleContactFieldChange('phoneNumber', e.target.value)}
                        />
                        <InputField
                            label="Email Address"
                            type="email"
                            value={contact.email}
                            onChange={e => handleContactFieldChange('email', e.target.value)}
                        />
                    </InputFieldContainer>
                    <SpanText> We will contact you through Whatsapp.</SpanText>
                </>
            ) : (
                <>
                    <InputField
                        label="First Name"
                        required
                        type="text"
                        size={'small'}
                        sx={InputFieldMargin}
                        value={contact.firstName}
                        helperText={errors.firstName ? errors.firstName : ''}
                        error={!!errors.firstName}
                        onChange={e => handleContactFieldChange('firstName', e.target.value)}
                    />
                    <InputField
                        type="text"
                        label="Last Name"
                        size={'small'}
                        value={contact.lastName}
                        onChange={e => handleContactFieldChange('lastName', e.target.value)}
                    />
                    <InputField
                        label="Whatsapp Phone Number"
                        type="text"
                        required
                        size={'small'}
                        sx={InputFieldMargin}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber ? errors.phoneNumber : ''}
                        value={contact.phoneNumber}
                        onChange={e => handleContactFieldChange('phoneNumber', e.target.value)}
                    />
                    <SpanText> We will contact you through Whatsapp.</SpanText>
                    <InputField
                        label="Email Address"
                        type="email"
                        size={'small'}
                        value={contact.email}
                        onChange={e => handleContactFieldChange('email', e.target.value)}
                    />
                </>
            )}
        </Container>
    );
};

export default Contact;
