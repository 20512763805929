import Image from 'mui-image';
import bookServiceFooter from '../assets/images/woof.png';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';
import { BookServiceFooterImage } from '../view/bookService/serviceFooter/footerStyle';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Icon from './icon';

type Props = { allowOnMobile?: Boolean };
const FooterBanner = ({ allowOnMobile = false }: Props) => {
    const isMobile = useCheckMobileScreen();

    if (isMobile ? (allowOnMobile ? false : true) : false) return null;

    return (
        <Container sx={{ mt: '5rem', width: '65rem' }}>
            <Paper
                elevation={0}
                sx={{ backgroundColor: '#f8f4f1', pl: '3rem', pt: '2.5rem', borderRadius: '2rem' }}
            >
                <Grid container xs={12}>
                    <Grid xs={12} lg={9}>
                        <Typography
                            sx={{
                                color: '#333',
                                fontFamily: 'Euclid-Circular-A-Bold',
                                fontSize: '2rem',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                            }}
                        >
                            Your one-stop pet needs shop
                        </Typography>
                        <Box mt={'1.7rem'}>
                            <Typography
                                sx={{
                                    color: '#14141E',
                                    fontFamily: 'Euclid-Circular-A-Regular',
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '1.5rem',
                                    mb: '0.87rem',
                                }}
                            >
                                Connect with us on
                            </Typography>
                            <Stack direction={'row'} spacing={1}>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    size="large"
                                    sx={{ backgroundColor: 'white' }}
                                    startIcon={<Icon name="whatsapp" />}
                                >
                                    +91 93599 - 39912
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    size="large"
                                    sx={{ backgroundColor: 'white' }}
                                    startIcon={<Icon name="mail" />}
                                >
                                    support@sniffynose.com
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid container xs={12} lg={3} justifyContent={'center'}>
                        <BookServiceFooterImage>
                            <Image src={bookServiceFooter} alt="image" duration={500} />
                        </BookServiceFooterImage>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default FooterBanner;
