import React from 'react';
import { Card, CardContent, Typography, Button, Stack, Chip, Divider, Box } from '@mui/material';
import {
    CardContentStyle,
    StyledLink,
    ExpiredLabelStyle,
    LogoStyle,
    CardChipStyle,
    ShopNowBtn,
    CouponStyle,
    CouponTitle,
    CouponDesc,
    CouponExpired,
    CartContainer,
    CartContextBox,
    TermsAndConditionBox,
} from './CouponStyle';
import parse from 'html-react-parser';
import Icon from '../../component/icon';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { checkCouponExpired } from '../../common/utils/couponUtils';
import { useNavigate } from 'react-router-dom';
import { CURRENCY_SIGN } from '../../common/constants';

const CouponCard = ({ coupon }: any) => {
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();
    function formatDate() {
        const date = coupon?.expirationDate ? new Date(coupon?.expirationDate) : new Date();

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        return (
            <span color="textSecondary">
                {day} {month}, {year}
            </span>
        );
    }

    // Sanitize the HTML content
    const description = parse(coupon?.description || '');
    return (
        <Card
            sx={{
                ...CartContainer,
                marginRight: isMobile ? 'auto' : '30px',
                maxWidth: isMobile ? 'unset' : '400px',
            }}
        >
            <CardContent sx={CardContentStyle}>
                <Box sx={CartContextBox}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                            <Chip
                                sx={CardChipStyle}
                                icon={
                                    <Box sx={LogoStyle}>
                                        <Icon name="logo" />
                                    </Box>
                                }
                                label={<Typography sx={CouponStyle}>{coupon?.code}</Typography>}
                                size="small"
                            />
                        </Stack>

                        {checkCouponExpired({ expirationDate: coupon?.expirationDate }) && (
                            <Typography sx={ExpiredLabelStyle}>Expired</Typography>
                        )}
                    </Box>
                    <Typography sx={CouponTitle} mb={1}>
                        {coupon?.title || ''}
                    </Typography>
                    <Typography sx={CouponDesc}>{description || ''}</Typography>
                </Box>
                <Box
                    sx={{
                        borderTop: '1px dashed rgba(0, 0, 0, 0.12)',
                        paddingTop: '12px',
                    }}
                >
                    <Typography sx={CouponDesc}>
                        {coupon.metaData.minOrderValue > 0 &&
                            'Min order ' + CURRENCY_SIGN + coupon.metaData.minOrderValue}
                        {coupon.metaData.maxDiscountValue > 0 &&
                            ' Max discount: ' + CURRENCY_SIGN + coupon.metaData.maxDiscountValue}
                    </Typography>
                    <Box sx={TermsAndConditionBox}>
                        <Typography sx={CouponExpired}>Expires on {formatDate()}</Typography>
                        <StyledLink to="/terms-conditions">T&C</StyledLink>
                    </Box>
                </Box>
            </CardContent>

            <Button
                sx={ShopNowBtn}
                disabled={checkCouponExpired({ expirationDate: coupon?.expirationDate })}
                onClick={() => navigate(`/products?coupon_code=${coupon.code}`)}
            >
                Shop Now
            </Button>
        </Card>
    );
};
export default CouponCard;
