import { appActions } from "..";

export const addItemToCartAction = (payload:any) => ({
  type: appActions.ADD_ITEM_TO_CART,
  payload,
});

export const getCartsAction = (payload:any) => ({
  type: appActions.GET_CART,
  payload,
});

export const deleteCartAction = (payload:any) => ({
  type: appActions.DELETE_CART_ITEM,
  payload,
});

export const updateCartAction = (payload:any) => ({
  type: appActions.UPDATE_CART_ITEM,
  payload,
});

export const applyCouponAction = (payload:any) => ({
  type: appActions.APPLY_COUPON,
  payload,
});

export const removeCouponAction = (payload:any) => ({
  type: appActions.REMOVE_COUPON,
  payload,
});
