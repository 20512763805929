import { CURRENCY } from "../constants";

export default function getProductKey(productObj: any) {
    const productKey = `${productObj.title}_${productObj.mrp}_${productObj.selling_price}_${productObj.discount}`;
    return productKey;
}

export const removeOneDupe = (currCart: any, dupe: any) => {
    let newArr = [...currCart];
    const dupeKey = getProductKey(dupe);
    const firstOccurrence = newArr.findIndex(item => dupeKey === getProductKey(item));
    if (firstOccurrence !== -1) {
        // If a duplicate is found, remove it
        newArr.splice(firstOccurrence, 1);
        //   console.log(arr);
        return newArr; // Return the modified array
    }
    return currCart;
};

export const getSizeMedia = (product: any) => {
    const results = [];
    const { media = [] } = product || {};
    const sizeChartMedia = media.find((m: any) => {
        return m?.url?.toLowerCase()?.search('size_chart') >= 0;
    });

    if (sizeChartMedia) {
        results.push({
            label: "Size Chart", mediaUrl: sizeChartMedia
        });
    }

    const measurementChartMedia = media.find((m: any) => {
        return m?.url?.toLowerCase()?.search('measurement_chart') >= 0;
    });

    if (measurementChartMedia) {
        results.push({
            label: "How to Measure", mediaUrl: measurementChartMedia
        });
    }

    return results;
};

export const isValidVariantToShowDiscount = (currentVariantName: string, product: any) => {
    let result = false;
    const { options = [] } = product || {};

    const hasPackVariant = options.find((v: any) => {
        return v?.name?.toLowerCase() === 'pack';
    });

    const hasSizeVariant = options.find((v: any) => {
        return v?.name?.toLowerCase() === 'size';
    });

    if (currentVariantName.toLocaleLowerCase() === 'pack') {
        result = true;
    } else if (currentVariantName.toLocaleLowerCase() === 'size' && !hasPackVariant) {
        result = true;
    } else if (!hasPackVariant && !hasSizeVariant) {
        const currentVariantIndex = options.findIndex((v: any) => {
            return v?.name?.toLowerCase() === currentVariantName;
        });

        result =
            (currentVariantIndex > -1 && currentVariantIndex === options.length - 1) ||
            options.length === 1;
    }

    return result;
};

export const getProductDiscountLabel = ({product, selectedVariant}:any) => {
    const result = product?.productVariantOptions.find((item:any) => item.option1 == selectedVariant || item.option2 == selectedVariant)
   return getDiscountLabel({product_variant_option:result})
}

export const getProductVariantDiscountLabel = ({product_variant_option}:any) => {
   return getDiscountLabel({product_variant_option})
}

const getDiscountLabel = ({product_variant_option}:any) => {
    const discount = product_variant_option?.discountValue || '';
    const discountMatrix = product_variant_option?.discountMetric || '';
    
    let label = ''
    
    if(discount && discountMatrix){
        // Determine the currency or percentage symbol
    label += discountMatrix === 'INR' ? `${CURRENCY} ` : ' ';
    
    // Add the discount amount
    label += discount;
    
    // Determine if it's a percentage discount
    label += discountMatrix === 'PERCENTAGE' ? '% ' : ' ';
    
    // Append 'Off'
    label += 'Off';
    }

    return label
}

export const getSelectedVariantFromProduct = ({product,selectedVariant}:any) => {
    if (!product) {
        return false;
    }
    let selectV: any = null;
    for (let i = 0; i < product.productVariantOptions.length; i++) {
        const pvo = product.productVariantOptions[i];
        for (let k = 0; k < selectedVariant.length; k++) {
            const item: any = selectedVariant[k];
            const key = 'option' + parseInt(String(k + 1)).toString();
            if (pvo[key] === item?.value?.id) {
                selectV = pvo;
            } else {
                selectV = null;
                break;
            }
        }
        if (selectV) {
            break;
        }
    }
    return selectV;
};

export const getDailyProductSoldCount = (productId: any) => {
    const today = new Date().toISOString().split('T')[0];
    const storedData = localStorage.getItem(`dailySold_${productId}`);

    if (storedData) {
        const parsedData = JSON.parse(storedData);

        if (parsedData.date === today) {
            return parsedData.count;
        }
    }

    const soldCount = Math.floor(Math.random() * 30) + 1;
    localStorage.setItem(
        `dailySold_${productId}`,
        JSON.stringify({ date: today, count: soldCount })
    );
    return soldCount;
};

export const getDailyProductViewCount = (productId: any) => {
        const today = new Date().toISOString().split('T')[0];
        const storedData = localStorage.getItem(`dailyViews_${productId}`);

        if (storedData) {
            const parsedData = JSON.parse(storedData);

            if (parsedData.date === today) {
                return parsedData.count;
            }
        }

        const viewCount = Math.floor(Math.random() *30) + 1;
        localStorage.setItem(
            `dailyViews_${productId}`,
            JSON.stringify({ date: today, count: viewCount }) 
        );
        return viewCount ;
};
