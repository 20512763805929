import {
  NameAndType,
  CollapseDetails,
  DateAndTimeBox,
  FormCollapse,
  PetService,
  FormCollapseService,
  FormCollapseServiceTitle,
  SpanButton,
  CollapseDateAndTime,
} from "./serviceDetailsStyle";
import calendar from "../../../assets/images/calendar.svg";
import Image from "../../../component/image/image";

const PetCard = ({ pet }: any) => {
  return (
    <FormCollapse>
      <NameAndType>
        {pet.name || pet.petType.name || pet.petBreed.name}
      </NameAndType>
      <DateAndTimeBox>
        <Image src={calendar} alt="image" />
        <NameAndType>Date & Time</NameAndType>
        <CollapseDateAndTime>
          {pet.date.format("ll")} & {pet.timeSlot.time}
        </CollapseDateAndTime>
      </DateAndTimeBox>
      <FormCollapseService>
        <FormCollapseServiceTitle>Services</FormCollapseServiceTitle>
        <PetService>
          <CollapseDetails>{pet.service.name}</CollapseDetails>
          <CollapseDetails>₹{pet.service.cost}</CollapseDetails>
        </PetService>
        <div style={{ width: "100%" }}>
          {pet.addOns.map((item: any) => (
            <PetService>
              <CollapseDetails>
                {item.name}
                <SpanButton>Add Ons</SpanButton>
              </CollapseDetails>
              <CollapseDetails>₹{item.cost}</CollapseDetails>
            </PetService>
          ))}
        </div>
      </FormCollapseService>
    </FormCollapse>
  );
};

export default PetCard;
