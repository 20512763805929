import { createSelector } from 'reselect';

const selectUserState = (state: any) => state.user;

export const isLoadingDataSelector = createSelector(
  [selectUserState],
  (userstate) => userstate.isLoading
);

export const userSelector = createSelector(
  [selectUserState],
  (userstate) => userstate.user
);

export const totalPointsSelector = createSelector(
  [selectUserState],
  (userstate) => userstate.totalPoints
);

export const rewardsSelector = createSelector(
  [selectUserState],
  (userstate) => userstate.rewards
);
