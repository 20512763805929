import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { OutlinedInput, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CouponCard from '../order/CouponCard';
import { CloseBox, LogoBox, ModalCouponTitle, ModalStyle, ModalTitle } from './CouponStyle';

type props = {
    open: boolean;
    handleClose: any;
    coupons: any;
    appliedCouponId: string;
    removeCoupon: any;
    applyCoupon: any;
};

export default function CouponsModal({
    open,
    handleClose,
    coupons,
    appliedCouponId,
    removeCoupon,
    applyCoupon,
}: props) {
    // const totalPoints = useSelector(totalPointsSelector);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [couponsData, setCouponsData] = useState(coupons);

    useEffect(() => {
        if (searchKeyword.length > 0) {
            const res = couponsData.filter((v: any) =>
                v.code.toLowerCase().includes(searchKeyword.toLowerCase())
            );

            setCouponsData(res);
        } else {
            setCouponsData(coupons);
        }
    }, [searchKeyword]);

    return (
        <Box>
            <Modal
                disableEscapeKeyDown
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={({ children, ...props }) => (
                    <Box
                        {...props}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {children}
                    </Box>
                )}
            >
                <Fade in={open}>
                    <Box sx={ModalStyle}>
                        <Grid container gap={'1.5rem'}>
                            <Grid item xs={12} sx={LogoBox}>
                                <Box sx={CloseBox}>
                                    <CloseIcon
                                        sx={{ color: 'rgba(9, 18, 31, 1)' }}
                                        onClick={handleClose}
                                    />
                                </Box>
                                <Typography sx={ModalTitle}>
                                    Coupons ({couponsData?.length})
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Stack direction="row" height={'48px'}>
                                    <OutlinedInput
                                        placeholder="Enter Coupon Code"
                                        fullWidth
                                        onChange={e => setSearchKeyword(e.target.value)}
                                    />
                                </Stack>

                                <Box
                                    sx={{
                                        marginTop: '1rem',
                                        height: '50vh',
                                        overflowY: 'auto',
                                        scrollbarWidth: 'none',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '18px',
                                    }}
                                >
                                    {couponsData.length > 0 ? (
                                        <>
                                            <Typography sx={ModalCouponTitle}>
                                                Rewarded discounts
                                            </Typography>
                                            {couponsData.map((coupon: any, index: any) => (
                                                <CouponCard
                                                    key={index}
                                                    coupon={coupon}
                                                    isStyle={true}
                                                    isApplied={appliedCouponId === coupon.id}
                                                    applyCoupon={applyCoupon}
                                                    removeCoupon={removeCoupon}
                                                    handleClose={handleClose}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <Typography>Coupon not found</Typography>
                                    )}
                                </Box>
                                {/* )} */}
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}
