import { createSelector } from 'reselect';

const selectCartState = (state: any) => state.cart;

export const selectCartItems = createSelector([selectCartState], cartState => cartState.cart);

export const selectCartIsLoading = createSelector(
    [selectCartState],
    cartState => cartState.isLoading
);

export const selectCartDrawer = createSelector(
    [selectCartState],
    cartState => cartState.cartDrawer
);

export const selectErrorMessage = createSelector(
    [selectCartState],
    cartState => cartState.errorMessage
);
