import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ProductCard from '../../component/ProductCard';
import FooterBanner from '../../component/FooterBanner';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import CategoryFilter from '../../component/CategoryFilter';
import { useEffect, useState } from 'react';
import useProductInfo from '../../common/hooks/useProductInfo';
import {
    LoadMore,
    LoadMoreButton,
    ProductUnavailable,
    filterTitle,
    Title,
    searchInput,
} from './productStyle';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams } from 'react-router-dom';
import FilterTag from './FilterTag';
import useGetCategories from '../../common/hooks/useGetCategories';
import useQueryParamFilters from './Hooks/useQueryParamFilters';
import useCheckIsMoreDataToLoad from '../../common/hooks/useCheckIsMoreDataToLoad';
import ProductNotFound from './ProductNotFound';

interface ProductsParams {
    page: number;
    search_keyword: string | null;
    limit: number;
    sortOrder: string | null;
    categories: string | null;
    tags: string | null;
    coupon_code: string | null;
}

interface Item {}

const ProductInfo = ({ page }: any) => {
    const [searchParams] = useSearchParams();
    const defaultSearch = searchParams.get('search');
    const defaultCategory = searchParams.get('category');
    const defaultTag = searchParams.get('tag');
    const defaultProduct = searchParams.get('product');
    const defaultCouponCode = searchParams.get('coupon_code');

    const isMobile = useCheckMobileScreen();
    const [currCat, setCurrCat] = useState(0);
    const [products, setProducts] = useState<Item[]>([]);
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);
    const [title, setTitle] = useState('All Products');

    const [queryParamFilters, setQueryParamFilters, resetQueryFilters] = useQueryParamFilters({
        defaultSearch,
        defaultCategory,
        defaultTag,
        defaultProduct,
        defaultCouponCode,
    });

    const [productsParams, setProductsParams] = useState<ProductsParams>({
        page: 1,
        search_keyword: null,
        limit: 10,
        sortOrder: null,
        categories: null,
        tags: null,
        coupon_code: null,
    });

    const {
        data: productsData,
        isLoading: isProductsLoading = true,
        refetch: getProducts,
    } = useProductInfo(productsParams);

    const { data: categoryData, isLoading: isCatLoading } = useGetCategories();

    const [hasMore, setHasMore, manageLoadMore] = useCheckIsMoreDataToLoad({
        ...productsData?.pagination,
    });

    /* if page changed from load more or after reset filters then call this section */
    useEffect(() => {
        setProducts([]);
        setCurrCat(0);

        setProductsParams({
            ...productsParams,
            ...(page === 'deals'
                ? { tags: 'Hot Deals', search_keyword: '' }
                : queryParamFilters.defaultTag
                ? { tags: defaultTag }
                : { tags: null, search_keyword: '' }),
            ...(queryParamFilters.defaultCategory && {
                categories: queryParamFilters.defaultCategory,
            }),
            ...((queryParamFilters.defaultSearch || queryParamFilters.defaultProduct) && {
                search_keyword: queryParamFilters.defaultProduct
                    ? queryParamFilters.defaultProduct
                    : queryParamFilters.defaultSearch,
            }),
            ...(queryParamFilters.defaultCouponCode && {
                coupon_code: queryParamFilters.defaultCouponCode,
            }),
            page: 1,
        });
    }, [page]);

    /* Performed load more data action */
    const loadMore = () => {
        setIsLoadingInitial(false);
        setProductsParams(prev => {
            const nextPage = productsParams.page + 1;
            return { ...prev, page: nextPage };
        });
    };

    /* Handle title for main section of page */
    const renderTitle = () => {
        const selectedCategory = categoryData && currCat ? categoryData[currCat].name : '';
        /* Set title based on search_keyword filter */
        if (productsParams.search_keyword && !defaultProduct) {
            setTitle(
                `${productsData?.pagination?.total || 0} results for "${
                    productsParams.search_keyword
                }"`
            );
        } else if (selectedCategory) {
            /* Set title based on category filter */
            setTitle(`${selectedCategory} Products`);
        } else {
            if (page === 'deals') {
                setTitle('Hot deals');
            } else {
                setTitle('All Products');
            }
        }
    };

    const clearSearchFilter = () => {
        // if URL there is a product id, let's redirect the user to main page.
        if (defaultSearch || defaultProduct) {
            window.location.href = window.location.pathname;
            return false;
        }
        /* Clear search_keyword filter */
        setProductsParams(prev => {
            return { ...prev, search_keyword: '', page: 1, limit: 10 };
        });
        setProducts([]);
    };

    const buildData = () => {
        /* make delay on update state */
        if (products.length === 0) {
            setProducts(productsData?.prodcutsArray);
        } else {
            setProducts(prevItems => [...prevItems, ...productsData?.prodcutsArray]);
        }
    };

    useEffect(() => {
        setHasMore(false);
        /* Get seletced category data from  category array to show selected category*/
        if (queryParamFilters.defaultCategory && categoryData) {
            const indexId = categoryData.findIndex(
                (data: any) =>
                    data.id == queryParamFilters.defaultCategory ||
                    data?.name?.toLowerCase() ==
                        queryParamFilters?.defaultCategory?.toLocaleLowerCase()
            );

            if (indexId >= 0) {
                setCurrCat(indexId);
            }
        }

        if (currCat) {
            /* clear previous filter before applying category filter*/
            resetQueryFilters();
            setProductsParams(prev => {
                return {
                    ...prev,
                    categories: categoryData[currCat].id,
                    page: 1,
                    search_keyword: '',
                    tags: null,
                    coupon_code: null,
                };
            });
        } else if (!queryParamFilters.defaultCategory) {
            /* set category as null if user selected ALL Products from list */
            setProductsParams(prev => {
                return { ...prev, categories: null, page: 1 };
            });
        }

        /* Render title for section */
        renderTitle();

        // setTimeout(() => {
        //     /* reset product list */
        //     setProducts([]);
        // });
    }, [currCat, categoryData]);

    useEffect(() => {
        if (productsData?.prodcutsArray) {
            /* render title*/
            renderTitle();
        }
    });

    useEffect(() => {
        if (productsData?.prodcutsArray) {
            /* render title */
            renderTitle();
            /* make delay on update state */
            setTimeout(buildData, 0);

            if (productsData?.pagination) {
                manageLoadMore();
            }
        }
    }, [productsData]);

    useEffect(() => {
        getProducts();
    }, [productsParams.page, productsParams.categories, productsParams.tags]);

    useEffect(() => {
        if (!queryParamFilters.defaultProduct && !queryParamFilters.defaultCategory) {
            if (productsParams?.search_keyword?.length === 0) {
                clearSearchFilter();
            } else {
                resetQueryFilters(); // Reset URL params searching filter state

                const getData = setTimeout(() => {
                    setProductsParams({
                        ...productsParams,
                        page: 1,
                        limit: 10,
                        search_keyword: productsParams.search_keyword,
                        tags: page === 'deals' ? 'Hot Deals' : queryParamFilters.defaultTag || null,
                        coupon_code: null,
                        categories: null,
                    });
                    setCurrCat(0);
                }, 250);

                return () => clearTimeout(getData);
            }
        }
    }, [productsParams.search_keyword]);

    useEffect(() => {
        if (products.length > 0 || !isProductsLoading) {
            setIsLoadingInitial(false);
        }
    }, [products]);

    useEffect(() => {
        setProducts([]);
        setIsLoadingInitial(true);
    }, [productsParams.categories, productsParams.tags, productsParams.search_keyword]);

    const resetUrlFilter = (filterType: any) => {
        if (page === 'deals' && !filterType) {
            window.location.href = '/products';
        } else if (filterType) {
            setProductsParams({
                ...productsParams,
                ...(filterType == 'tag' && { tags: null }),
                ...(filterType == 'coupon_code' && { coupon_code: null }),
                page: 1,
            });
        }
    };

    return (
        <>
            <Grid
                container
                sx={{
                    mb: { xs: '1rem', md: 0 },
                    maxWidth: '1440px',
                }}
            >
                {page === 'deals' ? (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            paddingTop: { xs: '1.5rem', md: '1rem' },
                            paddingBottom: {
                                xs: '0.75rem',
                                md: '1.5rem',
                            },
                            width: '100%',
                        }}
                        mb={1}
                        spacing={2}
                    >
                        <Grid
                            mb={2}
                            sx={{
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: isMobile ? 'flex-start' : 'center',
                                gap: isMobile ? '10px' : '',
                                justifyContent: 'space-between',
                                display: 'flex',
                                width: '100%',
                                marginBottom: '9px',
                                height: isMobile ? 'auto' : '48px',
                            }}
                        >
                            <Stack
                                direction={isMobile ? 'column' : 'row'}
                                justifyContent="space-between"
                                alignItems={isMobile ? 'start' : 'center'}
                                spacing={1}
                            >
                                <Typography sx={filterTitle}>
                                    <Title>{title}</Title>
                                </Typography>

                                <FilterTag
                                    removeFilter={resetUrlFilter}
                                    title={'Deal of the day'}
                                />
                            </Stack>
                            <Stack direction="row" width={isMobile ? '100%' : 'auto'}>
                                <OutlinedInput
                                    sx={searchInput}
                                    type="text"
                                    size="small"
                                    value={productsParams.search_keyword}
                                    onChange={e => {
                                        setProductsParams({
                                            ...productsParams,
                                            search_keyword: e.target.value,
                                        });
                                    }}
                                    placeholder="Search eg. wet food"
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position="start" sx={{ margin: '0' }}>
                                            <IconButton
                                                edge="start"
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'unset',
                                                    },
                                                }}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        productsParams.search_keyword ? (
                                            <InputAdornment position="end" sx={{ margin: '0' }}>
                                                <IconButton
                                                    edge="end"
                                                    sx={{
                                                        '&:hover': {
                                                            backgroundColor: 'unset',
                                                        },
                                                    }}
                                                >
                                                    <CancelIcon
                                                        onClick={clearSearchFilter}
                                                        sx={{ color: 'darkgray' }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            </Stack>
                        </Grid>
                    </Stack>
                ) : (
                    <>
                        <Grid xs={12}>
                            <CategoryFilter
                                filterVals={categoryData}
                                currFilterId={currCat}
                                setCurrFilterId={setCurrCat}
                                isLoading={isCatLoading}
                                categoryData={categoryData}
                                setProductsParams={setProductsParams}
                                productsParams={productsParams}
                                title={title}
                                clearSearchFilter={clearSearchFilter}
                                resetUrlFilter={resetUrlFilter}
                            />
                        </Grid>
                    </>
                )}

                <Grid container xs={12} spacing={3}>
                    {isLoadingInitial &&
                        Array(10)
                            .fill(null)
                            .map((a, i) => (
                                <Grid key={i} xs={6} md={3} lg={2.4}>
                                    <Skeleton
                                        variant="rounded"
                                        height={isMobile ? '315px' : '365px'}
                                        sx={{
                                            margin: isMobile ? '0 -5px' : '',
                                            borderRadius: '1rem',
                                        }}
                                    />
                                </Grid>
                            ))}

                    {!isLoadingInitial &&
                        products.length > 0 &&
                        products.map((obj: any, i: number): any => {
                            return (
                                <Grid
                                    key={i}
                                    xs={6}
                                    md={3}
                                    lg={2.4}
                                    sx={{
                                        padding: isMobile ? '10px 5px' : '10px',
                                    }}
                                >
                                    <ProductCard
                                        productData={obj}
                                        productsParams={productsParams}
                                        resetQueryFilter={resetQueryFilters}
                                    />
                                </Grid>
                            );
                        })}

                    {!isLoadingInitial && !isProductsLoading && products.length === 0 && (
                        <Grid xs={12}>
                            <ProductNotFound />
                        </Grid>
                    )}

                    {products.length > 1 && (
                        <Grid xs={12} sx={LoadMore}>
                            {hasMore && (
                                <Button
                                    disabled={isProductsLoading}
                                    onClick={() => loadMore()}
                                    sx={LoadMoreButton}
                                    title="Load More"
                                >
                                    {isProductsLoading ? (
                                        <>
                                            <CircularProgress
                                                size={15}
                                                sx={{ mr: 1 }}
                                                color="inherit"
                                            />
                                            Loading
                                        </>
                                    ) : (
                                        'Load More'
                                    )}
                                </Button>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    xs={12}
                    display={'flex'}
                    alignContent={'center'}
                    justifyContent={'center'}
                    sx={{ mt: { xs: 0, md: '3rem' }, mb: { xs: 0, md: '5rem' } }}
                >
                    <FooterBanner />
                </Grid>
            </Grid>
        </>
    );
};

export default ProductInfo;
