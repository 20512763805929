import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCartAction, updateCartAction } from '../service/action/cart/cartActions';
import { selectCartItems } from '../service/selectors/cartSelectors';

import { useNavigate } from 'react-router-dom';
import CartProductDetails from './CartProductDetails';
import { appActions } from '../service/action';

const CartProductList = ({ height, padding, toggleDrawer }: any) => {
    const cartItems = useSelector(selectCartItems);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const removeProductFromCart = (e: any, cartId: any) => {
        var _tempSubTotal = 0;
        const result = cartItems.items.filter((v: any) => {
            let subTotal = cartItems.subtotal;
            _tempSubTotal = subTotal - v.quantity * v.sellingPrice;

            if (v.id != cartId) {
                return v;
            }
        });

        dispatch({
            type: `${appActions.DELETE_CART_ITEM}_SUCCESS`,
            payload: { ...cartItems, subtotal: _tempSubTotal, items: result },
        });

        dispatch(deleteCartAction({ data: cartId }));

        if (cartItems.items.length == 1) {
            toggleDrawer && toggleDrawer(e);
            navigate('/products');
        }
    };

    const handleQuantityChange = (e: any, quantity: number, cartId: any) => {
        var _tempSubTotal = 0;

        const result = cartItems.items.map((v: any) => {
            let subTotal = cartItems.subtotal;
            subTotal = subTotal - v.quantity * v.sellingPrice;

            if (v.id == cartId) {
                v = { ...v, quantity: quantity };
                _tempSubTotal = subTotal + v.quantity * v.sellingPrice;
                // Update the quantity of the selected item
                const updatedItem = { ...v, quantity: quantity };

                return updatedItem;
            }
            return v;
        });
        dispatch({
            type: `${appActions.UPDATE_CART_ITEM}_SUCCESS`,
            payload: { ...cartItems, subtotal: _tempSubTotal, items: result },
        });

        dispatch(updateCartAction({ data: { quantity, id: cartId } }));

        if (cartItems.items.length == 1) {
            if (quantity === 0) {
                toggleDrawer && toggleDrawer(e);
                navigate('/products');
            }
        }
    };

    return (
        <Box
            sx={{
                overflowY: 'scroll',
                height: height,
                scrollbarWidth: 'none',
                padding: padding,
            }}
        >
            {cartItems &&
                cartItems?.items?.length > 0 &&
                cartItems?.items.map(
                    (
                        // { baseInfo, quantity, selectedVariant, variantPriceData, color, id }: any,
                        value: any,
                        index: any
                    ) => {
                        const isLastItem = index === (cartItems ? cartItems?.items?.length : 0) - 1;

                        return (
                            <CartProductDetails
                                handleQuantityChange={handleQuantityChange}
                                removeProductFromCart={removeProductFromCart}
                                value={value}
                                isLastItem={isLastItem}
                            />
                        );
                    }
                )}
        </Box>
    );
};

export default CartProductList;
