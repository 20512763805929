import { Box, CircularProgress, Container, Skeleton, Stack, Typography } from '@mui/material';
import { Headline } from '../confirmation/ConfirmationStyles';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import CouponCard from './CouponCard';
import { getCoupons } from '../../service/api/coupons';

export default function CouponsList({}) {
    const isMobile = useCheckMobileScreen();
    const [coupons, setCouponsData] = useState({
        data: [],
        pagination: {
            page: 0,
            limit: 0,
            total: 0,
        },
    });

    useEffect(() => {
        getcouponsMut.mutate({
            page: 1,
            limit: 100,
        });
    }, []);

    const getcouponsMut = useMutation({
        mutationFn: (data: any) => getCoupons(data),
        onSuccess: (res: any) => {
            setCouponsData(res.data);
        },
        onError: (error: any) => {
            console.log('ERROR WHILE GET COUPONS', error);
        },
    });

    return (
        <Grid container gap={'1rem'} flexDirection={'column'}>
            <Grid item>
                {getcouponsMut.isPending ? (
                    <Stack spacing={0}>
                        <Skeleton
                            width={195}
                            height={70}
                            sx={{ transformOrigin: '0 0', marginBottom: '-15px' }}
                        />
                    </Stack>
                ) : (
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: 'normal',
                            alignSelf: 'stretch',
                        }}
                    >
                        My Coupons
                        {!getcouponsMut.isPending && ` (${coupons?.data?.length})`}
                    </Typography>
                )}
            </Grid>
            <Grid item>
                <Grid container>
                    {getcouponsMut.isPending ? (
                        [...Array(6)].map((_, index) => (
                            <Grid item xs={12} md={4} lg={4}>
                                <Skeleton
                                    key={index}
                                    sx={{
                                        height: '300px',
                                        marginRight: isMobile ? 'auto' : '30px',
                                        marginBottom: '30px',
                                        transform: 'none',
                                        borderRadius: '24px',
                                    }}
                                />
                            </Grid>
                        ))
                    ) : coupons?.data.length > 0 ? (
                        coupons.data?.map((coupon, index) => (
                            <Grid item xs={12} md={4} lg={4}>
                                <CouponCard key={index} coupon={coupon} />
                            </Grid>
                        ))
                    ) : (
                        <Container maxWidth="md" sx={{ pt: '2rem', justifyContent: 'center' }}>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Headline>No coupons available.</Headline>
                            </Box>
                        </Container>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
