import { Box, Typography, useTheme, Container } from '@mui/material';
import { PageWrapper } from './products';
import scribbleBottomSVG from '../assets/images/bookServiceHeightLight.svg';
import {
    HeaderContainer,
    HeightLightImage,
    Title,
} from '../view/bookService/serviceHeader/headerStyle';
import Image from 'mui-image';
import Header from '../view/header/header';
import Footer from '../view/footer/footer';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { useEffect } from 'react';
import { animateScroll } from 'react-scroll';

type Props = {};

export default function TermsAndConditionsPage({}: Props) {
    const theme = useTheme();
    const Date = 'June 12, 2024';
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        animateScroll.scrollToTop();
    }, []);
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            <Header variant="fluid" />
            <Box
                sx={{
                    color: theme.palette.primary.contrastText,
                    textAlign: 'center',
                    py: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {!isMobile && (
                    <div>
                        <Title>Terms & Conditions</Title>
                        <HeaderContainer>
                            <HeightLightImage>
                                <Image src={scribbleBottomSVG} duration={0} alt="image" />
                            </HeightLightImage>
                        </HeaderContainer>
                    </div>
                )}
            </Box>
            <Box>
                <PageWrapper maxWidth={'xl'}>
                    <Container sx={{ py: 4 }}>
                        <Typography variant="body2" sx={{ marginBottom: 3, textAlign: 'end' }}>
                            Last updated : {Date}
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 3 }}>
                            Welcome to SniffyNose, a mobile application ("App") and website ("Site")
                            operated by Sniffy Nose Private Limited, located at Water Villas,
                            Amanora, Hadapsar Kharadi Bypass, Hadapsar, Pune - 411028, India. For
                            any queries or concerns, feel free to reach out to us via email at
                            support@sniffynose.com
                        </Typography>

                        <Typography variant="body1" sx={{ marginBottom: 3 }}>
                            <strong>
                                Please read these Terms of Use carefully before using SniffyNose.
                            </strong>{' '}
                            By accessing or using the App in any manner, including but not limited
                            to browsing or contributing content, you agree to be bound by these
                            Terms of Use.
                        </Typography>

                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Acceptance of Terms and Conditions
                        </Typography>

                        <Typography variant="body1" sx={{ marginBottom: 3 }}>
                            By accessing or using SniffyNose, you agree to abide by these Terms of
                            Use, along with our Privacy Policy. If you do not agree with any part of
                            these terms, you must refrain from using our services.
                        </Typography>

                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Modification
                        </Typography>

                        <Typography variant="body1" sx={{ marginBottom: 3 }}>
                            We reserve the right to modify these Terms of Use at any time. Any
                            changes will be duly notified to users, but it is your responsibility to
                            stay updated with the modifications. Your continued use of SniffyNose
                            after the modifications constitutes your acceptance of the revised
                            terms.
                        </Typography>

                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Prohibitions
                        </Typography>

                        <Typography variant="body1" sx={{ marginBottom: 3 }}>
                            You are granted a limited license to access and use SniffyNose. However,
                            certain actions are prohibited, including but not limited to:
                        </Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>
                                    Reproducing, modifying, or distributing any content from the App
                                    for commercial use.
                                </li>
                                <li>
                                    Violating the copyrights, trademarks, or other proprietary
                                    rights of SniffyNose.
                                </li>
                                <li>
                                    Decompiling or reverse engineering any software accessible
                                    through the App.
                                </li>
                                <li>
                                    Using the App for illegal activities or impairing its operation.
                                </li>
                                <li>
                                    Employing any data mining or extraction methods without
                                    authorization.
                                </li>
                                <li>Posting unsolicited promotional content.</li>
                                <li>
                                    Holding SniffyNose liable for any damage to your device caused
                                    by downloaded content from the App.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Eligibility
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You must be legally eligible to enter into contracts under the Indian
                            Contract Act, 1872, to use SniffyNose. Minors or individuals deemed
                            incompetent to contract are not eligible.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Product Information, Pricing, and Promotional Discounts
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            While we strive for accuracy, there may be errors in product information
                            or pricing. We reserve the right to correct such errors and modify
                            prices as needed. All prices are displayed in Indian Rupees (INR).
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Taxes
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You are responsible for paying all applicable taxes associated with your
                            purchases from SniffyNose.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Errors, Inaccuracies, and Omissions
                        </Typography>

                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We strive for accuracy but may occasionally have inaccuracies on our
                            platform. We reserve the right to correct errors and update information
                            without prior notice.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Contract for Sale of Goods
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            By placing an order on SniffyNose, you agree to the terms and conditions
                            outlined. Payment must be made at the time of ordering, and no contract
                            is formed until we accept your order.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Intellectual Property
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            All content on SniffyNose is owned by us. You may not copy, modify, or
                            create derivative works without our consent.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Links to Other Sites
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            SniffyNose may contain links to third-party websites. We are not
                            responsible for the content or services offered on these external sites.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Guarantees and Warranties
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We exclude all warranties and will not be liable for any damages
                            incurred while using SniffyNose.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Privacy
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            Your privacy is important to us. Please review our Privacy Policy to
                            understand how we handle your data.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Account Information
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You are responsible for maintaining the confidentiality of your account
                            information. Notify us immediately of any unauthorized use of your
                            account.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Cancellation and Refund Policy
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            <ul>
                                <li>
                                    Cancellations will be considered only if the request is made
                                    within 2-3 days of placing the order. However, the cancellation
                                    request may not be entertained if the orders have been
                                    communicated to the vendors/merchants and they have initiated
                                    the process of shipping them.
                                </li>
                                <li>
                                    SNIFFY NOSE does not accept cancellation requests for perishable
                                    items like flowers, eatables etc. However, refund/replacement
                                    can be made if the customer establishes that the quality of
                                    product delivered is not good.
                                </li>
                                <li>
                                    In case of receipt of damaged or defective items please report
                                    the same to our Customer Service team. The request will,
                                    however, be entertained once the merchant has checked and
                                    determined the same at his own end. This should be reported
                                    within 2-3 days of receipt of the products
                                </li>
                                <li>
                                    In case you feel that the product received is not as shown on
                                    the site or as per your expectations, you must bring it to the
                                    notice of our customer service within 2-3 days of receiving the
                                    product. The Customer Service Team after looking into your
                                    complaint will take an appropriate decision.
                                </li>
                                <li>
                                    In case of complaints regarding products that come with a
                                    warranty from manufacturers, please refer the issue to them.
                                </li>
                                <li>
                                    In case of any Refunds approved by the SNIFFY NOSE, it’ll take
                                    3-4 days for the refund to be processed to the end customer.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Frauds
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We reserve the right to take legal action against individuals engaging
                            in fraudulent activities on our platform.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Redeem Vouchers and Promotional Codes
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            Promotional offers are subject to terms set by SniffyNose.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Disclaimer
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You use SniffyNose at your own risk, and we do not warrant its use.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Indemnification
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You agree to indemnify SniffyNose from any claims arising out of your
                            breach of these terms.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Governing Law and Dispute Resolution
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            These terms are governed by Indian law, and any disputes will be
                            resolved in courts in New Delhi.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Grievance Redressal
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            For any complaints or concerns, please contact our designated Grievance
                            Officer.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Termination
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We reserve the right to terminate access to SniffyNose without prior
                            notice.
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Entire Agreement
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            These Terms of Use constitute the entire agreement between you and
                            SniffyNose.
                        </Typography>

                        <Typography variant="body1" sx={{ marginTop: 3 }}>
                            By accepting these Terms of Use, you acknowledge that you have read and
                            understood all the terms and conditions outlined herein.
                        </Typography>
                    </Container>
                </PageWrapper>
                <Footer />
            </Box>
        </Box>
    );
}
