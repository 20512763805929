import { useRoutes } from 'react-router-dom';
// import Home from "../pages";
import Confirmation from '../pages/confirmation';
import StoreLocation from '../pages/storeLocation';
import Support from '../pages/support';
import Products from '../pages/products';
import Invite from '../pages/invite';
import OrderPage from '../pages/OrderPage';
import ConfirmationPage from '../pages/ConfirmationPage';
import BookServicePage from '../pages/BookServicePage';
import PrivacyPolicyPage from '../pages/privacyPolicy';
import TermsAndConditionsPage from '../pages/TermsAndCondition';
import MyRewards from '../view/rewards/MyRewards';
import MyCoupons from '../view/coupon/MyCoupons';
import AuthGuard from '../component/AuthGuard';
import ProfilePreview from '../view/auth/ProfilePreview';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import MyOrders from '../view/order/MyOrders';
import AllRewards from '../view/rewards/AllRewards';

const AppRoutes = () => {
    const isMobile = useCheckMobileScreen();
    const routes = useRoutes([
        // { path: "/", element: <Home /> },
        { path: '/', element: <Products /> },
        { path: '/service', element: <BookServicePage /> },
        { path: '/confirm', element: <Confirmation /> },
        { path: '/location', element: <StoreLocation /> },
        { path: '/support', element: <Support /> },
        { path: '/products', element: <Products /> },
        { path: '/products/deals', element: <Products page={'deals'} /> },
        { path: '/order', element: <OrderPage /> },
        {
            path: '/order/confirmation/:orderId',
            element: <ConfirmationPage />,
        },
        {
            path: '/profile/order/:orderId',
            element: (
                <AuthGuard>
                    <ConfirmationPage page={'order-details'} />
                </AuthGuard>
            ),
        },
        {
            path: '/profile',
            element: isMobile && (
                <AuthGuard>
                    <ProfilePreview />
                </AuthGuard>
            ),
        },
        {
            path: '/profile/orders',
            element: (
                <AuthGuard>
                    <MyOrders />
                </AuthGuard>
            ),
        },
        {
            path: '/profile/coupons',
            element: (
                <AuthGuard>
                    <MyCoupons />
                </AuthGuard>
            ),
        },
        {
            path: '/profile/rewards',
            element: (
                <AuthGuard>
                    <MyRewards />
                </AuthGuard>
            ),
        },
        {
            path: '/rewards',
            element: <AllRewards />,
        },
        { path: '/invite', element: <Invite /> },
        { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
        { path: '/terms-conditions', element: <TermsAndConditionsPage /> },
    ]);
    return routes;
};

export default AppRoutes;
