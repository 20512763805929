import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const RewardPointsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    padding: '9px',
    background: 'rgba(109, 57, 255, 0.10)',
    justifyContent: 'center',
    gap: '9px',
});

export const PointsStyle = styled('span')({
    color: '#6D39FF',
    fontWeight: 600,
    fontSize: '16px',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
});
export const DiscountPriceStyle = styled('span')({
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    color: '#19B100',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
});

export const DiscountLabel = styled('span')({
    color: '#FFF',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
});
export const SecondDiscountLabel = styled('span')({
    padding: '8px 12px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '80px',
    background: '#FFF',
    color: '#14141E',
    fontSize: '16px',
    fontWeight: '400px',
});
/* export const SecondDiscountLabel = styled('span')({
    overflow: 'hidden',
    color: '#14141E',
    border: '1px solid white',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    gap: '10px',
    borderRadius: '25px',
    padding: '5px 10px 5px 10px',
    width: '70%',
    background: 'white !important',
}); */

export const SecondBannerContainerLabel = {
    color: '#000',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
};
export const SecondBannerContainerChildBox = {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
};

export const BannerContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#19B100',
    color: '#FFFFFF',
    padding: '10px 22px',
    gap: '1rem',
});
export const SecondBannerContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#DAF5D6',
    color: '#FFFFFF',
    padding: '7px 10px',
});
