import {
  FormTitle,
  ViewBox,
  ViewMore,
  ViewImage,
  FormContainer,
} from "./serviceDetailsStyle";
import viewMore from "../../../assets/images/viewMore.svg";
import viewLess from "../../../assets/images/ViewLess.svg";
import Image from "../../../component/image/image";
import PetCard from "./petCard";
import PetForm from "./petForm";

const PetDetails = ({
  pet,
  index,
  handlePetFieldChange,
  petsCount,
  isMobile,
}: any) => {
  return (
    <>
      <FormContainer
        sx={{
          borderRadius: petsCount > 1 ? "0.75rem 0.75rem 0 0 " : "0.75rem",
        }}
      >
        <FormTitle>#{1 + index} Pet</FormTitle>

        {!pet.isOpen ? (
          <PetCard pet={pet} />
        ) : (
          <PetForm
            index={index}
            handlePetFieldChange={handlePetFieldChange}
            pet={pet}
            isMobile={isMobile}
          />
        )}
      </FormContainer>
      {petsCount > 1 && (
        <ViewBox
          onClick={() => handlePetFieldChange(index, "isOpen", !pet.isOpen)}
        >
          <ViewMore>
            {pet.isOpen ? "View Less" : "View More"}
            <ViewImage>
              <Image src={pet.isOpen ? viewLess : viewMore} alt="image" />
            </ViewImage>
          </ViewMore>
        </ViewBox>
      )}
    </>
  );
};

export default PetDetails;
