import request from '../api/request';

export function getSignInUpCode(data?: any) {
    return request({
      url: `auth/signinup/code`,
      method: 'post',
      data,
    });
  }

export function consumeSignInUpCode(data?: any) {
    return request({
      url: `auth/signinup/code/consume`,
      method: 'post',
      data,
    });
  }

export function getUser(params?: any) {
    return request({
      url: `users/me`,
      method: 'get',
      params,
    });
  }

  export const usersApi: any = {
    GET_USER: async(payload:any) => await getUser(),   
  }
