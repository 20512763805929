import { Box, Typography,  styled } from '@mui/material';
import { appColor } from '../../common/appColor/appColor';

export const Container = styled(Box)`
    box-sizing: border-box;
    width: 100%;
    background-color: ${appColor.white};
    border-radius: 50px;
    padding: 50px;
    margin-top: 200px;
    @media (max-width: 768px) {
        padding: 20px;
        margin-top: 330px;
    }
`;

export const Heading = styled(Typography)`
    color: var(--Black, #14141e);
    font-family: Euclid-Circular-A-Medium;
    font-size: 1.5rem;
    font-style: normal;
    line-height: normal;
    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

export const BillDetailsBox = {
    display: 'flex',
    padding: '16px 4px 4px 4px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch',
    borderRadius: '12px',
    background: '#F6F6F6',
};

export const BillItems = {
    display: 'flex',
    padding: '0px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
};

export const BillItemName = {
    overflow: 'hidden',
    color: 'var(--Dark-Brown-Dark-Brown---90, #393637)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
};

export const BillItemNameText = {
    ...BillItemName,
    color: '#747474',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.85rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    marginTop: '.2rem',
}

export const BillItemAmount = {
    overflow: 'hidden',
    color: 'var(--Black, #14141E)',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
};

export const BillToPay = {
    color: 'var(--Dark-brown, #241F1F)',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
};

export const BillFinalAmount = {
    color: 'var(--Black, #14141E)',
    textAlign: 'right',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
};

export const NotesStyle = {
    color: '#333',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
};

export const PaymentDetailsBox = {
    border: 'var(--Cart, 1px) solid rgba(204, 204, 204, 0.50)',
    borderRadius: '16px',
    width: "50%",
    '@media(max-width: 768px)': {
        width: "100%",
    },
};

export const PayOnDelivery = {
    overflow: 'hidden',
    color: 'var(--Black, #14141E)',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
};

export const PayOnDeliverySpan = {
    color: 'var(--Black, #5E5E5E)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const RecommendBox = {
    display: 'flex',
    padding: '4px 6px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2px',
    borderRadius: '6px',
    background: 'rgba(25, 177, 0, 0.10)',
    color: '#19B100',
};

export const RecommendText = {
    overflow: 'hidden',
    leadingTrim: 'both',
    textEdge: 'cap',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
};

export const NoteBox = {
    gap: '3px',
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 768px)': {
        marginBottom: '20px',
    },
};

export const OrderStatus = {
    backgroundColor: 'rgba(16, 110, 68, 0.10);',
    color: '#106E44', 
    fontWeight: 'bold',
    borderColor: '#f2f2f2',
    borderRadius: '16px',
    padding: "8px"
}

export const PriceStyle = {
    color: 'var(--Black, #14141E)',
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFeatureSettings: "'dlig' on",
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '1.1rem',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.03125rem',
    textTransform: 'capitalize',
};

export const SecondaryTypographyStyle = {
    color: '#747474',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const RewardsPointsLabel = {
color: '#4F4C4D',
fontSize: '12px',
fontStyle: 'normal',
fontWeight: '500',
lineHeight: 'normal',
textTransform: 'uppercase',
};

export const StepTextStyle = {
    color: '#7F808C',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
};

export const SnackbarStyle = {
    ".MuiSnackbarContent-root": {
        background: 'red !important',
        color: '#fff !important',
        fontSize: '1rem !important',
    }
};


export const RedeemAndApplyBox = {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    '@media(max-width: 768px)': {
        flexDirection: "column",
        gap: "12px",
        alignItems: "start",
    },
}