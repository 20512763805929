import { Box, Grid, useTheme } from '@mui/material';

import { Fragment } from 'react';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import Layout from '../../component/layout/layout';
import CustomHero, { HeroWrapper } from '../../component/CustomHero';
import { PageWrapper } from '../../pages/products';
import ErrorBoundary from '../../component/ErrorBoundary';
import FooterBanner from '../../component/FooterBanner';
import ProfilePreview from '../profilePreview/ProfilePreview';
import RewardsList from './RewardsList';

export default function AllRewards() {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();
    return (
        <Fragment>
            <Layout>
                <Box sx={{ backgroundColor: theme.palette.background.default }}>
                    <HeroWrapper
                        sx={{
                            paddingTop: isMobile ? 0 : '3.5rem',
                            paddingBottom: isMobile ? 0 : '3.5rem',
                        }}
                    >
                        <CustomHero title={'Rewards & offers'} />
                    </HeroWrapper>
                    <PageWrapper maxWidth={false}>
                        <ErrorBoundary>
                            <RewardsList />
                        </ErrorBoundary>
                        <Box pb={isMobile ? '' : '5rem'} pt={isMobile ? '' : '5rem'}>
                            <FooterBanner />
                        </Box>
                    </PageWrapper>
                </Box>
            </Layout>
        </Fragment>
    );
}
