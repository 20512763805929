import { useState, useEffect } from "react";
import Image from "../../component/image/image";
import Header from "./inviteHeader";
import {
  ButtonBox,
  ButtonMainBox,
  Container,
  MainContainer,
  MayBeButton,
  ModalContent,
  NameText,
  Nav,
  NoButton,
  YesButton,
  Modal,
} from "./invitePageStyle";
import mobile from "../../assets/images/invitePage.png";
import desktop from "../../assets/images/desktopInvitePage.png";
import useCheckMobileScreen from "../../common/hooks/useCheckMobileScreen";
import axios from "axios";

const InvitePage = () => {
  const isMobile = useCheckMobileScreen();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [yesLoading, setYesLoading] = useState(false);
  const [noLoading, setNoLoading] = useState(false);
  const [maybeLoading, setMaybeLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const name = params.get("name") || "";
    const phone = params.get("ph") || "";

    if (!name || !phone) {
      window.location.href = "/";
    }

    setName(name);
    setPhone(phone);
  }, []);

  const handleResponse = async (responseType: string, loaderCaller: any) => {
    // setButtonColor("#fddc2e");
    loaderCaller(true)

    try {
      await axios.post(
        "https://sq7bdg4aw2.execute-api.ap-south-1.amazonaws.com/prod",
        {
          phone: phone,
          name: name,
          rsvp: responseType,
          event: "christmas-2023",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );



      setShowPopup(true);


      setTimeout(() => {
        setShowPopup(false);
        window.location.href = "/";
        loaderCaller(false)
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <MainContainer>
        <NameText sx={{fontWeight: 'bold'}}>Hi! {name}</NameText>
        <Container>
          {isMobile ? (
            <Image src={mobile} alt="image" />
          ) : (
            <Image src={desktop} alt="image" />
          )}
          <ButtonMainBox>
            <ButtonBox>
              <YesButton loading={yesLoading}
                onClick={() => handleResponse("yes", setYesLoading)}
              >
                Yes
              </YesButton>
              <MayBeButton loading={maybeLoading}
                onClick={() => handleResponse("maybe", setMaybeLoading)}
              >
                Maybe
              </MayBeButton>
              <NoButton loading={noLoading}
                onClick={() => handleResponse("no", setNoLoading)}

              >
                No
              </NoButton>
            </ButtonBox>

            {isMobile && <Nav />}
          </ButtonMainBox>
        </Container>
      </MainContainer>

      <Modal open={showPopup}>
        <ModalContent>
          <h2>Thank you for your response</h2>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InvitePage;
