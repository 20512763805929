import { ReactComponent as Map } from '../../assets/icons/map.svg';
import { ReactComponent as MapMob } from '../../assets/icons/map-mob.svg';
import { ReactComponent as Media } from '../../assets/icons/media.svg';
import { ReactComponent as MediaMob } from '../../assets/icons/headphone-mob.svg';
import { ReactComponent as ScissorsFill } from '../../assets/icons/scissors-fill.svg';
import { ReactComponent as ScissorsFillMob } from '../../assets/icons/scissors-mob.svg';
import { ReactComponent as ShoppingBagFill } from '../../assets/icons/shopping-bag-fill.svg';
import { ReactComponent as ShoppingBagFillMob } from '../../assets/icons/shop-mob.svg';
import { ReactComponent as Logo } from '../../assets/logos/logo.svg';
import { ReactComponent as LogoYellow } from '../../assets/logos/logo-yellow.svg';
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as YouTube } from '../../assets/icons/youtube.svg';
import { ReactComponent as Charity } from '../../assets/icons/charity.svg';
import { ReactComponent as HomeAddress } from '../../assets/icons/home-address.svg';
import { ReactComponent as CustomerSupport } from '../../assets/icons/customer-support.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowDownSmall } from '../../assets/icons/arrow-down-small.svg';
import { ReactComponent as ArrowUpSmall } from '../../assets/icons/arrow-up-small.svg';
import { ReactComponent as DesktopScissorsFill } from '../../assets/icons/desktop-scissors-fill.svg';
import { ReactComponent as DesktopMedia } from '../../assets/icons/desktop-media.svg';
import { ReactComponent as DesktopShoppingBag } from '../../assets/icons/desktop-shopping-bag.svg';
import { ReactComponent as DesktopMap } from '../../assets/icons/desktop-map.svg';
import { ReactComponent as Discount } from '../../assets/icons/Discount.svg';
import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg';
import { ReactComponent as EndStack } from '../../assets/icons/end-stack.svg';
import { ReactComponent as Close } from '../../assets/icons/close-line.svg';
import { ReactComponent as SearchMob } from '../../assets/icons/search-mob.svg';
import { ReactComponent as Mail } from '../../assets/icons/email.svg';
import { ReactComponent as WhatsApp } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as Stars } from '../../assets/icons/starts.svg';
import { ReactComponent as ConfirmationGraphic } from '../../assets/icons/corp-conf.svg';
import { ReactComponent as Calender } from '../../assets/icons/custom-calender.svg';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { ReactComponent as Exchange } from '../../assets/icons/exchange.svg';
import { ReactComponent as Return } from '../../assets/icons/Return.svg';
import { ReactComponent as Secure } from '../../assets/icons/secure.svg';
import { ReactComponent as COD } from '../../assets/icons/CODicon.svg';
import { ReactComponent as FastDelivery } from '../../assets/icons/superFastDelivery.svg';
import { ReactComponent as Service } from '../../assets/icons/fi_services.svg';
import { ReactComponent as Wallet } from '../../assets/icons/wallet.svg';
import { ReactComponent as POD } from '../../assets/icons/payOnDelivery.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as HotDeals } from '../../assets/icons/hot-deals.svg';
import { ReactComponent as Profile } from '../../assets/icons/profileIcon.svg';
import { ReactComponent as Coupons } from '../../assets/icons/coupons.svg';
import { ReactComponent as MyOrders } from '../../assets/icons/my-orders.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as Rewards } from '../../assets/icons/rewards.svg';
import { ReactComponent as MoneyStack } from '../../assets/icons/moneyStack.svg';
import { ReactComponent as SizeGuide } from '../../assets/icons/size_guide.svg';
import { ReactComponent as DiscountCode } from '../../assets/icons/discount-coupon.svg';
import { ReactComponent as LoginFrame } from '../../assets/icons/login-frame.svg';
import { ReactComponent as TrackShipment } from '../../assets/icons/TrackingIcon.svg';
import { ReactComponent as Rating } from '../../assets/icons/Rating-star.svg';
import { ReactComponent as DeliveryTime } from '../../assets/icons/delivery-time.svg';

interface IconPropsType {
    name: String;
}

const Icon = (props: IconPropsType) => {
    const isMobile = useCheckMobileScreen();
    switch (props.name) {
        case 'map':
            return <Map />;
        case 'map-mob':
            return <MapMob />;
        case 'media':
            return <Media />;
        case 'media-mob':
            return <MediaMob />;
        case 'scissors-fill':
            return <ScissorsFill />;
        case 'scissors-fill-mob':
            return <ScissorsFillMob />;
        case 'bag-fill':
            return <ShoppingBagFill />;
        case 'bag-fill-mob':
            return <ShoppingBagFillMob />;
        case 'discount-coupon':
            return <DiscountCode />;
        case 'logo':
            return <Logo />;
        case 'logo-yellow':
            return (
                <LogoYellow
                    style={{
                        height: isMobile ? '2.125rem' : '3rem',
                        width: isMobile ? 'auto' : '3rem',
                    }}
                />
            );
        case 'facebook':
            return <Facebook />;
        case 'instagram':
            return <Instagram />;
        case 'twitter':
            return <Twitter />;
        case 'youtube':
            return <YouTube />;
        case 'charity':
            return <Charity />;
        case 'home-address':
            return <HomeAddress />;
        case 'customer-support':
            return <CustomerSupport />;
        case 'arrow-down':
            return <ArrowDown />;
        case 'arrow-down-small':
            return <ArrowDownSmall />;
        case 'arrow-up-small':
            return <ArrowUpSmall />;
        case 'desktop-map':
            return <DesktopMap />;
        case 'desktop-media':
            return <DesktopMedia />;
        case 'desktop-shopping-bag':
            return <DesktopShoppingBag />;
        case 'desktop-scissors-fill':
            return <DesktopScissorsFill />;
        case 'discount':
            return <Discount />;
        case 'hamburger-menu':
            return <Hamburger />;
        case 'end-note':
            return <EndStack />;
        case 'close-icon':
            return <Close />;
        case 'search-mob':
            return <SearchMob />;
        case 'whatsapp':
            return <WhatsApp />;
        case 'mail':
            return <Mail />;
        case 'stars':
            return <Stars />;
        case 'confirmation-graphic':
            return <ConfirmationGraphic />;
        case 'calender':
            return <Calender />;
        case 'secure':
            return <Secure />;
        case 'no-exchange':
            return <Exchange />;
        case 'easy-return':
            return <Return />;
        case 'fast-delivery':
            return <FastDelivery />;
        case 'COD':
            return <COD />;
        case 'fi-service':
            return <Service />;
        case 'Pay-on-delivery':
            return <POD />;
        case 'wallet':
            return <Wallet />;
        case 'home':
            return <Home />;
        case 'user':
            return <User />;
        case 'hot-deals':
            return <HotDeals />;
        case 'profile':
            return <Profile />;
        case 'coupons':
            return <Coupons />;
        case 'myOrders':
            return <MyOrders />;
        case 'rewards':
            return <Rewards />;
        case 'logoIcon':
            return <LogoIcon />;
        case 'rewardPoints':
            return <Rewards />;
        case 'moneyStack':
            return <MoneyStack />;
        case 'size-guide':
            return <SizeGuide />;
        case 'login-frame':
            return <LoginFrame />;
        case 'Track-shipment':
            return <TrackShipment />;
        case 'Rating':
            return <Rating />;
        case 'Delivery-Time':
            return <DeliveryTime />;
        default:
            return <>Not available</>;
    }
};

export default Icon;
