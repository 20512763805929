import { Stack, Typography } from '@mui/material';
import {
    getProductDiscountLabel,
    getSizeMedia,
    isValidVariantToShowDiscount,
} from '../../common/utils/productUtils';
import Icon from '../icon';
import {
    OptionDiscountLabel,
    OptionName,
    SizeGuideStyle,
    StyledToggleButton,
    StyledToggleButtonGroup,
} from './ProductStyle';

const getObj: any = (index: any, options: any, val: any) => {
    for (const option of options[index].values) {
        if (option.name === val) {
            return option;
        }
    }
    return null; // Return null if the object with the specified name is not found
};

const Options = ({
    index,
    options,
    name,
    values,
    getVariantValue,
    productData,
    setSizeChartImages,
    defaultPref,
    setDefaultPref,
    product,
}: any) => {
    const tempName = name;
    const sizeMedias = getSizeMedia(productData);

    let isViewChartAvailable = name.toLowerCase() === 'size' && sizeMedias.length > 0;

    return (
        <>
            <Stack>
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography sx={OptionName}>{name}</Typography>

                    {isViewChartAvailable && (
                        <Stack flexDirection={'row'} gap={'4px'}>
                            <Icon name="size-guide" />
                            <Typography
                                sx={SizeGuideStyle}
                                onClick={() => setSizeChartImages(sizeMedias)}
                            >
                                Size Guide
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                <StyledToggleButtonGroup
                    value={getVariantValue(name, values)}
                    exclusive
                    size="small"
                    onChange={(_, val) => {
                        if (val) {
                            const varObj = getObj(index, options, val);
                            let selectedVariants: any = defaultPref.selectedVariant;
                            let variantToUpdate = selectedVariants.find(
                                (item: any) => item.variant === name
                            );

                            variantToUpdate.value = varObj ? varObj : null;

                            setDefaultPref((prev: any) => ({
                                ...prev,
                                selectedVariant: selectedVariants,
                            }));
                        }
                    }}
                    sx={{
                        flexWrap: 'wrap',
                    }}
                >
                    {values.map(({ name, id }: any) => {
                        let label = '';

                        const shouldShowForDiscount = isValidVariantToShowDiscount(
                            tempName,
                            productData
                        );

                        if (shouldShowForDiscount) {
                            label = getProductDiscountLabel({
                                product: product,
                                selectedVariant: id,
                            });
                        }

                        return (
                            <StyledToggleButton
                                value={name}
                                onClick={() => {}}
                                aria-label="left aligned"
                                sx={{
                                    paddingTop: label ? '20px' : '',
                                }}
                            >
                                {name}
                                {label && <Typography sx={OptionDiscountLabel}>{label}</Typography>}
                            </StyledToggleButton>
                        );
                    })}
                </StyledToggleButtonGroup>
            </Stack>
        </>
    );
};

export default Options;
