import React, { useState } from 'react';
import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share';
import { ContentCopy } from '@mui/icons-material';
const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
};

const ShareMenu = ({ productId }: any) => {

    const { protocol, hostname, port } = window.location;
    
    const currentRoute = window.location.pathname;
    let url: any = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

    if (productId) {
        url += `${currentRoute}?product=${productId}`;
    }
    
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e: any) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const [copied, setCopied] = useState(false);
    const handleCopy = (e: any) => {
        e.stopPropagation();

        // Construct the base URL
        navigator.clipboard.writeText(url).then(
            () => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            },
            err => {
                console.error('Failed to copy text: ', err);
            }
        );
    };

    return (
        <div>
            <IconButton
                aria-label="share"
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    zIndex: 2000,
                    borderRadius: '27px',
                    background: 'var(--BG-BG---Primary, #FFF)',
                    boxShadow: '0px 2px 0px 0px #D9D9D9',
                    color: '#0A69D9',
                }}
                onClick={e => handleClick(e)}
            >
                <ShareOutlinedIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiMenu-paper': {
                        borderRadius: '12px',
                        marginTop: '5px',
                    },
                }}
                onClose={e => handleClose(e)}
            >
                <MenuItem onClick={e => handleCopy(e)}>
                    <Typography width={'100%'} onClick={handleCopy} sx={menuItemStyle}>
                        <ContentCopy sx={{ color: '#0A69D9' }} />
                        {copied ? 'Copied' : 'Copy'}
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={e => handleClose(e)}>
                    <FacebookShareButton url={String(url)}>
                        <Typography sx={menuItemStyle}>
                            <FacebookIcon size={25} round={true} />
                            Facebook
                        </Typography>
                    </FacebookShareButton>
                </MenuItem>
                <Divider />
                <MenuItem onClick={e => handleClose(e)}>
                    <TwitterShareButton url={String(url)}>
                        <Typography sx={menuItemStyle}>
                            <TwitterIcon size={25} round={true} />
                            Twitter
                        </Typography>
                    </TwitterShareButton>
                </MenuItem>
                <Divider />
                <MenuItem onClick={e => handleClose(e)}>
                    <WhatsappShareButton url={String(url)}>
                        <Typography sx={menuItemStyle}>
                            <WhatsappIcon size={25} round={true} />
                            Whatsapp
                        </Typography>
                    </WhatsappShareButton>
                </MenuItem>
                <Divider />
                <MenuItem onClick={e => handleClose(e)}>
                    <LinkedinShareButton url={String(url)}>
                        <Typography sx={menuItemStyle}>
                            <LinkedinIcon size={25} round={true} />
                            Linkedin
                        </Typography>
                    </LinkedinShareButton>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ShareMenu;
