import * as Yup from 'yup';

export const states = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
];

export const validationUserSchema = Yup.object().shape({
    firstName: Yup.string().required('Your Name is required'),
    phoneNumber: Yup.string()
        .matches(/^[0-9\-\\(\\)\\.]{10,15}$/, 'Phone number must be at least 10 digits long.')
        .required('Phone Number is required'),
});

export const validationAddressSchema = Yup.object().shape({
    address_line_one: Yup.string().required('Address Line One is required'),
    address_line_two: Yup.string().required('Address Line Two is required'),
    pincode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Pincode must be at least 6 digits long.')
        .required('Pincode is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
});

export const validationSchemaStep1 = Yup.object().shape({
    user: validationUserSchema,
});

export const validationSchemaStep2 = Yup.object().shape({
    user: validationUserSchema,
    address: validationAddressSchema,
});

export const initialValues = {
    user: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: null,
    },
    products: [
        {
            name: '',
            _id: '',
            quantity: '',
            mrp: '',
            discount: '',
            short_description: '',
            Size: '',
            media: '',
            selling_price: '',
        },
    ],

    address: {
        id: '',
        completeAddress: '',
        address_line_one: '',
        address_line_two: '',
        city: '',
        state: 'Maharashtra',
        country: 'India',
        pincode: '',
    },
    is_delivery: true,
    is_pickup: false,
    pickup_location: 'hadapsar',
    notes: '',
};

export interface Coupon {
    id: string;
    code: string;
    description: string;
    title: string;
    discountValue: number;
    discountMetric: string;
    metaData: {
        usedCount: number;
        minOrderValue: number;
        maxDiscountValue: number;
    };
    expirationDate: string;
    normalizedDiscountValue: number;
    isExpired: false;
}
export interface address {
    id: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    pinCode: string;
    country: string;
}

export const initialOrderData = {
    metaData: {
        notes: '',
    },
    name: '',
    phone: '',
    addressId: '',
    promotionCode: '',
    paymentMode: '',
    cartSessionId: '',
};

export const initialAvailableAddressDataFormat = [
    {
        id: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        pinCode: '',
        country: '',
    },
];

export const FormattedOrderStatus = (status: string) => {
    switch (status?.toLocaleLowerCase()) {
        case 'received':
        case 'shipped':
            return 'In Progress';
        case 'ready_to_pickup':
            return 'Ready to pickup';
        case 'completed':
            return 'Delivered';
        case 'cancelled':
            return 'Cancelled';
        default:
            return 'In progress';
    }
};
