import { useState, useEffect } from 'react';
import Icons from '../../component/icon';
import {
    AccountBox,
    CartAndAccountBox,
    DesktopItemLabel,
    HeaderContainer,
    Headline,
    Item,
    NewAppBar,
    StyleLink,
    StyleMobProfile,
    TooltipStyle,
} from './headerStyle';
import { desktopNavItem, navItem } from '../../common/utils/navItem';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Badge, InputBase, Typography } from '@mui/material';
import {
    Box,
    Collapse,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CartView from '../cart/CartView';
import { getCartsAction } from '../../service/action/cart/cartActions';
import { selectCartItems, selectCartDrawer } from '../../service/selectors/cartSelectors';
import { appActions } from '../../service/action';
import { Icon } from '@iconify/react';

import LoginSignup from '../auth/loginSignup';
import PersonIcon from '@mui/icons-material/Person';
import { getDealsLabel } from '../../common/utils/dealsNavigationLabel';
import { getUserAction } from '../../service/action/userActions';
import { userSelector } from '../../service/selectors/userSelector';

const Header = ({
    variant = 'static',
    enableSearch = false,
}: {
    variant?: 'fluid' | 'static';
    enableSearch?: Boolean;
}) => {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const isMobile = useCheckMobileScreen();
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [isMobileMenuExpanded, setIsMobileMenuExpanded] = useState(false);

    const cartItems = useSelector(selectCartItems);
    const cartDrawer = useSelector(selectCartDrawer);
    const [openModal, setOpenModal] = useState(false);

    const location = useLocation();
    const currentRoute = location.pathname;
    const { orderId } = useParams();

    const token = localStorage.getItem('accessToken');
    const [authToken, setAuthToken] = useState(token);
    const userData = useSelector(userSelector);

    let title = 'Our Products';
    if (currentRoute === '/products') {
        title = 'Our Products';
    }
    if (currentRoute === '/products/deals') {
        title = getDealsLabel();
    }
    if (currentRoute === '/service') {
        title = 'Book a Service';
    }
    if (currentRoute === '/order') {
        title = 'Checkout';
    }
    if (orderId) {
        title = 'Confirmation';
    }
    if (currentRoute === '/privacy-policy') {
        title = 'Privacy Policy';
    }
    if (currentRoute === '/terms-conditions') {
        title = 'Terms & Conditions';
    }
    if (currentRoute === '/profile') {
        title = 'My Account';
    }
    if (currentRoute === '/profile/rewards') {
        title = 'My Rewards';
    }
    if (currentRoute === '/profile/coupons') {
        title = 'Coupons';
    }
    if (currentRoute === '/profile/orders') {
        title = 'My Orders';
    }

    const toggleCartDrawer = (anchor: any, open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        dispatch({
            type: `${appActions.SET_CART_DRAWER}_SUCCESS`,
            payload: { ...cartDrawer, [anchor]: open },
        });
    };

    const handleAddToCart = () => {
        toggleCartDrawer('right', true)({});
    };

    const toggleSearch = () => {
        setIsSearchExpanded(!isSearchExpanded);
    };

    const toggleMenu = () => setIsExpanded(prevState => !prevState);

    const openLoginModal = () => {
        if (isMobile) {
            toggleMenu();
        }
        setOpenModal(true);
    };

    useEffect(() => {
        window.scroll(0, 10);
    }, [location]);

    useEffect(() => {
        dispatch(getCartsAction({}));
    }, []);

    useEffect(() => {
        const user = Object.keys(userData).length == 0;

        if (user && token) {
            dispatch(getUserAction({}));
        }
    }, [authToken]);

    return (
        <HeaderContainer>
            <NewAppBar
                position={!isMobile ? (variant === 'fluid' ? 'relative' : undefined) : undefined}
                elevation={0}
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: '#14141e',
                    borderBottom: 'var(--Cart, 1px) solid #2E2E40',
                    alignItems: 'center',
                    height: isMobile ? 'auto' : '72px',
                }}
            >
                <Toolbar sx={TooltipStyle}>
                    {!isMobile ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '2.75rem',
                            }}
                        >
                            {desktopNavItem.map(item => (
                                <Link to={item.path || ''} style={StyleLink}>
                                    <Item key={Math.random()}>
                                        <Icons name={item.icon} />
                                        <DesktopItemLabel>{item.label}</DesktopItemLabel>
                                    </Item>
                                </Link>
                            ))}
                        </Box>
                    ) : (
                        <>
                            <Link to="/" style={{ height: '2.125rem' }}>
                                <Icons name={'logo-yellow'} />
                            </Link>

                            {/* <Fade in={!isExpanded}> */}
                            <Fade in={!isSearchExpanded && !isMobileMenuExpanded}>
                                <Headline
                                    sx={{
                                        display: 'inline-block',
                                        float: 'none',
                                        marginTop: '-4px',
                                    }}
                                >
                                    {title}
                                </Headline>
                            </Fade>
                            <Stack direction="row" alignItems="center" spacing={0} gap={'12px'}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    sx={{ display: enableSearch ? 'block' : 'none' }}
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleSearch}
                                >
                                    <Icons name={'search-mob'} />
                                </IconButton>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                >
                                    <Badge
                                        sx={{
                                            '.MuiBadge-badge': {
                                                backgroundColor: '#FF4E16',
                                            },
                                        }}
                                        badgeContent={
                                            cartItems && cartItems?.items?.length > 0 ? (
                                                <span
                                                    style={{
                                                        color: '#FFF',
                                                        fontFamily: 'Euclid-Circular-A-Medium',
                                                        fontSize: '1rem',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                    }}
                                                >
                                                    {cartItems ? cartItems?.items?.length : 0}
                                                </span>
                                            ) : null
                                        }
                                        color="secondary"
                                        onClick={handleAddToCart}
                                    >
                                        <Icon color="#FFF" fontSize={'1.5rem'} icon="bxs:cart" />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    onClick={toggleMenu}
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ paddingRight: 0 }}
                                >
                                    {isMobileMenuExpanded ? (
                                        <Icons name={'close-icon'} />
                                    ) : (
                                        <Icons name={'hamburger-menu'} />
                                    )}
                                </IconButton>
                            </Stack>
                        </>
                    )}
                    {!isMobile && (
                        <>
                            <Box sx={CartAndAccountBox}>
                                <Box sx={AccountBox}>
                                    {!authToken && (
                                        <>
                                            <Badge
                                                sx={{
                                                    '.MuiBadge-badge': {
                                                        backgroundColor: '#FF4E16',
                                                    },
                                                }}
                                                onClick={openLoginModal}
                                                color="secondary"
                                            >
                                                <PersonIcon sx={{ color: 'white' }} />
                                            </Badge>
                                            <DesktopItemLabel onClick={openLoginModal}>
                                                {`Login/Signup`}
                                            </DesktopItemLabel>
                                        </>
                                    )}

                                    {authToken && (
                                        <Link to="/profile/rewards" style={StyleLink}>
                                            <Item>
                                                <PersonIcon sx={{ color: 'white' }} />
                                                <DesktopItemLabel>My Account</DesktopItemLabel>
                                            </Item>
                                        </Link>
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        alignItems: 'end',
                                        color: '#FFF',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleAddToCart}
                                >
                                    <Badge
                                        sx={{
                                            '.MuiBadge-badge': {
                                                backgroundColor: '#FF4E16',
                                            },
                                        }}
                                        badgeContent={
                                            cartItems && cartItems?.items?.length > 0 ? (
                                                <span
                                                    style={{
                                                        color: '#FFF',
                                                        fontFamily: 'Euclid-Circular-A-Medium',
                                                        fontSize: '1rem',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                    }}
                                                >
                                                    {cartItems ? cartItems?.items?.length : 0}
                                                </span>
                                            ) : null
                                        }
                                        color="secondary"
                                    >
                                        <Icon color="#FFF" fontSize={'1.5rem'} icon="bxs:cart" />
                                    </Badge>
                                    <Typography sx={{ fontSize: '14px' }}>Cart</Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </Toolbar>
                <Collapse in={isSearchExpanded}>
                    <Toolbar>
                        <InputBase
                            placeholder="Search..."
                            sx={{
                                flexGrow: 1,
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                padding: '0.5rem',
                            }}
                        />

                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="search"
                            onClick={toggleSearch}
                        >
                            <Icons name={'close-icon'} />
                        </IconButton>
                    </Toolbar>
                </Collapse>
                <Collapse in={isExpanded} sx={{ width: '100%' }}>
                    <Toolbar>
                        <List sx={{ width: '100%' }}>
                            {authToken ? (
                                <ListItem
                                    key={`profile`}
                                    component={Link}
                                    to={'/profile'}
                                    sx={StyleMobProfile}
                                >
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <PersonIcon sx={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`My Account`}
                                        primaryTypographyProps={{ color: 'white' }}
                                    />
                                </ListItem>
                            ) : (
                                <ListItem
                                    key={`login`}
                                    component={Link}
                                    to={'#'}
                                    onClick={openLoginModal}
                                    sx={StyleMobProfile}
                                >
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <PersonIcon sx={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`Login/Signup`}
                                        primaryTypographyProps={{ color: 'white' }}
                                    />
                                </ListItem>
                            )}
                            {navItem.map(item => (
                                <ListItem
                                    key={item.path}
                                    component={Link}
                                    to={item.path || '#'}
                                    onClick={toggleMenu}
                                >
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <Icons name={item.icon} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.label}
                                        primaryTypographyProps={{ color: 'white' }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Toolbar>
                </Collapse>
            </NewAppBar>
            {cartDrawer.right && (
                <CartView cartDrawer={cartDrawer} toggleDrawer={toggleCartDrawer} />
            )}
            {openModal && (
                <LoginSignup open={openModal} setOpen={setOpenModal} setAuthToken={setAuthToken} />
            )}
        </HeaderContainer>
    );
};

export default Header;
