import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { appColor } from "../../common/appColor/appColor";

export const Container = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  background-color: ${appColor.white};
  border-radius: 50px;
  padding: 50px;
  margin-top: 160px;
  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 290px;
  }
`;

export const CardsLocation = styled(Box)`
  margin-top: -130px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: -260px;
  }
`;

export const TitleBox = styled(Box)`
  margin-top: 6.25rem;
  @media (max-width: 768px) {
    margin-top: 3.25rem;
  }
`;

export const Title = styled(Box)`
  color: var(--Black, #14141e);
  text-align: center;
  font-family: Cambon;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 1;
  position: relative;
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const HeightLightImage = styled(Box)`
  width: 100%;
  height: 1.56338rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  margin-top: -18px;
  margin-left: 35px;

  @media (max-width: 768px) {
    height: 0.9rem;
    margin-top: -13px;
    margin-left: 18px;
  }
`;

export const TitleBoxSpan = styled(Box)`
  color: var(--Black, #14141e);
  text-align: center;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: normal;
  }
`;

export const StoreCards = styled(Box)`
  margin-top: 3.75rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;
