import request from './request';

export function getRewards(params?: any) {
    return request({
        url: `rewards`,
        method: 'get',
        params,
        headers: localStorage.getItem('tempAccessToken')
            ? {
                  requiresTempAccessToken: true,
              }
            : { requiresTempAccessToken: false },
    });
}

export function redeemRequest(data?: any) {
    return request({
        url: `rewards/redeem/request`,
        method: 'post',
        data,
    });
}

export function redeemVerify(data?: any) {
    return request({
        url: `rewards/redeem/verify`,
        method: 'post',
        data,
    });
}

export const rewardsApi: any = {
    GET_REWARDS: async () => await getRewards(),
};
