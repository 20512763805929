import React from 'react';
import {
    HeaderContainer,
    HeightLightImage,
    MainContainer,
    ProductHeaderImage,
    Title,
} from '../view/bookService/serviceHeader/headerStyle';
import scribbleBottomSVG from '../assets/images/bookServiceHeightLight.svg';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import Image from 'mui-image';
import productHeader from '../assets/images/productHeader.png';
import useSticky from '../common/hooks/useSticky';
import { AppBar, Container, styled, useTheme } from '@mui/material';

type Props = {
    onSearch?: any;
    title: string;
    fontSize?: string;
    heroImage?: any;
    heroImageHeight?: string;
};

export const HeroWrapper = styled(Container)(({ theme }) => ({
    maxWidth: '1440px',
}));

export default function CustomHero({
    onSearch,
    title,
    fontSize,
    heroImage,
    heroImageHeight,
}: Props) {
    const { sticky, stickyRef } = useSticky();
    const isMobile = useCheckMobileScreen();
    const theme = useTheme();
    const customHeroImage = heroImage ? heroImage : scribbleBottomSVG;

    return (
        <MainContainer>
            {!isMobile && <Title sx={{ fontSize: fontSize }}>{title}</Title>}
            {!isMobile && (
                <HeaderContainer>
                    <HeightLightImage>
                        <Image
                            src={customHeroImage}
                            style={{ objectFit: 'contain' }}
                            duration={0}
                            alt="image"
                            height={heroImageHeight}
                        />
                    </HeightLightImage>
                </HeaderContainer>
            )}

            {/* {!isMobile && onSearch && (
                <AppBar
                    ref={stickyRef}
                    elevation={0}
                    // color={theme.palette.background.default}
                    color="inherit"
                    position={'relative'}
                    sx={{
                        justifyContent: 'center',
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <SearchBar onChange={onSearch} />
                </AppBar>
            )} */}
            <div
                style={
                    {
                        // @ts-ignore
                        // height: sticky
                        //     ? `${stickyRef?.current ? stickyRef?.current?.clientHeight : 0}px`
                        //     : '0px',
                    }
                }
            />
            {!isMobile && (
                <ProductHeaderImage sx={{ marginTop: '4rem' }}>
                    <Image
                        src={productHeader}
                        duration={500}
                        width={'13.75rem'}
                        fit="contain"
                        alt="image"
                    />
                </ProductHeaderImage>
            )}
        </MainContainer>
    );
}
