import { styled, css } from "@mui/material/styles";
import { Box } from "@mui/material";
import { appColor } from "../../common/appColor/appColor";
import { Modal as BaseModal } from "@mui/base/Modal";
import LoadingButton from "@mui/lab/LoadingButton";

export const MainContainer = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  background-color: ${appColor.white};
  border-radius: 50px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 1.25rem;
    justify-content: start;
    align-items: start;
  }
`;

export const Container = styled(Box)`
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const HeaderContainer = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  padding: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.25rem;
  }
`;

export const HeaderImage = styled(Box)`
  margin-left: 220px;
  margin-bottom: -54px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 0;
  }
`;

export const HeaderTextBox = styled(Box)`
  gap: 0;
  display: grid;
`;

export const TextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const InviteBanner = styled(Box)`
  color: var(--white, #fff);
  text-align: center;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
`;

export const InviteDec = styled(Box)`
  color: var(--white, #fff);
  font-family: Cambon;
  font-size: 3.375rem;
  font-style: italic;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const NameText = styled(Box)`
  color: var(--brown, #241f1f);
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonMainBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 1rem;
  @media (max-width: 768px) {
    margin-top: 0;
    flex-direction: column;
  }
`;

export const ButtonBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 50%;
  @media (max-width: 768px) {
    margin: 0.75rem 0;
    width: 100%;
  }
`;

export const ButtonText = styled(Box)`
  color: #231f20;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  letter-spacing: 0.0175rem;
  
`;

export const YesButton = styled(LoadingButton)`
  display: flex;
  padding: 0.6875rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  flex: 1 0 0;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  background: var(--yellow-pri, #fddc2e);
  border: 1px solid #e6e6e6;
  cursor: pointer;
`;

export const MayBeButton = styled(LoadingButton)`
  display: flex;
  padding: 0.6875rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  flex: 1 0 0;
  border-top: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  background: var(--yellow-pri, #eaa161);
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
`;

export const NoButton = styled(LoadingButton)`
  display: flex;
  padding: 0.6875rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  flex: 1 0 0;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  border-top: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  background: var(--yellow-pri, #2d2a2a);
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
`;

export const Nav = styled(Box)`
  width: 8.375rem;
  height: 0.3125rem;
  border-radius: 6.25rem;
  background: var(--Black, #16182f);
`;

export const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    background-color: #fff;

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      margin-bottom: 4px;
    }
  `
);
