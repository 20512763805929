import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {
    Button,
    CircularProgress,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { CURRENCY_SIGN } from '../../common/constants';
import CartProductList from '../../component/CartProductList';
import { selectCartIsLoading, selectCartItems } from '../../service/selectors/cartSelectors';
import { useEffect, useState } from 'react';
import { appActions } from '../../service/action';
import styled from '@emotion/styled';
import Icons from '../../component/icon';
import PointsIcon from '../../assets/images/points.svg';
import BannerIcon from '../../assets/images/BannerIcon.svg';
import {
    BannerContainer,
    SecondBannerContainer,
    DiscountPriceStyle,
    PointsStyle,
    RewardPointsContainer,
    SecondBannerContainerChildBox,
    SecondBannerContainerLabel,
    SecondDiscountLabel,
} from './CartStyle';
import { useMutation } from '@tanstack/react-query';
import { getCartCoupons } from '../../service/api/coupons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
type Anchor = 'top' | 'left' | 'bottom' | 'right';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
        display: 'inline-flex',
        padding: '6px 12px 6px 10px',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '12px',
        color: 'var(--Accent-White, #FFF)',
        textAlign: 'center',
        fontFamily: 'Euclid-Circular-A-Regular',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        cursor: 'pointer',
    },
}));

interface Coupon {
    discountValue: number;
    discountMetric: string;
    metaData: {
        minOrderValue: number;
        maxDiscountValue: number;
    };
    expirationDate: string;
    normalizedDiscountValue: number;
}

const CartView = ({ cartDrawer }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobile = useCheckMobileScreen();

    const cartItems = useSelector(selectCartItems);
    const cartLoading = useSelector(selectCartIsLoading);

    const [coupon, setCoupons] = useState<Coupon>({
        discountValue: 0,
        discountMetric: '',
        metaData: {
            minOrderValue: 0,
            maxDiscountValue: 0,
        },
        expirationDate: '',
        normalizedDiscountValue: 0,
    });

    const toggleCartDrawer = (anchor: any, open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        dispatch({
            type: `${appActions.SET_CART_DRAWER}_SUCCESS`,
            payload: { ...cartDrawer, [anchor]: open },
        });
    };

    const redirectToOrder = () => {
        toggleCartDrawer('right', false)({});
        navigate('/order');
    };
    const [isAnyProductSoldOut, setIsAnyProductSoldOut] = useState(false);

    /* check sold out products */
    useEffect(() => {
        const isSoldOut = cartItems?.items?.some((product: any) => !product.isAvailable);

        setIsAnyProductSoldOut(isSoldOut);
    }, [cartItems]);

    const totalPoints = Math.floor(cartItems?.earnableRewardPoints || 0);

    const cartCoupons = useMutation({
        mutationFn: async (data: any) => await getCartCoupons(data),
        onSuccess: (res: any) => {
            const data = res?.data?.coupons ? res?.data?.coupons[0] : null;

            if (res?.data?.coupons) {
                setCoupons(data);
            }
        },
    });

    useEffect(() => {
        cartCoupons.mutate({
            page: 1,
            limit: 1,
        });
    }, [cartDrawer, cartItems]);

    const formatDiscountAmt = () => {
        const discoountAmt = Number(coupon?.normalizedDiscountValue);

        if (discoountAmt % 1 > 0) {
            return discoountAmt.toFixed(2);
        }
        return discoountAmt;
    };

    const calculateAddMoreAmount = () => {
        const minOrderValue = coupon?.metaData?.minOrderValue || coupon?.normalizedDiscountValue;

        const _tempAmount = Number(minOrderValue - cartItems?.subtotal);
        if (_tempAmount % 1 > 0) {
            return _tempAmount.toFixed(2);
        }
        return _tempAmount;
    };
    const list = (anchor: Anchor) => (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
            role="presentation"
        >
            <Box
                sx={{
                    borderBottom: '1px solid #ddd',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: isMobile ? '10px 16px' : '10px 30px',
                        alignSelf: 'stretch',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Cambon-Semi-Bold',
                            fontSize: '1.5rem',
                            fontWeight: '500',
                            lineHeight: '36px',
                            textAlign: 'left',
                            color: 'var(--Black, #14141E)',
                        }}
                    >
                        Cart ({cartItems ? cartItems?.items?.length : 0})
                    </Typography>
                    <CloseIcon
                        onClick={toggleCartDrawer('right', false)}
                        sx={{ cursor: 'pointer' }}
                    />
                </Box>
                {totalPoints > 0 && cartItems?.items?.length > 0 && (
                    <RewardPointsContainer>
                        <img src={PointsIcon} alt="" />
                        <Typography
                            variant="body1"
                            sx={{ fontSize: '15px', justifyContent: 'center' }}
                        >
                            Earn <PointsStyle>{totalPoints.toLocaleString('en-IN')}</PointsStyle> SN
                            points with this purchase{' '}
                        </Typography>
                        <Link
                            to="#"
                            onClick={() => window.open('/rewards', '_blank')}
                            style={{ height: '1rem' }}
                        >
                            <BootstrapTooltip title={'Learn more'}>
                                <InfoOutlinedIcon
                                    sx={{
                                        fontSize: '1.25rem',
                                        color: '#0A69D9',
                                    }}
                                />
                            </BootstrapTooltip>
                        </Link>
                    </RewardPointsContainer>
                )}
            </Box>

            {cartItems && cartItems?.items?.length > 0 ? (
                <>
                    <CartProductList
                        height={
                            coupon
                                ? coupon?.metaData?.minOrderValue > cartItems?.subtotal
                                    ? 'calc(100% - 315px)'
                                    : 'calc(100% - 290px)'
                                : 'calc(100% - 230px)'
                        }
                        toggleDrawer={toggleCartDrawer('right', false)}
                        padding={isMobile ? '0 15px' : ''}
                    />

                    {coupon &&
                    (!coupon?.metaData?.minOrderValue ||
                        cartItems?.subtotal >= coupon?.metaData?.minOrderValue) &&
                    Number(formatDiscountAmt()) > 0 ? (
                        <BannerContainer>
                            <img src={BannerIcon} alt="" />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.25rem',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#FFF',
                                        fontFamily: 'Euclid-Circular-A-Regular',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '16px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {`Wohoo! Save ${
                                        CURRENCY_SIGN + formatDiscountAmt()
                                    } on your order`}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        overflow: 'hidden',
                                        color: 'rgba(255, 255, 255, 0.80)',
                                        textOverflow: 'ellipsis',
                                        fontFamily: 'Euclid-Circular-A-Regular',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '16px',
                                    }}
                                >
                                    Apply coupon at next step
                                </Typography>
                            </Box>
                        </BannerContainer>
                    ) : (
                        coupon &&
                        coupon?.normalizedDiscountValue > 0 &&
                        Number(calculateAddMoreAmount()) > 0 && (
                            <SecondBannerContainer sx={{ gap: '0.5rem', flexDirection: 'column' }}>
                                <Box sx={SecondBannerContainerChildBox}>
                                    <Icons name={'discount-coupon'} />
                                    <Box>
                                        <Typography variant="body1" sx={SecondBannerContainerLabel}>
                                            Unlock amazing savings!
                                        </Typography>
                                    </Box>
                                </Box>
                                <SecondDiscountLabel>
                                    Add{' '}
                                    <PointsStyle style={{ color: '#0A69D9' }}>
                                        {CURRENCY_SIGN + calculateAddMoreAmount()}
                                    </PointsStyle>{' '}
                                    more SAVE upto{' '}
                                    <DiscountPriceStyle>
                                        {CURRENCY_SIGN + formatDiscountAmt()}
                                    </DiscountPriceStyle>{' '}
                                </SecondDiscountLabel>
                            </SecondBannerContainer>
                        )
                    )}

                    <Box
                        sx={{
                            padding: isMobile ? '16px' : '16px 30px 20px 30px',
                            borderTop: '1px solid #ddd',
                            width: '100%',
                            background: '#FFFFFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '0.625rem',
                            alignSelf: 'stretch',
                            boxShadow: '0px -2px 33px 0px rgba(0, 0, 0, 0.00)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                gap: isMobile ? '18px' : '0.25rem',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: isMobile ? '8px' : '10px',
                                    flex: '1 0 0',
                                }}
                            >
                                <Typography
                                    sx={{
                                        overflow: 'hidden',
                                        color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                        fontSize: isMobile ? '1rem' : '1.125rem',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '16px',
                                        height: '16px',
                                        alignSelf: 'stretch',
                                        display: 'flex',
                                        gap: '3px',
                                    }}
                                >
                                    Subtotal
                                    <span
                                        style={{
                                            fontSize: '0.75rem',
                                            fontWeight: '400',
                                            fontFamily: 'Euclid-Circular-A-Regular',
                                        }}
                                    >
                                        (inclusive of all taxes)
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        overflow: 'hidden',
                                        color: '#7B7979',
                                        textOverflow: 'ellipsis',
                                        fontSize: '0.75rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '16px',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    Final will be calculated at checkout
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: '1',
                                    overflow: 'hidden',
                                    color: `${
                                        cartLoading
                                            ? `gray`
                                            : `var(--Dark-Brown-Dark-Brown---100, #231F20)`
                                    }`,
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                    fontSize: '1.25rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                }}
                            >
                                {CURRENCY_SIGN}
                                {cartItems?.subtotal?.toFixed(2) || 0}
                            </Typography>
                        </Box>

                        <Button
                            variant="contained"
                            fullWidth
                            size="large"
                            disabled={cartLoading || isAnyProductSoldOut}
                            color="primary"
                            onClick={() => {
                                redirectToOrder();
                            }}
                            sx={{
                                background: '#FFD600 !important',
                                boxShadow: 'none !important',
                            }}
                        >
                            {cartLoading ? (
                                <>
                                    <CircularProgress size={15} sx={{ mr: 1 }} color="inherit" />
                                    Processing
                                </>
                            ) : (
                                'Checkout'
                            )}
                        </Button>
                    </Box>
                </>
            ) : (
                <Typography
                    sx={{
                        fontSize: '16px',
                        lineHeight: '36px',
                        padding: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    Your cart is empty.
                </Typography>
            )}
        </Box>
    );

    return (
        <Drawer
            anchor="right"
            open={cartDrawer.right}
            onClose={toggleCartDrawer('right', false)}
            sx={{
                '.MuiDrawer-paper': {
                    width: isMobile ? '90%' : '390px',
                },
            }}
        >
            {list('right')}
        </Drawer>
    );
};

export default CartView;
