import { useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Skeleton,
} from '@mui/material';
import { Logout } from '@mui/icons-material';
import PointsIcon from '../../assets/images/points.svg';
import Icons from '../../component/icon';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../service/action';
import { userSelector } from '../../service/selectors/userSelector';
import { getRewardsAction } from '../../service/action/userActions';
import { totalPointsSelector, rewardsSelector } from '../../service/selectors/userSelector';
import { IconStyle, linkStyle, PhoneNumber, SNText } from './ProfilePreviewStyle';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

const ProfilePreview = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector(userSelector);
    const totalPoints = useSelector(totalPointsSelector);
    const rewards = useSelector(rewardsSelector);
    const isMobile = useCheckMobileScreen();

    const isSelected = (path: any, path2?: any) => {
        return location.pathname.includes(path) || location.pathname.includes(path2);
    };

    const redirect = (url: any) => {
        navigate(url);
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        dispatch({
            type: `${appActions.LOGOUT}_SUCCESS`,
        });
        navigate('/products');
    };

    function formatPhoneNumber() {
        if (!userData?.mobile?.startsWith('+91')) {
            return '';
        }

        // Extract the country code and the main number
        const countryCode = userData?.mobile.slice(0, 3);
        const mainNumber = userData?.mobile.slice(3);

        return countryCode + ' ' + mainNumber;
    }

    function formatDate() {
        const date = userData?.createdAt ? new Date(userData.createdAt) : new Date();

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        const daySuffix = (day: any) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        };

        return (
            <Typography variant="body2" color="textSecondary">
                Since {day}
                <sup>{daySuffix(day)}</sup> {month} {year}
            </Typography>
        );
    }

    useEffect(() => {
        /* recall api to get updated rewards and points */
        if (rewards.length === 0 || location.pathname !== '/profile/rewards') {
            dispatch(getRewardsAction({}));
        }
    }, []);

    return (
        <Box
            sx={{
                width: isMobile ? '100%' : 280,
                borderRadius: 8,
                bgcolor: 'white',
                display: 'flex',
                flexDirection: 'column',
                minWidth: isMobile ? '100%' : '280px',
                maxWidth: isMobile ? '100%' : '280px',
            }}
        >
            {!userData?.mobile ? (
                <>
                    <Skeleton
                        height="auto"
                        width=""
                        sx={{
                            borderRadius: '26px',
                            minHeight: '300px',
                            transformOrigin: '0 0%',
                            marginBottom: '-100px',
                        }}
                    />
                    {[...Array(5)].map((_, index) => (
                        <Skeleton
                            height="auto"
                            width=""
                            sx={{
                                borderRadius: '8px',
                                minHeight: '5rem',
                                transformOrigin: '0 -10%',
                                marginBottom: '-25px',
                            }}
                        />
                    ))}
                </>
            ) : (
                <>
                    <Box
                        sx={{
                            background: '#000',
                            height: 'auto',
                            borderRadius: '28px 28px 20px 20px',
                            border: 'var(--Cart, 1px) solid #FFF',
                        }}
                        mb={1}
                    >
                        <Box
                            pb={2}
                            sx={{
                                textAlign: 'center',
                                mb: '10px',
                                border: 'var(--Cart, 1px) solid rgba(204, 204, 204, 0.50)',
                                background: '#F8F8F8',
                                borderRadius: '24px',
                            }}
                        >
                            <Box sx={{ mb: 1, pt: 2 }}>
                                <Icons name="profile" />
                            </Box>
                            <Typography sx={PhoneNumber}>{formatPhoneNumber()}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {formatDate()}
                            </Typography>
                        </Box>
                        <Box
                            position={'relative'}
                            display={'flex'}
                            justifyContent={'space-between'}
                            sx={{
                                color: 'rgba(255, 255, 255, 0.90)',
                                fontWeight: '400',
                                fontSize: '14px',
                            }}
                            ml={2}
                            mr={2}
                            pb={1}
                        >
                            <Typography sx={SNText}>SN POINTS</Typography>
                            <Typography
                                variant="body2"
                                sx={{ ...SNText, display: 'flex', gap: '4px' }}
                            >
                                <img src={PointsIcon} alt="" />
                                {totalPoints.toLocaleString('en-IN')}
                            </Typography>
                        </Box>
                    </Box>
                    <List sx={{ paddingBottom: '0px' }} onClick={e => redirect('/profile/rewards')}>
                        <ListItem
                            button
                            sx={{
                                '&:hover': linkStyle,
                                backgroundColor: isSelected('/profile/rewards')
                                    ? linkStyle
                                    : 'inherit',
                                gap: '1rem',
                            }}
                        >
                            <ListItemIcon sx={IconStyle}>
                                <Icons name="rewardPoints" />
                            </ListItemIcon>
                            <ListItemText primary="Rewards" />
                        </ListItem>
                    </List>
                    <List sx={{ paddingBottom: '0px' }} onClick={e => redirect('/profile/coupons')}>
                        <ListItem
                            button
                            sx={{
                                '&:hover': linkStyle,
                                backgroundColor: isSelected('/profile/coupons')
                                    ? linkStyle
                                    : 'inherit',
                                gap: '1rem',
                            }}
                        >
                            <ListItemIcon sx={IconStyle}>
                                <Icons name="coupons" />
                            </ListItemIcon>
                            <ListItemText primary="My Coupons" />
                        </ListItem>
                    </List>
                    <List onClick={e => redirect('/profile/orders')}>
                        <ListItem
                            button
                            sx={{
                                '&:hover': linkStyle,
                                backgroundColor: isSelected('/profile/orders', '/profile/order')
                                    ? linkStyle
                                    : 'inherit',
                                gap: '1rem',
                            }}
                        >
                            <ListItemIcon sx={IconStyle}>
                                <Icons name="myOrders" />
                            </ListItemIcon>
                            <ListItemText primary="My Orders" />
                        </ListItem>
                    </List>

                    <List
                        sx={{ color: '#ff475D', paddingBottom: 0, borderTop: '1px dashed #D9D9D9' }}
                        onClick={logout}
                    >
                        <ListItem
                            button
                            sx={{
                                '&:hover': linkStyle,
                                gap: '1rem',
                            }}
                        >
                            <ListItemIcon sx={IconStyle}>
                                <Logout sx={{ color: '#ff475D' }} />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </>
            )}
        </Box>
    );
};

export default ProfilePreview;
