import { appActions } from '../../action';

const initialState: any = {
    cart: [],
    cartDrawer: { right: false },
    isLoading: false,
    errorMessage: '',
};
const cartReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${appActions.ADD_ITEM_TO_CART}_START`:
            return { ...state, isLoading: true };
        case `${appActions.ADD_ITEM_TO_CART}_SUCCESS`:
            return { ...state, cart: action.payload, isLoading: false };
        case `${appActions.ADD_ITEM_TO_CART}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.GET_CART}_START`:
            return { ...state, isLoading: true };
        case `${appActions.GET_CART}_SUCCESS`:
            return { ...state, cart: action.payload, isLoading: false };
        case `${appActions.GET_CART}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.DELETE_CART_ITEM}_START`:
            return { ...state, isLoading: true };
        case `${appActions.DELETE_CART_ITEM}_SUCCESS`:
            return { ...state, cart: action.payload, isLoading: false };
        case `${appActions.DELETE_CART_ITEM}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.UPDATE_CART_ITEM}_START`:
            return { ...state, isLoading: true };
        case `${appActions.UPDATE_CART_ITEM}_SUCCESS`:
            return { ...state, cart: action.payload, isLoading: false };
        case `${appActions.UPDATE_CART_ITEM}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.APPLY_COUPON}_START`:
            return { ...state, isLoading: true, errorMessage: null };
        case `${appActions.APPLY_COUPON}_SUCCESS`:
            return { ...state, cart: action.payload, isLoading: false };
        case `${appActions.APPLY_COUPON}_FAIL`:
            return { ...state, isLoading: false, errorMessage: action.message };

        case `${appActions.REMOVE_COUPON}_START`:
            return { ...state, isLoading: true };
        case `${appActions.REMOVE_COUPON}_SUCCESS`:
            return { ...state, cart: action.payload, isLoading: false };
        case `${appActions.REMOVE_COUPON}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.SET_CART_DRAWER}_START`:
            return { ...state, cartDrawer: { right: false } };
        case `${appActions.SET_CART_DRAWER}_SUCCESS`:
            return { ...state, cartDrawer: action.payload };
        case `${appActions.SET_CART_DRAWER}_FAIL`:
            return { ...state, cartDrawer: { right: false } };

        case `${appActions.CLEAR_ERROR_MESSAGE}`:
            return { ...state, errorMessage: null };

        default:
            return state;
    }
};

export default cartReducer;
