import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Chip, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import { AddMore, ExpiredLabelStyle } from '../coupon/CouponStyle';
import parse from 'html-react-parser';
import Icon from '../../component/icon';
import { checkCouponExpired } from '../../common/utils/couponUtils';
import { useSelector } from 'react-redux';
import { selectCartIsLoading, selectCartItems } from '../../service/selectors/cartSelectors';
import { CURRENCY_SIGN } from '../../common/constants';

type props = {
    handleClose: any;
    coupon: any;
    isApplied: any;
    applyCoupon: any;
    removeCoupon: any;
    isFirstItem?: any;
    isStyle?: any;
};

export default function CouponCard({
    handleClose,
    coupon,
    applyCoupon,
    isFirstItem,
    isApplied,
    removeCoupon,
    isStyle,
}: props) {
    const description = parse(coupon?.description || '');
    const isCartLoading = useSelector(selectCartIsLoading);
    const cartItems = useSelector(selectCartItems);
    const [currSelectedId, setcurrSelectedId] = useState('');

    const totalAmount = cartItems.total || 0;

    useEffect(() => {
        /* clear state after action completed */
        if (isCartLoading == false && currSelectedId) {
            setcurrSelectedId('');
        }
    }, [isCartLoading]);

    const closeModal = () => {
        setTimeout(() => {
            handleClose();
        }, 500);
    };
    const applyCouponAction = (couponId: any) => {
        applyCoupon(coupon?.id);
        setcurrSelectedId(coupon?.id);
        closeModal();
    };
    const removeCouponAction = (couponId: any) => {
        removeCoupon();
        setcurrSelectedId(couponId);
        closeModal();
    };

    const formatDiscountAmt = (amount: any) => {
        const discoountAmt = Number(amount || 0);

        if (discoountAmt % 1 > 0) {
            return discoountAmt.toFixed(2);
        }

        return discoountAmt;
    };

    return (
        <Grid
            sx={{
                borderTop: isFirstItem ? '' : '1px solid rgba(0, 0, 0, 0.14)',
                padding: '1rem',
                border: isStyle ? '1px solid rgba(0, 0, 0, 0.14)' : '',
                borderRadius: isStyle ? '18px' : '',
            }}
        >
            <Box>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    alignSelf="stretch"
                    gap={'8px'}
                >
                    <Stack direction="row" alignItems="center">
                        <Chip
                            sx={{
                                background: '#ffddaf',
                                borderRadius: '4px',
                                fontSize: '14.51px',
                                fontWeight: '500',
                                gap: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '5px',
                            }}
                            icon={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '20px',
                                        color: '#ff5733',
                                        width: '10px',
                                        height: '9px',
                                    }}
                                >
                                    <Icon name="logo" />
                                </Box>
                            }
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Black, #14141E)',
                                        fontFamily: 'Euclid-Circular-A-Medium',
                                        fontSize: '14.51px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: 'normal',
                                        paddingLeft: '7px',
                                        borderLeft: '1px dashed #C8B4A9',
                                    }}
                                >
                                    {coupon?.code || ''}
                                </Typography>
                            }
                            size="small"
                        />
                    </Stack>
                    {isApplied ? (
                        <Button
                            variant="contained"
                            disableElevation
                            color="error"
                            sx={{
                                color: '#FF4E16',
                                background: 'rgba(255, 78, 22, 0.10)',
                                '&:hover': {
                                    color: '#FFF',
                                },
                            }}
                            disabled={isCartLoading && coupon?.id == currSelectedId}
                            onClick={e => removeCouponAction(coupon?.id)}
                        >
                            {isCartLoading && coupon?.id == currSelectedId ? (
                                <>
                                    <CircularProgress size={15} sx={{ mr: 1 }} color="inherit" />
                                    Removing
                                </>
                            ) : (
                                'Remove'
                            )}
                        </Button>
                    ) : checkCouponExpired({ expirationDate: coupon?.expirationDate }) ? (
                        <Typography sx={ExpiredLabelStyle}>Expired</Typography>
                    ) : (
                        totalAmount >= coupon.metaData.minOrderValue && (
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                disabled={
                                    checkCouponExpired({
                                        expirationDate: coupon?.expirationDate,
                                    }) ||
                                    (isCartLoading && coupon?.id == currSelectedId)
                                }
                                sx={{ padding: '4px 12px' }}
                                onClick={e => applyCouponAction(coupon?.id)}
                            >
                                {isCartLoading && coupon?.id == currSelectedId ? (
                                    <>
                                        <CircularProgress
                                            size={15}
                                            sx={{ mr: 1 }}
                                            color="inherit"
                                        />
                                        Applying
                                    </>
                                ) : (
                                    'Apply'
                                )}
                            </Button>
                        )
                    )}
                </Box>
                <Typography variant="h6" fontWeight="600" fontSize={'18px'}>
                    {coupon?.title || ''}
                </Typography>
                <Typography fontWeight="500">
                    {coupon.metaData.maxDiscountValue > 0 &&
                        'Maximum discount: ' +
                            CURRENCY_SIGN +
                            formatDiscountAmt(coupon?.metaData?.maxDiscountValue)}
                </Typography>
                {totalAmount < coupon.metaData.minOrderValue && (
                    <Typography sx={AddMore}>
                        Add{' '}
                        {CURRENCY_SIGN +
                            formatDiscountAmt(coupon?.metaData?.minOrderValue - totalAmount)}{' '}
                        more to avail this offer
                    </Typography>
                )}
                <Divider
                    sx={{
                        width: '100%',
                        borderStyle: 'dashed',
                        marginY: 1,
                    }}
                />
                <Typography variant="body2" color="#747474">
                    {description || ''}
                </Typography>
            </Box>
        </Grid>
    );
}
