import Image from '../../component/image/image';
import {
    Cards,
    CardButtonBox,
    CardText,
    CardTextBox,
    CardTitle,
    Container,
    CardButtonText,
    NavBorder,
    NavCard,
    MainContainer,
    TextConatainer,
} from './storeInfoStyle';
import Arrow from '../../assets/images/arrow.svg';
import { Link, useLocation } from 'react-router-dom';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

interface CardInfo {
    id: number;
    name: string;
    address: string;
    image: string;
    to: string;
    phone: string;
    phoneLink: string;
}

const storeInfo: CardInfo[] = [
    {
        id: 1,
        name: 'Hadapsar, Store',
        address:
            'Shop 1, Mauli Bunglow, Survey 201, DP Rd, near Siddheshwar Foods, Chandan Nagar, Malwadi, Hadapsar, Pune, Maharashtra 411028',
        image: require('../../assets/images/Hadapsar_store.png'),
        to: 'https://maps.app.goo.gl/ayvrsjeh8JszdJFR8',
        phone: '+91 93599 - 39912',
        phoneLink: 'tel:9359939912',
    },
    {
        id: 2,
        name: 'Undri, Store',
        address:
            'Gate 4, Ground Floor Meera Crystal Survey No,13/14 15/19 NIBM Annex, next to Hills and Dales, Undri, Pune, Maharashtra 411060',
        image: require('../../assets/images/Undri_store.png'),
        to: 'https://maps.app.goo.gl/RagwdpMSWM4Tj7Kg7',
        phone: '+91 91567 - 19769',
        phoneLink: 'tel:9156719769',
    },
];

const StoreInfo = ({ boxShadow, gap, borderRadius, background }: any) => {
    const location = useLocation();
    const currentRoute = location.pathname;
    const hasDivider = currentRoute === '/support';
    const isMobile = useCheckMobileScreen();

    return (
        <MainContainer>
            <Container gap={gap}>
                {storeInfo.map(store => (
                    <>
                        <Cards
                            key={store.id}
                            boxShadow={boxShadow}
                            borderRadius={borderRadius}
                            background={background}
                        >
                            <Image src={store.image} alt={`store ${store.id}`} />
                            <TextConatainer>
                                <CardTextBox>
                                    <CardTitle>{store.name}</CardTitle>
                                    <CardText>
                                        {isMobile && store.address.length > 50
                                            ? `${store.address.substring(0, 50)}...`
                                            : store.address}
                                    </CardText>
                                </CardTextBox>
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    to={store.phoneLink}
                                    target="_blank"
                                >
                                    <CardText>{store.phone}</CardText>
                                </Link>
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    to={store.to}
                                    target="_blank"
                                >
                                    <CardButtonBox>
                                        <CardButtonText>Get Direction</CardButtonText>
                                        <Image src={Arrow} alt="image" />
                                    </CardButtonBox>
                                </Link>
                            </TextConatainer>
                        </Cards>
                        {store.id === 1 && hasDivider && (
                            <NavCard>
                                <NavBorder />
                            </NavCard>
                        )}
                    </>
                ))}
            </Container>
        </MainContainer>
    );
};

export default StoreInfo;
