import { useEffect, useRef, useState } from 'react';
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Container,
    Fade,
    Grid,
    LinearProgress,
    Snackbar,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import {
    selectCartItems,
    selectCartIsLoading,
    selectErrorMessage,
} from '../../service/selectors/cartSelectors';
import Login from '../auth/Login';
import { getSignInUpCode } from '../../service/api/auth';
import { createOrder, getPickupAddresses } from '../../service/api/order';
import { createUserAddress } from '../../service/api/user';
import { appActions } from '../../service/action';
import CouponsModal from '../coupon/CouponsModal';
import { redeemRequest } from '../../service/api/rewards';
import { getCartCoupons } from '../../service/api/coupons';
import { getRewardsAction } from '../../service/action/userActions';
import {
    rewardsSelector,
    totalPointsSelector,
    userSelector,
} from '../../service/selectors/userSelector';
import { applyCouponAction, removeCouponAction } from '../../service/action/cart/cartActions';
import RedeemSuccessModal from '../coupon/RedeemSuccessModal';
import RedeemRewardModal from '../rewards/RedeemRewardModal';
import { Field, Form, Formik } from 'formik';
import {
    address,
    Coupon,
    initialAvailableAddressDataFormat,
    initialOrderData,
    initialValues,
    validationSchemaStep1,
    validationSchemaStep2,
} from './utils';
import { Heading, NoteBox, NotesStyle, StepTextStyle } from './OrderStyles';
// import CustomerDetails from '../../component/CustomerDetails';
import AddressDetails from '../../component/AddressDetails';
import ProductList from './ProductList';
import RewardPoints from './CheckoutRewardPoints';
import CouponSection from './CouponSection';
import BillDetails from './BillDetails';
import PaymentMethod from './PaymentMethod';

export default function Checkout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isMobile = useCheckMobileScreen();

    const phoneNumberRef = useRef<HTMLInputElement | null>(null);

    const [addressAndPaymentData, setAddressAndPaymentData] = useState({
        paymentMethod: 'POD',
        selectedAddressId: '',
        deliveryAtMyPlace: true,
    });
    const [preAuthData, setPreAuthData] = useState({
        phone: '',
        deviceId: '',
        preAuthSessionId: '',
    });

    const rewards = useSelector(rewardsSelector);
    const userData = useSelector(userSelector);
    const cartItems = useSelector(selectCartItems);
    const isCartLoading = useSelector(selectCartIsLoading);
    const totalRedeemPoints = useSelector(totalPointsSelector);

    const [currStep, setCurrStep] = useState(1);
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [generatedCoupon, setGeneratedCoupon] = useState({
        id: '',
        code: '',
        expirationDate: '',
    });
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openCouponModal, setOpenCouponModal] = useState(false);
    const [redeemRewardModal, setRedeemRewardModal] = useState(false);
    const [redeemSuccessModal, setRedeemSuccessModal] = useState(false);
    const [isAnyProductSoldOut, setIsAnyProductSoldOut] = useState(false);
    const [formData, setFormData]: any = useState(initialOrderData);
    const [availableAddresses, setAvailableAddresses] = useState<address[]>(
        initialAvailableAddressDataFormat
    );

    const handleCloseCouponModal = () => setOpenCouponModal(false);
    const handleLoginModalAction = () => setOpenLoginModal(!openLoginModal);
    const handleRedeemRewardModalAction = () => setRedeemRewardModal(!redeemRewardModal);
    const handleRedeemCouponModalAction = () => setRedeemSuccessModal(!redeemSuccessModal);
    const totalPoints = useSelector(totalPointsSelector);
    const applyCouponFailed = useSelector(selectErrorMessage);

    useEffect(() => {
        if (generatedCoupon.code && generatedCoupon.id) {
            getcouponsMut.mutate({
                page: 1,
                limit: 100,
            });

            applyCoupon(generatedCoupon?.id);
            handleRedeemCouponModalAction();
        }
    }, [generatedCoupon]);

    useEffect(() => {
        if (location?.state) {
            phoneNumberRef?.current?.focus();
            window.history.replaceState({}, '');
        }
    }, [location]);

    useEffect(() => {
        if (cartItems?.items?.length == 0) {
            navigate('/products');
        }
        // added if condition twice because some time gives undefined
        if (cartItems?.items?.length && isCartLoading == false) {
            getcouponsMut.mutate({
                page: 1,
                limit: 100,
            });
        }

        const isSoldOut = cartItems?.items?.some((product: any) => !product.isAvailable);

        setIsAnyProductSoldOut(isSoldOut);
    }, [cartItems]);

    useEffect(() => {
        if (availableAddresses.length > 0) {
            setAddressAndPaymentData((pre: any) => {
                return { ...pre, selectedAddressId: availableAddresses[0].id };
            });
        }
    }, [availableAddresses]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            /* recall api to get updated rewards and points */
            dispatch(getRewardsAction({}));
        }

        /* get available addresses */
        orderAddressMut.mutate({});
    }, []);

    useEffect(() => {
        if (applyCouponFailed) {
            setRedeemSuccessModal(!redeemSuccessModal);
        }
    }, [applyCouponFailed]);

    const createOrderMut = useMutation({
        mutationFn: (data: any) => createOrder(data),
        onSuccess: (res: any) => {
            setOpenLoginModal(false);
            navigate(`/order/confirmation/${res.data.id}`);
            /* clear cart data */
            dispatch({
                type: `${appActions.DELETE_CART_ITEM}_SUCCESS`,
                payload: {},
            });
        },
        onError: (error: any) => {
            console.log('ERROR WHILE GENERATE ORDER', error);
        },
    });

    const orderAddressMut = useMutation({
        mutationFn: (data: any) => getPickupAddresses(),
        onSuccess: (res: any) => {
            setAvailableAddresses(res.data);
        },
        onError: (error: any) => {
            console.log('ERROR WHILE GET ORDER ADDRESSES', error);
        },
    });

    const createAddressMut = useMutation({
        mutationFn: (data: any) => createUserAddress(data),
        onSuccess: (res: any) => {
            const addressId = res?.data?.id;

            createOrderMut.mutate({
                metaData: {
                    notes: formData?.notes,
                },
                name: formData?.name,
                phone: formData?.phone,
                addressId: addressId,
                paymentMode: addressAndPaymentData.paymentMethod,
                cartSessionId: cartItems ? cartItems.id : '',
            });
        },
        onError: () => {
            console.log('ADDRESS CREATION FAILED');
        },
    });

    const sendOTPFormMut = useMutation({
        mutationFn: (data: any) => getSignInUpCode(data),
        onSuccess: (res: any) => {
            res = res.data;
            if (res.status === 'OK') {
                setPreAuthData(pre => {
                    return {
                        ...pre,
                        deviceId: res.deviceId,
                        preAuthSessionId: res.preAuthSessionId,
                    };
                });
                setOpenLoginModal(true);
            } else {
                throw new Error('Failed to send OTP');
            }
        },
    });

    const sendOTP = ({ phoneNumber }: any) => {
        phoneNumber = `+91${phoneNumber.replace(/^0+/, '')}`;
        const result = sendOTPFormMut.mutate({ phoneNumber });
        return result;
    };

    const sendRedeemRequestMut = useMutation({
        mutationFn: (data: any) => redeemRequest(data),
        onSuccess: (res: any) => {},
    });

    const redeemRequestMut = () => {
        const result = sendRedeemRequestMut.mutate({});
        return result;
    };

    const getcouponsMut = useMutation({
        mutationFn: (data: any) =>
            getCartCoupons({
                page: 1,
                limit: 100,
            }),
        onSuccess: (res: any) => {
            setCoupons(res?.data?.coupons || []);
        },
        onError: (error: any) => {
            console.log('ERROR WHILE GET COUPONS', error);
        },
    });

    const generateOrder = (obj: any) => {
        if (obj.address.id) {
            createOrderMut.mutate({
                metaData: {
                    notes: obj?.notes,
                },
                name: obj?.name,
                phone: obj?.phone.replace(/^0+/, ''),
                addressId: obj.address.id,
                paymentMode: addressAndPaymentData.paymentMethod,
                cartSessionId: cartItems ? cartItems.id : '',
            });
        } else {
            createAddressMut.mutate({
                address1: obj?.address.address_line_one,
                address2: obj?.address.address_line_two,
                name: `${obj?.user.firstName} ${obj?.user.lastName}`,
                phone: obj?.user?.phoneNumber.replace(/^0+/, ''),
                city: obj?.address?.city,
                state: obj?.address?.state,
                pinCode: obj?.address?.pincode,
                country: obj?.address?.country,
                metaData: {
                    latitude: 0,
                    longitude: 0,
                },
                email: obj?.user?.email,
            });
        }
    };

    const applyCoupon = (couponId: any) => {
        dispatch(applyCouponAction({ data: { couponId } }));
    };

    const removeCoupon = () => {
        dispatch(removeCouponAction({}));
    };

    const submitOrder = async (values: any) => {
        if (values.is_delivery) {
            values.address.id = '';
        } else {
            values.address.id = addressAndPaymentData.selectedAddressId;
            values.address.address_line_one = '';
            values.address.address_line_two = '';
            values.address.city = '';
            values.address.state = '';
            values.address.pincode = '';
        }

        const addressParts = [
            values.address.address_line_one,
            values.address.address_line_two,
            values.address.city,
            values.address.state,
            values.address.pincode,
        ];
        const obj = {
            user: {
                firstName: values.user.firstName,
                lastName: values.user.lastName,
                phoneNumber: `+91${values.user.phoneNumber.replace(/^0+/, '')}`,
                email: values.user.email ? values.user.email : null,
            },
            products: cartItems?.items,
            address: {
                ...values.address,
                completeAddress: addressParts.filter(Boolean).join(', '),
            },
            is_delivery: values.is_delivery,
            is_pickup: values.is_pickup,
            pickup_location: values.pickup_location,
            notes: values.notes,
            name:
                values.user.firstName.charAt(0).toUpperCase() +
                values.user.firstName.slice(1) +
                ' ' +
                (values.user.lastName.charAt(0).toUpperCase() + values.user.lastName.slice(1)),
            phone: values.user.phoneNumber,
        };

        setFormData(obj);

        const phoneNumber = values.user.phoneNumber;

        if (!localStorage.getItem('accessToken')) {
            /* remove temp accessToken if available */
            localStorage.removeItem('tempAccessToken');

            const result = sendOTP({ phoneNumber });
            setPreAuthData(pre => {
                return { ...pre, phone: phoneNumber };
            });

            return result;
        } else {
            generateOrder(obj);
        }
    };

    let firstReward: any;
    if (rewards.length > 0) {
        // Filter the rewards array to only include items with points_value less than totalPoints
        const filteredRewards = rewards.filter((reward: any) => {
            if (
                reward.points_value <= totalRedeemPoints &&
                !reward.name.includes('service') &&
                reward.points_value <= cartItems.total
            ) {
                return reward;
            }
        });

        const sortedAndFilteredRewards = filteredRewards.sort(
            (a: any, b: any) => b.points_value - a.points_value
        );

        // Access the first object of the sorted and filtered array
        firstReward = sortedAndFilteredRewards[0];
    }

    return (
        <Container
            maxWidth="md"
            sx={{ pt: isMobile ? '1.5rem' : '3rem', width: isMobile ? '100%' : '50%' }}
        >
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={
                        addressAndPaymentData.deliveryAtMyPlace
                            ? validationSchemaStep2
                            : validationSchemaStep1
                    }
                    onSubmit={submitOrder}
                    validateOnBlur
                    validateOnMount
                >
                    {({ values, errors, touched, setFieldValue, validateForm, setTouched }) => (
                        <Form>
                            <Grid container gap={isMobile ? '0' : '2.5rem'}>
                                {isMobile && (
                                    <Grid xs={12}>
                                        <Stack
                                            direction={'row'}
                                            spacing={2}
                                            alignContent={'center'}
                                            justifyContent={'space-between'}
                                        >
                                            <Heading>
                                                {/* {currStep === 1 && 'Contact Details'} */}
                                                {currStep === 1 && 'Shipping Details'}
                                                {currStep === 2 && 'Review your Order'}
                                            </Heading>
                                            <Typography sx={StepTextStyle}>
                                                Step {currStep}/2
                                            </Typography>
                                        </Stack>
                                        <LinearProgress
                                            sx={{ my: 2 }}
                                            color="inherit"
                                            variant="determinate"
                                            value={(currStep / 2) * 100}
                                        />
                                    </Grid>
                                )}

                                {/* <Fade
                                    in={currStep === 1 || !isMobile}
                                    className="w-100"
                                    unmountOnExit
                                >
                                    <Box>
                                        <CustomerDetails
                                            errors={errors}
                                            touched={touched}
                                            phoneNumberRef={phoneNumberRef}
                                        />
                                    </Box>
                                </Fade> */}

                                <Fade
                                    in={currStep === 1 || !isMobile}
                                    className="w-100"
                                    unmountOnExit
                                >
                                    <Box>
                                        <AddressDetails
                                            errors={errors}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setAddressAndPaymentData={setAddressAndPaymentData}
                                            availableAddresses={availableAddresses}
                                            selectedAddressId={
                                                addressAndPaymentData.selectedAddressId
                                            }
                                            setSelectedAddressId={setAddressAndPaymentData}
                                            phoneNumberRef={phoneNumberRef}
                                        />
                                    </Box>
                                </Fade>

                                <Fade
                                    in={currStep === 2 || !isMobile}
                                    className="w-100"
                                    unmountOnExit
                                >
                                    <Grid
                                        container
                                        spacing={isMobile ? 0 : '1.875rem'}
                                        gap={isMobile ? '1.25rem' : '1.5rem'}
                                        paddingLeft={isMobile ? '' : '15px'}
                                        m={0}
                                    >
                                        <Grid xs={12}>
                                            <ProductList />
                                        </Grid>
                                        {totalPoints > 0 && firstReward && (
                                            <Grid xs={12}>
                                                <Heading>Rewards</Heading>

                                                <RewardPoints
                                                    totalPoints={totalPoints}
                                                    rewardDetails={firstReward}
                                                    handleModalAction={
                                                        handleRedeemRewardModalAction
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        {coupons.length > 0 && (
                                            <Grid xs={12}>
                                                <CouponSection
                                                    applyCoupon={applyCoupon}
                                                    removeCoupon={removeCoupon}
                                                    coupons={coupons}
                                                    setOpenCouponModal={setOpenCouponModal}
                                                />
                                            </Grid>
                                        )}

                                        <Grid xs={12}>
                                            <BillDetails />
                                        </Grid>

                                        <Grid xs={12}>
                                            <PaymentMethod
                                                paymentMethod={addressAndPaymentData.paymentMethod}
                                                setPaymentMethod={setAddressAndPaymentData}
                                            />
                                        </Grid>

                                        <Grid xs={12} sx={NoteBox}>
                                            <Typography sx={NotesStyle}>
                                                Is there anything else you’d like to add?
                                            </Typography>
                                            <Field
                                                name="notes"
                                                placeholder="I would like my order to be delivered in evening"
                                                as={TextField}
                                                size={isMobile ? 'small' : 'medium'}
                                                multiline
                                                rows={3}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Fade>

                                {!isMobile && (
                                    <Grid
                                        xs={12}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        paddingRight={'30px'}
                                    >
                                        <Stack direction={'row'} spacing={2}>
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                size="large"
                                                onClick={e => navigate('/products')}
                                                sx={{ minWidth: '9.375rem' }}
                                                disabled={
                                                    sendOTPFormMut.isPending ||
                                                    createOrderMut.isPending
                                                }
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                color="primary"
                                                size="large"
                                                sx={{ minWidth: '9.375rem' }}
                                                disabled={
                                                    sendOTPFormMut.isPending ||
                                                    isAnyProductSoldOut ||
                                                    cartItems?.items?.length == 0 ||
                                                    createOrderMut.isPending
                                                }
                                                type="submit"
                                            >
                                                {sendOTPFormMut.isPending ? (
                                                    <>
                                                        <CircularProgress
                                                            size={15}
                                                            sx={{ mr: 1 }}
                                                            color="inherit"
                                                        />
                                                        Processing
                                                    </>
                                                ) : createOrderMut.isPending ? (
                                                    'Placing your order...'
                                                ) : (
                                                    'Confirm Order'
                                                )}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                )}
                            </Grid>
                            {isMobile && (
                                <AppBar
                                    position="fixed"
                                    color="inherit"
                                    sx={{ top: 'auto', bottom: 0 }}
                                >
                                    <Toolbar>
                                        <Stack direction={'row'} spacing={2} width={'100%'}>
                                            {currStep === 1 ? (
                                                <Button
                                                    onClick={e => navigate('/products')}
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="large"
                                                    fullWidth
                                                >
                                                    Cancel
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => {
                                                        setCurrStep(currStep - 1);
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="large"
                                                    fullWidth
                                                >
                                                    Back
                                                </Button>
                                            )}

                                            {currStep === 2 ? (
                                                <Button
                                                    variant="contained"
                                                    disableElevation
                                                    id="submit-order"
                                                    color="primary"
                                                    size="large"
                                                    type="submit"
                                                    disabled={
                                                        sendOTPFormMut.isPending ||
                                                        createOrderMut.isPending
                                                    }
                                                    fullWidth
                                                >
                                                    {sendOTPFormMut.isPending ? (
                                                        <>
                                                            <CircularProgress
                                                                size={13}
                                                                sx={{ mr: 1 }}
                                                                color="inherit"
                                                            />
                                                            Processing
                                                        </>
                                                    ) : (
                                                        'Confirm Order'
                                                    )}
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    disableElevation
                                                    id="next-step"
                                                    color="primary"
                                                    size="large"
                                                    onClick={async e => {
                                                        e.preventDefault();
                                                        const userSchema = {
                                                            firstName: true,
                                                            phoneNumber: true,
                                                        };
                                                        const schema =
                                                            addressAndPaymentData.deliveryAtMyPlace
                                                                ? {
                                                                      user: userSchema,
                                                                      address: {
                                                                          // Add the address fields as touched
                                                                          address_line_one: true,
                                                                          address_line_two: true,
                                                                          pincode: true,
                                                                          city: true,
                                                                          state: true,
                                                                      },
                                                                  }
                                                                : {
                                                                      user: userSchema,
                                                                  };

                                                        switch (currStep) {
                                                            case 1:
                                                                setTouched(schema);
                                                                window.scrollTo(0, 0);
                                                                break;
                                                            // case 2:
                                                            //     setTouched({
                                                            //         user: {
                                                            //             // Keep the user fields as touched
                                                            //             firstName: true,
                                                            //             phoneNumber: true,
                                                            //         },
                                                            //         address: {
                                                            //             // Add the address fields as touched
                                                            //             address_line_one: true,
                                                            //             address_line_two: true,
                                                            //             pincode: true,
                                                            //             city: true,
                                                            //             state: true,
                                                            //         },
                                                            //     });
                                                            //     window.scrollTo(0, 0);
                                                            //     break;
                                                            default:
                                                                break;
                                                        }
                                                        // Wait for a moment to allow setTouched to complete
                                                        // await new Promise(resolve => setTimeout(resolve, 1));
                                                        const errors = await validateForm();
                                                        if (Object.keys(errors).length === 0) {
                                                            setCurrStep(currStep + 1);
                                                            window.scrollTo(0, 0);
                                                        }
                                                    }}
                                                    fullWidth
                                                >
                                                    Next
                                                </Button>
                                            )}
                                        </Stack>
                                    </Toolbar>
                                </AppBar>
                            )}
                        </Form>
                    )}
                </Formik>
            </>

            {openLoginModal && (
                <Login
                    handleLoginModalAction={handleLoginModalAction}
                    open={openLoginModal}
                    preAuthSessionId={preAuthData.preAuthSessionId}
                    deviceId={preAuthData.deviceId}
                    phone={preAuthData.phone}
                    sendOTP={sendOTP}
                    cartItems={cartItems}
                    orderData={formData}
                    createOrderMut={createOrderMut}
                    createAddressMut={createAddressMut}
                    sendOtpActionStatus={sendOTPFormMut.isPending}
                    paymentMethod={addressAndPaymentData.paymentMethod}
                    setCurrStep={setCurrStep}
                />
            )}
            {openCouponModal && (
                <CouponsModal
                    open={openCouponModal}
                    handleClose={handleCloseCouponModal}
                    coupons={coupons}
                    appliedCouponId={cartItems?.coupon?.id || ''}
                    applyCoupon={applyCoupon}
                    removeCoupon={removeCoupon}
                />
            )}

            {redeemRewardModal && (
                <RedeemRewardModal
                    handleCouponCodeOpen={handleRedeemRewardModalAction}
                    handleClose={handleRedeemRewardModalAction}
                    openModal={redeemRewardModal}
                    selectedCouponCode={firstReward._id}
                    phone={userData?.mobile || ''}
                    sendOTP={redeemRequestMut}
                    sendOtpActionStatus={sendRedeemRequestMut.isPending}
                    setOpenCouponModal={redeemRewardModal}
                    setCouponData={setGeneratedCoupon}
                />
            )}

            {redeemSuccessModal && applyCouponFailed == null && (
                <RedeemSuccessModal
                    openModal={redeemSuccessModal}
                    handleClose={handleRedeemCouponModalAction}
                    handleOpen={handleRedeemCouponModalAction}
                    couponData={generatedCoupon}
                    isShowButtons={false}
                />
            )}
            {/* <Snackbar
                open={alertMessage}
                autoHideDuration={100}
                message="This coupon is not applicable"
                sx={SnackbarStyle}
            /> */}
        </Container>
    );
}
