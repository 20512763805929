import { styled } from '@mui/material/styles';
import { AppBar, Box, Typography } from '@mui/material';
import { appColor } from '../../common/appColor/appColor';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';

export const Headline = styled(Typography)`
  color: #fff;
  text-align: center;
  font-family: Cambon-Semi-Bold !important;
  font-size: 1.375rem;
  font-style: normal;
  line-height: normal;
`;

export const TooltipStyle = {
  justifyContent: 'space-between',
  paddingBlock: '14px',
  maxWidth: '1440px',
  width: '100%',
  paddingRight: '4rem !important',
  paddingLeft: '4rem !important',
  '@media (min-width: 1441px)': {
      paddingRight: '0 !important',
      paddingLeft: '0 !important',
  },
  '@media (max-width: 768px)': {
    justifyContent: 'space-between',
    paddingBlock: '0.875rem',
    width: '100%',
    paddingRight: '1.5rem !important',
    paddingLeft: '1.5rem !important',
  },
  
}

export const NavBar = styled(AppBar)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  border-bottom: 1px;
  height: 80px;
  background: red;
  box-shadow: none;
  z-index: 100;
  @media (max-width: 768px) {
    justify-content: start;
    padding: 0.88rem 1.25rem;
  }
  &:before {
    content: '';
    position: absolute;

    background-color: transparent;
    bottom: -50px;
    height: 50px;
    left: 0px;
    width: 25px;
    border-top-left-radius: 1.25rem;
    box-shadow: 0 -25px 0 0 #14141e;
  }
  &:after {
    content: '';
    position: absolute;

    background-color: transparent;
    bottom: -50px;
    height: 50px;
    right: 0px;
    width: 25px;
    border-top-left-radius: 1.25rem;
    box-shadow: 0 -25px 0 0 #14141e;
    transform: rotate(0deg) scaleX(-1);
  }
`;
export const NewAppBar = styled(AppBar)`
  &:before {
    content: '';
    position: absolute;

    background-color: transparent;
    bottom: -50px;
    height: 50px;
    left: 0px;
    width: 25px;
    border-top-left-radius: 1.25rem;
    box-shadow: 0 -25px 0 0 #14141e;
  }
  &:after {
    content: '';
    position: absolute;

    background-color: transparent;
    bottom: -50px;
    height: 50px;
    right: 0px;
    width: 25px;
    border-top-left-radius: 1.25rem;
    box-shadow: 0 -25px 0 0 #14141e;
    transform: rotate(0deg) scaleX(-1);
  }
`;

export const NavBarTop = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0.625rem 3.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  background: var(--yellow-sec, #fddc2e);
  top: 0;
  position: relative;
  z-index: 100;
  height: 20px;
  @media (max-width: 768px) {
    padding: 0.625rem 1rem;
  }
`;

const Flex = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const HeaderContainer = styled(Flex)`
  padding-bottom: 72px;
`;

export const NavItem = styled(Flex)`
  gap: 3.25rem;
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const Logo = styled(Flex)`
  gap: 52px;
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const LogoSmall = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 72px;
`;

export const Item = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.5rem;
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: start;
    cursor: pointer;
  }
`;

export const DesktopItemLabel = styled(Box)`
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: Euclid-Circular-A-Regular;
`;

export const ItemLabel = styled(Box)`
  /* color: ${appColor.textColor}; */
  font-weight: 400;
  font-size: 14px;
  font-family: Euclid-Circular-A-Regular;
`;

export const Menu = styled(IconButton)`
  color: ${appColor.textColor};
  position: absolute;
  right: 0;
  display: flex;
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const SnDrawer = styled(Drawer)`
  display: flex;
  box-sizing: border-box;
  width: 540;
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const StyleLink = {
  textDecoration: 'none',
};

export const StyleMobProfile = {
  borderBottom:"1px dashed #32323F"
}

export const CartAndAccountBox={
  display: 'flex',
  gap: '2rem',
  alignItems: 'end',
  color: '#FFF',
  
}

export const AccountBox = {
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'end',
  color: '#FFF',
  cursor: 'pointer',
}
