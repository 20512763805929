export const appActions: any = {
    GET_PRODUCT_CATEGORY: 'GET_PRODUCT_CATEGORY',
    GET_PRODUCT: 'GET_PRODUCT',
    GET_UNIQUE_PRODUCT: 'GET_UNIQUE_PRODUCT',
    GET_EXCLUSIVE_OFFERS: 'GET_EXCLUSIVE_OFFERS',

    ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
    DELETE_CART_ITEM: 'DELETE_CART_ITEM',
    UPDATE_CART_ITEM: 'UPDATE_CART_ITEM',
    APPLY_COUPON: 'APPLY_COUPON',
    REMOVE_COUPON: 'REMOVE_COUPON',
    GET_CART: 'GET_CART',
    SET_CART_DRAWER: 'SET_CART_DRAWER',

    GET_USER: 'GET_USER',
    GET_REWARDS: 'GET_REWARDS',
    UPDATE_POINTS: 'UPDATE_POINTS',
    LOGOUT: 'LOGOUT',

    CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',
};
