import { styled } from '@mui/material/styles';
import { Box, TextField, Button, TextareaAutosize } from '@mui/material';

export const DropDownField = {
  width: '100%',
};

export const DropDownBoxMargin = {
  marginRight: '1rem',
};

export const Container = styled(Box)`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FormContainer = styled(Box)`
  border: 1px solid rgba(205, 205, 205, 0.5);
  padding: 0 1rem 1rem;
  margin-top: 1.88rem;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
    border: none;
    border-top: 1px solid rgba(204, 204, 204, 0.5);
    border-radius: 0;
  }
`;

export const SpanButton = styled(Box)`
  display: flex;
  padding: 0.25rem 0.375rem;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.3125rem;
  background: #ededed;
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03125rem;
  margin-left: 0.5rem;
`;

export const Title = styled(Box)`
  color: var(--Black, #14141e);
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const StepSpan = styled(Box)`
  color: #7f808c;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Stepper = styled(Box)`
  @media only screen and (max-width: 600px) {
    color: black;
  }
`;
export const InputFieldContainer = styled(Box)`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 1.25rem;
  }
`;

export const AutoCompleteStyle = {
  marginTop: '1.88rem',
};

export const AutoCompleteBox = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 1rem',
  margin: '0.5rem',
};

export const AutoCompleteCost = {
  display: 'flex',
  padding: '0.1rem 0.5rem',
  alignItems: 'center',
  gap: '0.625rem',
  borderRadius: '0.5rem',
  border: '1px solid rgba(204, 204, 204, 0.50)',
};

export const ServicePlanBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  cursor: pointer;
  padding: 1rem 1.125rem;
  border-bottom: 1px solid rgb(204, 204, 204);
  gap: 0.3rem;
`;

export const MainServicePlan = styled(Box)`
  border: 1px solid rgb(204, 204, 204);
`;

export const ServicePlanTitleLabel = styled(Box)`
  color: #231f20;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 112.5% */
  letter-spacing: 0.02rem;
`;

export const FormTitle = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.03125rem;
  position: absolute;
  margin: -0.6rem 0rem;
  background-color: white;
  @media (max-width: 768px) {
    color: #14141e;
    font-weight: 500;
    padding-right: 0.62rem;
  }
`;

export const ServicePlantitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
`;

export const ServicePlan = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const ServicePlanCostBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.1rem;
  width: 180px;
  @media (max-width: 768px) {
    width: 370px;
  }
`;

export const ServiceTime = styled(Box)`
  color: #5f5f5f;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.9375rem;
`;

export const Package = styled('div')<{ isOpen?: boolean }>(props => ({
  color: '#000',
  fontFamily: 'Euclid-Circular-A-Regular',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  overflow: 'hidden',
  maxHeight: props.isOpen ? '75px' : '15px',
  transition: props.isOpen ? 'max-height 0.20s ease-in' : 'max-height 0.20s ease-out',
  paddingLeft: '30px',
}));

export const Label = styled(Box)`
  color: #4c4c4c;
  font-family: Euclid-Circular-A-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const IncludesText = styled(Box)`
  color: #333;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.125rem;
  text-decoration-line: underline;
`;

export const ServicePlanDes = styled(Box)`
  color: #333;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 30px;
  gap: 0.3rem;
  display: flex;
  flex-direction: column;
`;

export const ServicePlanCost = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
`;

export const InputField = styled(TextField)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  width: 100%;
`;

export const DropDownBox = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 100% */
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 1.25rem;
  }
`;

export const CalendarConatiner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  margin-top: 1.88rem;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  padding-top: 1.88rem;
  @media (max-width: 768px) {
    border: none;
    padding-top: 0;
    margin-top: 1.25rem;
    gap: 0;
  }
`;

export const CalendarMonthAndYear = styled(Box)`
  display: flex;
  gap: 0.4rem;
`;

export const CalendarMonth = styled(Box)`
  color: var(--Black, #14141e);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CalendarYear = styled(Box)`
  color: var(--Black, #14141e);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const WeekScheduleConatiner = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  margin-top: 0.75rem;
  @media (max-width: 768px) {
    gap: 0.3rem;
  }
`;

export const WeekScheduleRow = styled(Box)`
  border: 0;
  width: 100%;
`;

export const WeekScheduleDate = styled(Box)`
  display: flex;
  padding: 0.6rem 1.12rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  border-radius: 3.125rem;
  cursor: pointer;
  @media (max-width: 768px) {
    gap: 0;
    padding: 0.5rem 0.125rem;
  }
`;

export const TimeBoxs = styled(Box)`
  width: 100%;
  display: flex;
  padding: 0.625rem 0.2rem;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  flex: 1 0 0;
  border-radius: 0.75rem;
  margin: 1rem 0;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const TimeBoxsContainer = styled(Box)`
  display: flex;
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 1.88rem;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    border: none;
    padding-bottom: 0;
    margin-bottom: 1.25rem;
  }
`;

export const RadioStyle = {
  justifyContent: 'start',
  display: 'flex',
  padding: '3px 9px',
  '&.Mui-checked': {
    color: '#FDDC2E',
  },
};

export const TimeDay = styled(Box)`
  color: var(--Black, #14141e);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
`;

export const TimeSlot = styled(Box)`
  color: #333;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 0.75rem;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

export const ViewDetails = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.2rem;
`;

export const ViewDetailsButton = styled(Button)`
  display: flex;
  padding: 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  color: #14141e;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CommentBoxTitle = styled(Box)`
  color: #333;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 1.88rem;
  margin-bottom: 0.25rem;
  @media (max-width: 768px) {
    padding-top: 1.25rem;
  }
`;

export const CommentBox = styled(TextareaAutosize)`
  display: flex;
  height: 6rem !important;
  width: 95% !important;
  padding: 0.875rem 0.75rem 0.875rem 1rem;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.375rem;
  border: 1px solid rgba(204, 204, 204, 0.5);
  background: #fff;
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const AddAnotherBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.88rem;
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;

export const AddAnotherPetText = styled(Button)`
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  gap: 0.375rem;
  display: flex;
  padding: 0.625rem 0.75rem;
  display: flex;
  align-items: center;
  color: #14141e;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FormCollapse = styled(Box)`
  padding-top: 1.13rem;
  @media (max-width: 768px) {
    border: 1px solid rgba(205, 205, 205, 0.5);
    border-radius: 0.75rem 0.75rem 0 0;
    margin-top: 1.35rem;
    padding: 0.75rem;
    padding-top: 0;
  }
`;

export const PetService = styled(Box)`
  display: flex;
  padding: 0.25rem 0rem;
  align-items: center;
  gap: 0.9375rem;
  justify-content: space-between;
  width: 100%;
`;

export const NameAndType = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  padding: 0.75rem 0;
  margin-right: 2rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-right: 0;
  }
`;

export const DateAndTimeBox = styled(Box)`
  display: flex;
  height: 1.25rem;
  align-items: center;
  gap: 0.625rem;
`;

export const CollapseDetails = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.03125rem;
  display: flex;
  align-items: center;
`;

export const CollapseDateAndTime = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.03125rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    right: 2rem;
    position: absolute;
  }
`;

export const FormCollapseService = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FormCollapseServiceTitle = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  padding: 1rem 0;
`;

export const ViewBox = styled(Box)`
  display: flex;
  padding: 1rem 1rem;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  width: 100%;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const ViewMore = styled(Box)`
  color: #231f20;
  text-align: center;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 128.571% */
  letter-spacing: 0.0175rem;
  display: flex;
  cursor: pointer;
`;

export const ViewImage = styled(Box)`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
`;
