import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    ListItemText,
    Skeleton,
    Stack,
    Toolbar,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
    useTheme,
} from '@mui/material';
import CustomIcon from '../../component/icon';
import { useDispatch } from 'react-redux';
import {
    CouponStyle,
    DividerStyle,
    Headline,
    MRPTitle,
    Paragraph,
    SubMRPTitle,
    Title,
    UserAddress,
    UserBox,
    UserBoxes,
    UserContainer,
    UserDetails,
    UserDetailsTitle,
    UserPersonalDetails,
    UserPhoneAndEmailBox,
} from './ConfirmationStyles';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useState } from 'react';
import { getCartsAction } from '../../service/action/cart/cartActions';
import { useMutation } from '@tanstack/react-query';
import { getOrderDetails } from '../../service/api/order';
import defaultImage from '../../assets/images/defaultImage.svg';
import { BillItemName } from '../order/OrderStyles';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { Link, useNavigate } from 'react-router-dom';
import CustomStepper from '../../component/CustomStepper';
import { CURRENCY, CURRENCY_SIGN } from '../../common/constants';
import { Icon } from '@iconify/react';
import Icons from '../../component/icon';
import { PointsStyle } from '../cart/CartStyle';
import PointsIcon from '../../assets/images/points.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@emotion/styled';
import { EstimatedBox, EstimatedText, OrderDetailsDate, TackShipmentLink } from './confirmStyle';
import { format } from 'date-fns';

type Props = {
    orderId: String;
    page: String;
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
        display: 'inline-flex',
        padding: '6px 12px 6px 10px',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '12px',
        color: 'var(--Accent-White, #FFF)',
        textAlign: 'center',
        fontFamily: 'Euclid-Circular-A-Regular',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        cursor: 'pointer',
    },
}));

export default function ConfirmationView({ orderId, page }: Props) {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState({
        address: {
            name: '',
            email: '',
            phone: '',
            type: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            pinCode: '',
            country: '',
        },
        createdAt: '',
        orderNo: '',
        couponCode: '',
        subtotal: 0,
        total: 0,
        tax: 0,
        deliveryFee: 0,
        deliveryFeeWaiver: 0,
        deliveryFeeMessage: '',
        applicableDiscountInr: 0,
        metaData: {
            notes: '',
            requestedProducts: [],
        },
        fulfillment: {
            providerOrderTrackingUrl: '',
            providerEstimatedDelivery: '',
        },
        items: [
            {
                fullfillmentTrackingId: '',
                product: {
                    createdAt: '',
                    description: '',
                    discountMetric: '',
                    discountValue: '',
                    externalItemCode: '',
                    id: '',
                    name: '',
                },
            },
        ],
    });

    const dispatch = useDispatch();
    useEffect(() => {
        getOrderMut.mutate({ orderId });
        dispatch(getCartsAction({}));
    }, []);

    const isShowRewardPointsLabel = () => {
        const orderDate = new Date(orderData.createdAt);

        // Add 5 days to the order date
        const addedDate = new Date(orderDate);
        addedDate.setDate(addedDate.getDate() + 5);

        // Get the current date
        const currentDate = new Date();

        // Compare the dates
        if (currentDate > addedDate) {
            return false;
        } else {
            return true;
        }
    };

    const getOrderMut = useMutation({
        mutationFn: (data: any) => getOrderDetails(data),
        onSuccess: (res: any) => {
            if (!res.data) {
                navigate('/products');
            }
            setOrderData(res.data);
        },
        onError: (error: any) => {
            navigate('/products');
            console.log('ERROR WHILE CREATE ORDER', error);
        },
    });

    const applicableDiscountInr = Math.round(Number(orderData?.applicableDiscountInr) || 0);

    if (orderData) {
        const { address1, address2, city, state, pinCode, country } = orderData?.address;
        const fullAddress = [address1, address2, city, state, pinCode, country]
            .filter(Boolean)
            .join(', ');

        const addresType = orderData?.address?.type || '';

        const estimatedDate = () => {
            const dateFormat = 'MMM dd, yyyy';
            const deliveryDate = orderData?.fulfillment?.providerEstimatedDelivery;

            const formattedDate = deliveryDate ? format(new Date(deliveryDate), dateFormat) : '';

            return formattedDate;
        };

        return (
            <Container
                maxWidth="md"
                sx={{
                    pt: isMobile || page ? null : '2rem',
                    px: '0 !important',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobile || page ? '100%' : '50%',
                    marginLeft: page ? 'revert' : 'auto',
                }}
            >
                {!page && (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        sx={{
                            width: isMobile ? '321px' : '486px',
                            height: isMobile ? '205px' : '311px',
                        }}
                    >
                        <CustomIcon name="confirmation-graphic" />
                    </Box>
                )}
                <Grid
                    width={'100%'}
                    container
                    xs={12}
                    gap={page ? '1rem' : '2.5rem'}
                    marginLeft={''}
                >
                    {!page && (
                        <Grid xs={12} display={'flex'} justifyContent={'center'}>
                            <Stack justifyContent={'center'} display={'flex'} spacing={0.5}>
                                <Headline>Thank you! {orderData.address.name}</Headline>
                            </Stack>
                        </Grid>
                    )}
                    {page &&
                        (getOrderMut.isPending ? (
                            <Skeleton
                                width={150}
                                height={60}
                                sx={{ transformOrigin: '0 0', marginBottom: '-15px' }}
                            />
                        ) : (
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                Order Details
                            </Typography>
                        ))}

                    <Grid xs={12} display={'flex'} justifyContent={'center'}>
                        <Card
                            variant="outlined"
                            sx={{ width: '100%', bgcolor: '#f8f9f9', borderRadius: '1rem' }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                    background: '#FFF',
                                    padding: '0.5rem',
                                }}
                            >
                                {getOrderMut.isPending ? (
                                    <Skeleton
                                        width={110}
                                        height={40}
                                        sx={{ transformOrigin: '0 0', marginBottom: '-15px' }}
                                    />
                                ) : (
                                    <Typography sx={{ fontWeight: 600 }}>
                                        #{orderData?.orderNo || ''}
                                    </Typography>
                                )}
                            </Grid>
                            <Box
                                sx={{
                                    padding: isMobile ? '1rem 0.4rem' : '1.5rem 2.5rem',
                                    background: 'rgba(222, 222, 222, 0.20)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                }}
                            >
                                <CustomStepper />
                                <Box sx={EstimatedBox}>
                                    {orderData?.fulfillment?.providerEstimatedDelivery && (
                                        <>
                                            <Typography sx={EstimatedText}>
                                                Estimated delivery by:
                                            </Typography>

                                            <Typography sx={OrderDetailsDate}>
                                                {estimatedDate()}
                                            </Typography>
                                        </>
                                    )}
                                    <TackShipmentLink
                                        to={orderData?.fulfillment?.providerOrderTrackingUrl}
                                        target="_blank"
                                    >
                                        <Icons name="Track-shipment" />
                                        Track Shipment
                                    </TackShipmentLink>
                                </Box>
                            </Box>

                            {isShowRewardPointsLabel() &&
                                (getOrderMut.isPending ? (
                                    <Skeleton
                                        width={'100%'}
                                        height={70}
                                        sx={{ transformOrigin: '0 0', marginBottom: '-15px' }}
                                    />
                                ) : (
                                    <Box
                                        style={{
                                            padding: '12px 20px',
                                            background: 'rgba(109, 57, 255, 0.10)',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', gap: '14px' }}>
                                            <img src={PointsIcon} alt="" />
                                            <Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '6px',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Wohoo! You get total of{' '}
                                                        <PointsStyle>
                                                            {Math.floor(
                                                                orderData?.total
                                                            ).toLocaleString('en-IN')}
                                                        </PointsStyle>{' '}
                                                        points with this order{' '}
                                                    </Typography>
                                                    <Link
                                                        to="#"
                                                        onClick={() =>
                                                            window.open('/rewards', '_blank')
                                                        }
                                                        style={{ height: '1rem' }}
                                                    >
                                                        <BootstrapTooltip
                                                            title={'Learn more'}
                                                            placement="top"
                                                        >
                                                            <InfoOutlinedIcon
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    color: '#0A69D9',
                                                                }}
                                                            />
                                                        </BootstrapTooltip>
                                                    </Link>
                                                </Box>

                                                <Typography
                                                    sx={{ color: '#5E5E5E', fontSize: '13px' }}
                                                >
                                                    Note: Reward points will credited within 5 days
                                                    of delivery.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}

                            <CardContent sx={{ bgcolor: 'background.paper' }}>
                                {!getOrderMut.isPending && (
                                    <Typography sx={Title}>
                                        {`Your Picks (${orderData?.items?.length})`}
                                    </Typography>
                                )}
                                {getOrderMut.isPending ? (
                                    <Stack spacing={0}>
                                        <Skeleton height={80} />
                                        <Skeleton height={80} />
                                        <Skeleton height={80} />
                                        <Skeleton height={80} />
                                    </Stack>
                                ) : (
                                    orderData &&
                                    orderData?.items?.map((product: any, index: number) => {
                                        const secondary = product.product.productVariantOption
                                            ? product?.product?.productVariantOption
                                                  .map((item: any) => {
                                                      return item.name + ': ' + item?.value?.name;
                                                  })
                                                  .join(', ')
                                            : null;

                                        return (
                                            <Box key={index}>
                                                <CardContent
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: '1rem',
                                                        px: 0,
                                                    }}
                                                >
                                                    <Stack
                                                        sx={{
                                                            border: '1px solid rgb(236, 236, 236)',
                                                            padding: '5px',
                                                            height: '5rem',
                                                            flexShrink: '0',
                                                            marginTop: '10px',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                product?.product?.media
                                                                    ? product?.product?.media.url
                                                                    : defaultImage
                                                            }
                                                            alt=""
                                                            style={{
                                                                aspectRatio: 1,
                                                                borderRadius: '1rem',
                                                                width: '4rem',
                                                                height: '4rem',
                                                                objectFit: 'contain',
                                                            }}
                                                        />
                                                    </Stack>
                                                    <Stack width={'100%'}>
                                                        <Stack sx={{ width: '100%' }}>
                                                            <ListItemText
                                                                primary={product?.product?.name}
                                                                primaryTypographyProps={{
                                                                    color: 'var(--Black, #14141E)',
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Medium',
                                                                    fontSize: '1rem',
                                                                    fontStyle: 'normal',
                                                                    lineHeight: 'normal',
                                                                    letterSpacing: '0.03125rem',
                                                                    textTransform: 'capitalize',
                                                                }}
                                                                secondary={secondary || ''}
                                                                secondaryTypographyProps={{
                                                                    color: '#747474',
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Regular',
                                                                    fontSize: '0.875rem',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: 'normal',
                                                                }}
                                                            />
                                                        </Stack>
                                                        <Typography sx={{ fontSize: '15px' }}>
                                                            Qty: {product.quantity}
                                                        </Typography>
                                                    </Stack>
                                                    <Box
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={0.5}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--Black, #14141E)',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Semi-Bold',
                                                                    fontSize: '1rem',
                                                                    fontStyle: 'normal',
                                                                    lineHeight: '1.5rem',
                                                                }}
                                                            >
                                                                {CURRENCY_SIGN}
                                                                {`${product?.sellingPrice}`}
                                                            </Typography>

                                                            {product?.discountValue > 0 && (
                                                                <Typography
                                                                    sx={{
                                                                        overflow: 'hidden',
                                                                        color: '#7B7979',
                                                                        textAlign: 'right',
                                                                        whiteSpace: 'nowrap',
                                                                        textOverflow: 'ellipsis',
                                                                        fontFamily:
                                                                            'Euclid-Circular-A-Regular',
                                                                        fontSize: '0.825rem',
                                                                        fontStyle: 'normal',
                                                                        lineHeight: '1rem',
                                                                        leadingTrim: 'both',
                                                                        textEdge: 'cap',
                                                                        textDecorationLine:
                                                                            'strikethrough',
                                                                        display: '-webkit-box',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        WebkitLineClamp: 2,
                                                                        textDecoration:
                                                                            'line-through',
                                                                    }}
                                                                    // component="div"
                                                                >
                                                                    {`${CURRENCY_SIGN}${product?.originalPrice}`}
                                                                </Typography>
                                                            )}
                                                        </Stack>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                float: 'inline-end',
                                                            }}
                                                        >
                                                            {product?.discountValue > 0 && (
                                                                <Box
                                                                    sx={{
                                                                        background:
                                                                            theme.palette.primary
                                                                                .main,
                                                                        borderRadius: '0.5rem',
                                                                        padding: '3px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="body2"
                                                                        fontSize={'12px'}
                                                                    >
                                                                        {product?.discountMetric ===
                                                                        'INR'
                                                                            ? CURRENCY
                                                                            : ' '}{' '}
                                                                        {product?.discountValue}
                                                                        {product?.discountMetric ===
                                                                        'PERCENTAGE'
                                                                            ? '% '
                                                                            : ' '}
                                                                        Off
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                                {orderData?.items?.length - 1 != index && (
                                                    <Divider />
                                                )}
                                            </Box>
                                        );
                                    })
                                )}
                            </CardContent>
                            <CardContent>
                                {getOrderMut.isPending ? (
                                    <Stack spacing={0}>
                                        <Skeleton height={60} />
                                        <Skeleton height={60} />
                                        <Skeleton height={60} />
                                        <Skeleton height={60} />
                                    </Stack>
                                ) : (
                                    <>
                                        <Stack
                                            pb={0}
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                        >
                                            <SubMRPTitle>
                                                {`Subtotal (${orderData?.items?.length} items)`}
                                                <Typography
                                                    sx={BillItemName}
                                                    style={{
                                                        overflow: 'hidden',
                                                        color: '#747474',
                                                        textOverflow: 'ellipsis',
                                                        fontFamily: 'Euclid-Circular-A-Regular',
                                                        fontSize: '0.85rem',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                        marginTop: '.2rem',
                                                    }}
                                                >
                                                    inclusive of all taxes
                                                </Typography>
                                            </SubMRPTitle>
                                            <SubMRPTitle>
                                                {CURRENCY_SIGN}
                                                {orderData?.subtotal?.toFixed(2)}
                                            </SubMRPTitle>
                                        </Stack>
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            mt={2}
                                        >
                                            <SubMRPTitle>{`Delivery Fee`}</SubMRPTitle>
                                            <SubMRPTitle
                                                style={{
                                                    textDecoration:
                                                        orderData?.deliveryFeeWaiver ===
                                                        orderData?.deliveryFee
                                                            ? 'line-through'
                                                            : 'none',
                                                }}
                                            >
                                                {CURRENCY_SIGN}
                                                {orderData?.deliveryFee.toFixed(2) || 0}
                                            </SubMRPTitle>
                                        </Stack>

                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            mt={0.5}
                                            style={{
                                                color: '#747474',
                                                textOverflow: 'ellipsis',
                                                fontFamily: 'Euclid-Circular-A-Regular',
                                                fontSize: '0.85rem',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                                marginTop: '.2rem',
                                            }}
                                        >
                                            {orderData?.deliveryFeeMessage}
                                        </Stack>

                                        {orderData?.applicableDiscountInr > 0 && (
                                            <Stack
                                                direction={'row'}
                                                justifyContent={'space-between'}
                                                mt={2}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: isMobile ? 'start' : 'center',
                                                        gap: '5px',
                                                        flexDirection: isMobile ? 'column' : 'row',
                                                    }}
                                                >
                                                    <SubMRPTitle>
                                                        {`Coupon Discount `}
                                                        {/* <span >{`(${orderData?.couponCode})`}</span> */}
                                                    </SubMRPTitle>
                                                    <CouponStyle>{`${orderData?.couponCode}`}</CouponStyle>
                                                </Box>
                                                <SubMRPTitle>
                                                    <Typography sx={{ color: '#159600' }}>
                                                        -{CURRENCY_SIGN}
                                                        {orderData?.applicableDiscountInr.toFixed(
                                                            2
                                                        ) || 0}
                                                    </Typography>
                                                </SubMRPTitle>
                                            </Stack>
                                        )}

                                        <Divider sx={DividerStyle} />
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            mt={2}
                                        >
                                            <MRPTitle>{'Total'}</MRPTitle>
                                            <MRPTitle>
                                                {CURRENCY_SIGN}
                                                {orderData?.total.toFixed(2) || 0}
                                            </MRPTitle>
                                        </Stack>
                                    </>
                                )}
                            </CardContent>
                            <CardContent sx={UserContainer}>
                                {getOrderMut.isPending ? (
                                    <Skeleton
                                        width={'100%'}
                                        height={40}
                                        sx={{ transformOrigin: '0 0', marginBottom: '-15px' }}
                                    />
                                ) : (
                                    <Typography sx={Title}>Delivery & payment details</Typography>
                                )}

                                {getOrderMut.isPending ? (
                                    <Stack spacing={0}>
                                        <Skeleton height={80} />
                                        <Skeleton height={80} />
                                        <Skeleton height={80} />
                                    </Stack>
                                ) : (
                                    <Box>
                                        <Box sx={UserBoxes}>
                                            <Icons name={'user'} />

                                            <Box sx={UserBox}>
                                                <Typography sx={UserDetailsTitle}>
                                                    {orderData?.address?.name ||
                                                        orderData?.address?.address1 ||
                                                        ''}
                                                </Typography>
                                                <Box sx={UserPhoneAndEmailBox}>
                                                    {orderData?.address?.phone && (
                                                        <Box sx={UserDetails}>
                                                            <Typography sx={UserPersonalDetails}>
                                                                Phone:
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    ...UserPersonalDetails,
                                                                    fontWeight: 400,
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Regular',
                                                                }}
                                                            >
                                                                {orderData?.address?.phone || 'NA'}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {orderData?.address?.email && (
                                                        <Box sx={UserDetails}>
                                                            <Typography sx={UserPersonalDetails}>
                                                                Email:
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    ...UserPersonalDetails,
                                                                    fontWeight: 400,
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Regular',
                                                                }}
                                                            >
                                                                {orderData?.address?.email || 'NA'}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider sx={DividerStyle} />
                                        <Box sx={UserBoxes}>
                                            <Icons name={'home'} />

                                            <Box sx={UserBox}>
                                                <Typography sx={UserDetailsTitle}>
                                                    {addresType.charAt(0).toUpperCase() +
                                                        addresType.slice(1) || 'NA'}
                                                </Typography>
                                                <Typography sx={UserAddress}>
                                                    {fullAddress || 'NA'}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider sx={DividerStyle} />
                                        <Box sx={UserBoxes}>
                                            <Icons name={'Pay-on-delivery'} />
                                            {/* <Icons name={'wallet'} /> */}

                                            <Box sx={UserBox}>
                                                <Typography sx={UserDetailsTitle}>
                                                    Pay on Delivery
                                                </Typography>
                                                <Typography sx={UserAddress}>
                                                    Pay in cash or pay online to delivery partner
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    {orderData?.metaData?.notes && (
                        <Grid xs={12} display={'flex'} justifyContent={'center'}>
                            <Card variant="outlined" sx={{ width: '100%', borderRadius: '1rem' }}>
                                <Box
                                    p={'1.25rem'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={'0.5rem'}
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--Black, #14141E)',
                                            fontVariantNumeric: 'lining-nums proportional-nums',
                                            fontFeatureSettings: "'dlig' on",
                                            fontFamily: 'Euclid-Circular-A-Medium',
                                            fontSize: '1rem',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: '0.03125rem',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        Your Note
                                    </Typography>
                                    <Paragraph>{orderData?.metaData?.notes}</Paragraph>
                                </Box>
                            </Card>
                        </Grid>
                    )}

                    {!page &&
                        (isMobile ? (
                            <AppBar
                                position="fixed"
                                color="inherit"
                                sx={{ top: 'auto', bottom: 0 }}
                            >
                                <Toolbar>
                                    <Stack direction={'row'} spacing={2} width={'100%'}>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            size="large"
                                            onClick={() => navigate('/')}
                                            sx={{ fontSize: '0.75rem', fontWeight: 500 }}
                                            fullWidth
                                            startIcon={
                                                <Icon
                                                    icon="ri:arrow-left-line"
                                                    color={theme.palette.primary.main}
                                                />
                                            }
                                        >
                                            Back to Home
                                        </Button>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            size="large"
                                            sx={{
                                                bgcolor: theme.palette.background.default,
                                                color: 'white',
                                                fontSize: '0.75rem',
                                                fontWeight: 500,
                                            }}
                                            fullWidth
                                            onClick={() => navigate('/products')}
                                            startIcon={<CustomIcon name="stars" />}
                                        >
                                            Explore More
                                        </Button>
                                    </Stack>
                                </Toolbar>
                            </AppBar>
                        ) : (
                            <Grid xs={12} display={'flex'} justifyContent={'center'}>
                                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        size="large"
                                        sx={{ minWidth: '9.375rem' }}
                                        onClick={() => navigate('/')}
                                        startIcon={
                                            <Icon
                                                icon="ri:arrow-left-line"
                                                color={theme.palette.primary.main}
                                            />
                                        }
                                    >
                                        Back to Home
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        size="large"
                                        sx={{
                                            minWidth: '9.375rem',
                                            bgcolor: theme.palette.background.default,
                                            color: 'white',
                                        }}
                                        type="submit"
                                        onClick={() => navigate('/products')}
                                        startIcon={<CustomIcon name="stars" />}
                                    >
                                        Explore More Product
                                    </Button>
                                </Stack>
                            </Grid>
                        ))}
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="md" sx={{ pt: '2rem', justifyContent: 'center' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <CustomIcon name="confirmation-graphic" />
                    <Headline>No orders founds.</Headline>
                </Box>
            </Container>
        );
    }
}
