import { Box, useTheme } from '@mui/material';
import CustomHero, { HeroWrapper } from '../component/CustomHero';
import { PageWrapper } from './products';
import ErrorBoundary from '../component/ErrorBoundary';
import FooterBanner from '../component/FooterBanner';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import BookServiceForm from '../view/bookService';
import Layout from '../component/layout/layout';
import { Fragment } from 'react';

export default function BookServicePage() {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();

    return (
        <Fragment>
            <Layout>
                <Box sx={{ backgroundColor: theme.palette.background.default }}>
                    <HeroWrapper
                        sx={{
                            paddingTop: isMobile ? 0 : '3.5rem',
                            paddingBottom: isMobile ? 0 : '3.5rem',
                        }}
                    >
                        <CustomHero title="Book a Service" />
                    </HeroWrapper>
                    <PageWrapper maxWidth={false}>
                        <ErrorBoundary>
                            <BookServiceForm />
                        </ErrorBoundary>
                        <Box py={isMobile ? '1.5rem' : '5rem'}>
                            <FooterBanner />
                        </Box>
                    </PageWrapper>
                </Box>
            </Layout>
        </Fragment>
    );
}
