import {
  Box,
  Button,
  styled,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import { appColor } from "../../common/appColor/appColor";
import { Link } from "react-router-dom";

export const MainContainer = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  background-color: ${appColor.white};
  border-radius: 50px;
  padding: 50px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 1rem;
`;

export const Container = styled(Box)`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 41.5625rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const EstimatedBox = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
}

export const EstimatedText={
  color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
  fontFeatureSettings: "'salt' on, 'ss02' on",
  fontFamily: 'Euclid-Circular-A-Regular',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  letterSpacing: '0.07px',
}

export const OrderDetailsDate={
  color: 'var(--Accent-Bright-Blue, #1059E9)',
  fontFeatureSettings: "'salt' on, 'ss02' on",
  fontFamily: 'Euclid-Circular-A-Regular',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '18px',
}
export const TackShipmentLink = styled(Link)(({ theme }) => ({
  display: "flex",
  padding: '4px 12px 4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '8px',
  border: '1px solid var(--Neutral-Grey-Grey---20, #ECECEC)',
  background: '#FFF',
  color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
  fontFamily: 'Euclid-Circular-A-Regular',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  fontStyle: 'normal',
  textAlign: 'center',
  textDecoration: "none",
  '@media(max-width: 768px)': {
    width: '100%'
}
}))

export const ImageBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 20.0625rem;
    height: 12.8125rem;
    flex-shrink: 0;
  }
`;

export const TextBox = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const ImageStyle = {
  width: "30.375rem",
  height: "22.3125rem",
};

export const ImageTextOne = styled(Box)`
  color: #333;
  font-family: Inter;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ImageTextTwo = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
`;

export const StepperBox = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "9.4375rem",
  paddingTop: "10px",
  height: "5.625rem",
  background: "rgba(233, 234, 235, 0.30)",
  marginTop: "3rem",
  border: "1px solid rgba(204, 204, 204, 0.5)",
  borderRadius: "0.75rem 0.75rem 0 0",
};

export const PetContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  width: 100%;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-top: none;
`;

export const PetBox = styled(Box)`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.9375rem;
  align-self: stretch;
  @media (max-width: 768px) {
    gap: 0.75rem;
  }
`;

export const PetIndex = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const PetName = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
`;

export const DateAndAddressBox = styled(Box)`
  display: flex;
  height: 1.25rem;
  align-items: center;
  gap: 0.625rem;
`;

export const NameAndType = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  padding: 0.75rem 0;
  margin-right: 2rem;
`;

export const DateBox = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: 0.03125rem;
`;

export const AddressBox = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: 0.03125rem;
  margin-left: 2.55rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const ServiceDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const ServiceDetailsTitle = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  padding: 1rem 0;
  @media (max-width: 768px) {
    padding-bottom: 0.38rem;
    padding-top: 0;
  }
`;

export const SpanButton = styled(Box)`
  display: flex;
  padding: 0.25rem 0.375rem;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.3125rem;
  background: #ededed;
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03125rem;
  margin-left: 0.5rem;
`;

export const PetService = styled(Box)`
  display: flex;
  padding: 0.25rem 0rem;
  align-items: center;
  gap: 0.9375rem;
  justify-content: space-between;
  width: 100%;
`;

export const TextDetails = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: 0.03125rem;
  display: flex;
  align-items: center;
`;

export const PriceDetailsBox = styled(Box)`
  display: flex;
  padding: 0.625rem 0rem;
  width: 100%;
  flex-direction: column;
  gap: 0.25rem;
  align-self: stretch;
  background: rgba(222, 222, 222, 0.3);
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-top: none;
  border-radius: 0 0 0.75rem 0.75rem;
`;

export const PriceDetailsTitle = styled(Box)`
  font-family: Euclid-Circular-A-Regular;
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  margin-bottom: 0.25rem;
`;

export const PriceBoxs = styled(Box)`
  display: flex;
  padding: 0.25rem 0rem;
  align-items: center;
  gap: 0.9375rem;
  justify-content: space-between;
`;

export const PriceTotalText = styled(Box)`
  color: var(--Dark-brown, #241f1f);
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PricingItem = styled(Box)`
  color: #4c4c4c;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: 0.03125rem;
  padding: 0.25rem;
`;

export const BackButton = styled(Button)`
  color: #231f20;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 112.5% */
  letter-spacing: 0.02rem;
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  display: inline-flex;
  padding: 0.9375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const PriceSlightly = styled(Box)`
  color: rgba(20, 20, 30, 0.8);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  align-items: center;
  display: flex;
  gap: 0.2rem;
  margin-top: 0.4rem;
`;

export const StepIconRoot = styled("div")<{
  ownerState: { active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: "#D9D9D9",
  zIndex: 1,
  color: "#fff",
  width: "2rem",
  height: "2rem",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#14141E",
  }),
}));

export const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "1rem",
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#D9D9D9",
    borderRadius: 1,
  },
}));
