import {
    Box,
    Card,
    CardContent,
    Container,
    ListItemText,
    Pagination,
    Skeleton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getOrders } from '../../service/api/order';
import {
    OrderStatus,
    BillItemName,
    PriceStyle,
    SecondaryTypographyStyle,
} from '../order/OrderStyles';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { Link, useNavigate } from 'react-router-dom';
import { CURRENCY_SIGN } from '../../common/constants';
import defaultImage from '../../assets/images/defaultImage.svg';
import { Headline } from '../confirmation/ConfirmationStyles';
import { FormattedOrderStatus } from './utils';

const skeletonStyles = {
    height: '300px',
    transformOrigin: '0 0%',
    borderRadius: '10px',
    marginBottom: '-100px',
};

export default function OrdersList({}) {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [orderData, setOrderData] = useState({
        data: [
            {
                id: '',
                status: '',
                orderNo: '',
                total: 0,
                createdAt: '',
                orderItemCount: 0,
                topItem: {
                    product: {
                        name: '',
                        media: {
                            url: '',
                        },
                        productVariantOption: [
                            {
                                name: '',
                                value: {
                                    name: '',
                                },
                            },
                        ],
                    },
                },
            },
        ],
        pagination: {
            page: 0,
            limit: 0,
            total: 0,
        },
    });

    useEffect(() => {
        getOrderMut.mutate({
            page: currentPage,
            limit: 5,
        });
    }, [currentPage]);

    const getOrderMut = useMutation({
        mutationFn: (data: any) => getOrders(data),
        onSuccess: (res: any) => {
            setOrderData(res.data);
        },
        onError: (error: any) => {
            navigate('/products');
            console.log('ERROR WHILE CREATE ORDER', error);
        },
    });

    // Handle page change
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);

        // Extracting parts of the date
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        // Formatting hours and minutes
        const formattedHours = hours % 12 || 12; // convert to 12-hour format
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Constructing the formatted date string
        const formattedDate = `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
        return formattedDate;
    };

    const calculatePageCount = () => {
        const totalItems = orderData?.pagination?.total || 0;
        const itemsPerPage = 5;
        return Math.ceil(totalItems / itemsPerPage);
    };

    if (orderData?.data.length > 0) {
        return (
            <Grid container gap={'1rem'} width={'100%'} flexDirection={'column'}>
                {getOrderMut.isPending ? (
                    <Stack spacing={0}>
                        <Skeleton
                            width={150}
                            height={60}
                            sx={{ transformOrigin: '0 0', marginBottom: '-15px' }}
                        />
                    </Stack>
                ) : (
                    <Typography variant="h5" sx={{ fontWeight: '600' }}>
                        My Orders
                    </Typography>
                )}

                {getOrderMut.isPending ? (
                    <Stack spacing={0}>
                        {[...Array(3)].map((_, index) => (
                            <Skeleton key={index} sx={skeletonStyles} />
                        ))}
                    </Stack>
                ) : (
                    orderData.data?.map((order, index) => {
                        const secondary = order?.topItem?.product.productVariantOption
                            ? order?.topItem?.product?.productVariantOption
                                  .map((item: any) => {
                                      return item.name + ': ' + item?.value?.name;
                                  })
                                  .join(', ')
                            : null;
                        const order_status = FormattedOrderStatus(order?.status);

                        return (
                            <Grid xs={12} display={'flex'} justifyContent={'center'}>
                                <Link
                                    to={`/profile/order/${order.id}`}
                                    style={{ textDecoration: 'none', width: '100%' }}
                                >
                                    <Card
                                        variant="outlined"
                                        sx={{ width: '100%', borderRadius: '1rem' }}
                                    >
                                        <Box
                                            p={isMobile ? '1rem' : '1.25rem'}
                                            display={'flex'}
                                            flexDirection={'column'}
                                            gap={'0.5rem'}
                                            key={index}
                                        >
                                            <Grid
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Black, #14141E)',
                                                        fontVariantNumeric:
                                                            'lining-nums proportional-nums',
                                                        fontFeatureSettings: "'dlig' on",
                                                        fontFamily: 'Euclid-Circular-A-Medium',
                                                        fontSize: '1rem',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: '0.03125rem',
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    Order: {`#${order.orderNo}`}
                                                    <Typography
                                                        sx={{
                                                            ...BillItemName,
                                                            color: '#747474',
                                                            fontSize: '0.85rem',
                                                            marginTop: '.2rem',
                                                        }}
                                                    >
                                                        {formatDate(order?.createdAt)}
                                                    </Typography>
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        ...OrderStatus,
                                                        backgroundColor:
                                                            order_status === 'In Progress' ||
                                                            order_status === 'Ready to pickup'
                                                                ? '#FDEBC6'
                                                                : order_status === 'Delivered'
                                                                ? '#ECECEC'
                                                                : '#FFEFF1',
                                                        color:
                                                            order_status === 'In Progress' ||
                                                            order_status === 'Ready to pickup'
                                                                ? '#FF9500'
                                                                : order_status === 'Delivered'
                                                                ? '#106E44'
                                                                : '#FF475D',
                                                    }}
                                                >
                                                    {order_status}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    gap: '1rem',
                                                }}
                                            >
                                                <Stack
                                                    sx={{
                                                        border: '1px solid rgb(236, 236, 236)',
                                                        padding: '5px',
                                                        height: '5rem',
                                                        flexShrink: '0',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            order?.topItem?.product?.media
                                                                ? order?.topItem?.product?.media
                                                                      ?.url
                                                                : defaultImage
                                                        }
                                                        alt=""
                                                        style={{
                                                            aspectRatio: 1,
                                                            borderRadius: '1rem',
                                                            width: '4rem',
                                                            height: '4rem',
                                                            objectFit: 'contain',
                                                        }}
                                                    />
                                                </Stack>
                                                <CardContent
                                                    sx={{
                                                        padding: '0px !important',
                                                        display: 'flex',
                                                        flexDirection: isMobile ? 'column' : 'row',
                                                        gap: '1rem',
                                                        justifyContent: isMobile
                                                            ? 'unset'
                                                            : 'space-between',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Stack>
                                                        <Stack>
                                                            <ListItemText
                                                                primary={
                                                                    order?.topItem?.product?.name ||
                                                                    ''
                                                                }
                                                                primaryTypographyProps={{
                                                                    color: 'var(--Black, #14141E)',
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Medium',
                                                                    fontSize: '1rem',
                                                                    fontStyle: 'normal',
                                                                    lineHeight: 'normal',
                                                                    letterSpacing: '0.03125rem',
                                                                    textTransform: 'capitalize',
                                                                }}
                                                                secondary={secondary || ''}
                                                                secondaryTypographyProps={
                                                                    SecondaryTypographyStyle
                                                                }
                                                            />
                                                            <Typography
                                                                sx={{
                                                                    color: '#747474',
                                                                    fontFamily:
                                                                        'Euclid-Circular-A-Regular',
                                                                    fontSize: '0.900rem',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: 'normal',
                                                                }}
                                                            >
                                                                {order.orderItemCount > 1 &&
                                                                    `+${
                                                                        order?.orderItemCount - 1
                                                                    } more items`}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: isMobile
                                                                ? 'row'
                                                                : 'column',
                                                            alignItems: isMobile
                                                                ? 'center'
                                                                : 'unset',
                                                            justifyContent: isMobile
                                                                ? 'space-between'
                                                                : 'column',
                                                            gap: isMobile ? 0 : '1rem',
                                                            marginTop: isMobile ? 0 : '6px',
                                                        }}
                                                    >
                                                        <Typography sx={PriceStyle}>
                                                            {CURRENCY_SIGN}
                                                            {order?.total.toFixed(2) || 0}
                                                        </Typography>
                                                        <Typography>
                                                            <Link
                                                                style={{
                                                                    textDecoration: 'none',
                                                                    fontSize: '15px',
                                                                    color: '#0073e6',
                                                                }}
                                                                to={`/profile/order/${order.id}`}
                                                            >
                                                                View Details
                                                            </Link>
                                                        </Typography>
                                                    </Stack>
                                                </CardContent>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Link>
                            </Grid>
                        );
                    })
                )}
                {!getOrderMut.isPending && (
                    <Grid xs={12} mb={5} display={'flex'} justifyContent={'center'}>
                        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                            <Pagination
                                count={calculatePageCount()}
                                page={currentPage}
                                color="primary"
                                onChange={handlePageChange}
                            />
                        </Stack>
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return (
            <Container maxWidth="md" sx={{ pt: '2rem', justifyContent: 'center' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Headline>No orders founds.</Headline>
                </Box>
            </Container>
        );
    }
}
