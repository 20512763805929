import { Fragment } from "react";
import Layout from "../component/layout/layout";
import SupportInfo from "../view/supportInfo/supportInfo";

const Support = () => {
  return (
    <Fragment>
      <Layout>
        <SupportInfo />
      </Layout>
    </Fragment>
  );
};

export default Support;
