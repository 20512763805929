import request from '../api/request';
import oldrequest from '../api/oldrequest';

export function getCategories(params?: any) {
  return request({
    url: 'products/home',
    method: 'get',
    params,
  });
}
export function getProducts(params?: any) {
  return request({
    url: 'products/',
    method: 'get',
    params,
  });
}
export function getProduct(id:any,params?: any) {
  return request({
    url: 'products/'+id,
    method: 'get',
    params,
  });
}
export function getProductsByCategory(categories="",params?: any) {
  return request({
    url: 'products?categories='+categories,
    method: 'get',
    params,
  });
}
export function sendOrder(data?: any) {
  return oldrequest({
    url: 'products/orders/',
    method: 'post',
    data,
  });
}
