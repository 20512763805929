import './App.css';
import { Provider } from 'react-redux';
import { store } from './service/store';
import AppRoutes from './route/appRoutes';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import CustomTooltip from './component/CustomTooltip';

if (process.env.REACT_APP_ENV === 'prod') {
    // @ts-ignore
    ReactGA.initialize(process.env.REACT_APP_GTAG_MEASUREMENT_ID);
}

const queryClient = new QueryClient();

const theme = createTheme({
    palette: {
        primary: {
            main: '#FDDC2E',
        },
        secondary: {
            main: 'rgba(250, 121, 33, 1)',
        },
        text: {
            primary: 'rgba(20, 20, 30, 1)',
            secondary: 'rgba(20, 20, 30, 1)',
        },
        background: {
            default: 'rgba(20, 20, 30, 1)',
        },
    },
    shape: {
        borderRadius: 3,
    },
    typography: {
        fontFamily: [
            'Cambon',
            'Euclid-Circular-A-Regular',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontFamily: 'Cambon-Semi-Bold',
        },
        body1: {
            fontFamily: 'Euclid-Circular-A-Regular',
        },
        body2: {
            fontFamily: 'Euclid-Circular-A-Regular',
        },
        subtitle1: {
            fontFamily: 'Euclid-Circular-A-Regular',
        },
        subtitle2: {
            fontFamily: 'Euclid-Circular-A-Regular',
        },
        button: {
            fontFamily: 'Euclid-Circular-A-Medium',
            fontSize: '1.125rem',
            textTransform: 'inherit',
            textDecoration: 'none',
            borderRadius: '0.75rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                    fontWeight: 500,
                },
                outlined: {
                    border: '1px solid #e6e6e6;',
                    borderRadius: '0.5rem',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: '0.3125rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    transition:
                        'border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: 'inherit',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#333',
                    fontVariantNumeric: 'lining-nums proportional-nums',
                    fontFeatureSettings: "'dlig' on",
                    fontFamily: 'Euclid-Circular-A-Regular',
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '150%',
                    marginX: 0,
                },
            },
        },
    },
});

const App = () => {
    return (
        <Provider store={store}>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <AppRoutes />
                        <CustomTooltip />
                    </BrowserRouter>
                </QueryClientProvider>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
