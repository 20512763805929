import { Box, useTheme } from '@mui/material';
import CustomHero, { HeroWrapper } from '../component/CustomHero';
import { PageWrapper } from './products';
import ErrorBoundary from '../component/ErrorBoundary';
import OrderForm from '../view/order/Checkout';
import FooterBanner from '../component/FooterBanner';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { Fragment } from 'react';
import Layout from '../component/layout/layout';

export default function OrderPage() {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();

    return (
        <Fragment>
            <Layout>
                <Box sx={{ backgroundColor: theme.palette.background.default }}>
                    <HeroWrapper
                        sx={{
                            paddingTop: isMobile ? 0 : '3.5rem',
                            paddingBottom: isMobile ? 0 : '3.5rem',
                        }}
                    >
                        <CustomHero title="Checkout" />
                    </HeroWrapper>
                    <PageWrapper
                        maxWidth={false}
                        sx={{
                            height: isMobile ? 'calc(100vh - 72px)' : 'auto',
                            borderRadius: isMobile ? '0 !important' : '1.25rem !important',
                        }}
                    >
                        <ErrorBoundary>
                            <OrderForm />
                        </ErrorBoundary>
                        <Box py={isMobile ? '1.5rem' : '5rem'}>
                            <FooterBanner />
                        </Box>
                    </PageWrapper>
                </Box>
            </Layout>
        </Fragment>
    );
}
