import { styled, ToggleButton, toggleButtonClasses, ToggleButtonGroup, toggleButtonGroupClasses } from "@mui/material"
import { CSSProperties } from "react"

export const AddToCartContainer = {
    flexDirection: "row",
    alignItems: "center",
    gap:"1rem",
    '@media (max-width: 768px)': {
        position: 'fixed',
        bottom: 0,
        padding: '10px 8px 10px 0',
        background: '#fff',
        width: '93%',
    },
}

export const AddToCartButtonGroup = {
    borderRadius: '0.5rem',
    border: '1px solid rgba(204, 204, 204, 0.50)',
}

export const ButtonRemoveIcon = {
    borderRight: 'none !important',
    minWidth: '30px !important',
}

export const ButtonQuantity = {
    borderRight: 'none !important',
    color: 'rgba(20, 20, 30, 1)',
}

export const AddToCartButton = {
    maxWidth: '300px',
    marginTop: "0 !important",
}
 
export const CarouselContainer={
    backgroundColor: '#F6F6F6',
    cursor: 'pointer',
    position: 'relative',
}

export const CarouselArrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 6,
    height: 6,
    cursor: 'pointer',
};

export const CarouselImage: CSSProperties = {
    borderRadius: '16px',
    aspectRatio: 'contain',
    objectFit: 'contain',
    maxHeight: '300px',
    width: '100%',
}


export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: '0.44rem !important',
        marginLeft: '0 !important',
        border: 0,
        borderRadius: '0.5rem',
        minWidth: '2.5rem',
        maxHeight: '2.5rem',
        [`&.${toggleButtonGroupClasses.groupedHorizontal}`]: {
            border: 0,
            borderRadius: '0.5rem',
        },
    },
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: '1px solid var(--Neutral-Grey-Grey---20, #ECECEC) !important',
    background: 'var(--Accent-White, #FFF)',
    flex: '0 1 auto',
    flexDirection: 'column',
    [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: 'var(--Yellow-Yellow---10, #FFFBE6) !important',
        border: '1px solid var(--Yellow-Yellow---100, #FFD600) !important',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
    },
}));

export const OptionName = {
    color: '#333',
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFeatureSettings: "'dlig' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
}

export const SizeGuideStyle = {
    color: '#0A69D9',
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFeatureSettings: "'dlig' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    cursor: 'pointer',
}

export const OptionDiscountLabel= {
    background: '#009552',
    borderRadius: '4px',
    color: 'var(--Accent-White, #FFF)',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.6rem',
}

export const CustomRenderThumbStyle = {
    height: '100%',
    minHeight:'70px',
    maxHeight:'100px',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '0.5rem' 
}

export const ProductViewCarouselGrid = {
    position: 'sticky',
    top: '0rem',
    alignSelf: 'flex-start'
}

export const ProductViewTitleBox = {
    display: "flex",
    flexDirection: 'column',
    gap: '12px',
    '@media (max-width: 768px)': {
        gap: "4px",
        paddingTop: '1.25rem',
    }
}

export const ProductViewTitle = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '2rem',
    '@media (max-width: 768px)': {
        fontSize: '1rem',
        lineHeight: 'normal',
    }
}

export const SoldNumberBox = {
    display: "flex",
    gap: "4px",
    alignItems: "center"
}

export const SoldNumberText = {
    color: 'var(--Secoundary-Orange, #FA7921)',
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFeatureSettings: "'dlig' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '14px',
}

export const ViewNumberText = {
    color: '#141414',
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFeatureSettings: "'dlig' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
}

export const ProductViewDescription = {
    color: '#5E5E5E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    '@media (max-width: 768px)': {
        fontSize: '0.75rem',
    }
}



export const KnowMoreStyle = {
    color: '#000',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
}

export const SoldOutStyle = {
    overflow: 'hidden',
    color: '#14141E',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '1.25rem',
}

export const VariantSellingPrice = {
    overflow: 'hidden',
    color: '#14141E',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '1.25rem',
}

export const VariantOriginalPrice = {
    overflow: 'hidden',
    color: '#14141E',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.25rem',
    textDecoration: 'line-through',
}
    
export const DiscountBox = {
    padding: '2px 4px',
    background: '#FDDC2E',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
}

export const DiscountText = {
    color: '#14141E',
    fontSize: '0.75rem',
    fontFamily: 'Euclid-Circular-A-Medium',
    wordWrap: 'break-word',
}

export const AllTaxText = {
    color: '#241F1F',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

export const RewardPointsText = {
    fontSize: '14px',    
    display: 'flex',
    gap: '3px',
}

export const PointsStyle = styled('span')({
    color: '#6D39FF',
    fontWeight: 400,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
});

export const AvailableCouponBox = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
}

export const AvailableCouponText = {
    color: '#000',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px',
}

export const CollapseStyle = {
    border: '1px solid rgba(204, 204, 204, 0.50)',
    borderRadius: '12px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
}

export const AlterStyle = {
    width: '100%',
    alignItems: 'center',
    height: '44px',
    background: '#19B100',
    '.css-1pxa9xg-MuiAlert-message': {
        width: '100%',
    },
}

export const ViewCartBox = {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
}

export const ViewCartText = {
    color: '#FFF',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.28px',
}

export const ViewCartButton = {
    color: '#FFF',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: ' 0.28px',
    textDecorationLine: 'underline !important',
}

export const DescriptionText = {
    color: '#000',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '1rem',
    fontStyle: 'normal',
    lineHeight: 'normal',
    mb: 1,
}

export const DeliveryInfoTitle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
    color: '#000',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
}

export const DeliveryInfoText = {
    color: 'var(--Dark-Brown-Dark-Brown---90, #393637)',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontFamily: "Euclid-Circular-A-Medium",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    display: 'flex',
    gap: "4px",
}

export const DeliveryDay = {
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontFamily: "Euclid-Circular-A-Medium",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
}

export const PolicyContainer = {
    flexDirection: 'row',
    paddingTop: "22px",
    marginTop: "22px",
    borderTop: "1px dashed #D9D9D9",
    '@media (max-width: 768px)': {
        flexFlow: 'wrap',
        gap: '0.5rem',
        paddingTop: "15px",
        marginTop: "15px",
    }  
}

export const PolicyBox = {
    display: 'flex',
    padding: '6px 10px',
    alignItems: 'center',
    gap: '8px',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
        flex: '0 0 48%',
        flexDirection: 'row',
    }
}

export const PolicyText = {
    color: '#141414',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: 'normal',
}

export const SellerInfoContainer = {
    paddingTop: '1rem',
    paddingBottom: '22px',
    marginBottom: '22px',
    borderBottom: '1px dashed #D9D9D9',
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    '@media (max-width: 768px)': {
        paddingTop: 0,
        marginTop: "10px",
    } 
}

export const SellerInfoText = {
    overflow: 'hidden',
    color: '#000',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Semi-Bold',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
}

export const SellerInfoTitle  = {
    color: '#14141E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

export const Rating = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
    color: 'var(--Dark-Brown-Dark-Brown---90, #393637)',
    leadingTrim: 'both',
    textEdge: 'cap',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
}


export const ReviewButton = {
    overflow: 'hidden',
    color: '#0A69D9',
    leadingTrim: 'both',
    textEdge: 'cap',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    textDecorationLine: 'underline !important',
    height:"18px",
}
