import { Fragment } from "react";
import Layout from "../component/layout/layout";
import Loaction from "../view/storeLocation/location";

const StoreLocation = () => {
  return (
    <Fragment>
      <Layout>
        <Loaction />
      </Layout>
    </Fragment>
  );
};

export default StoreLocation;
