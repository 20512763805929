import { appActions } from '.';

export const getUserAction = (payload: any) => ({
    type: appActions.GET_USER,
    payload,
});

export const getRewardsAction = (payload: any) => ({
    type: appActions.GET_REWARDS,
    payload,
});

export const logout = (payload: any) => ({
    type: appActions.LOGOUT,
    payload,
});
