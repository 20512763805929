import Image from '../../../component/image/image';
import {
    Container,
    HeaderContainer,
    Title,
    HeightLightImage,
    HeaderImage,
    MainContainer,
    ProductHeaderImage,
} from './headerStyle';
import bookServiceHeightLight from '../../../assets/images/bookServiceHeightLight.svg';
import bookServiceHeader from '../../../assets/images/bookServiceHeader.png';
import { useLocation } from 'react-router-dom';
import useCheckMobileScreen from '../../../common/hooks/useCheckMobileScreen';
import productHeader from '../../../assets/images/productHeader.png';

const Header = () => {
    const isMobile = useCheckMobileScreen();
    const location = useLocation();
    const currentRoute = location.pathname;

    let title = 'Book a Service';
    if (currentRoute === '/confirm') {
        title = 'Confirmation';
    }
    if (currentRoute === '/location') {
        title = 'Our Store Location';
    }
    if (currentRoute === '/support') {
        title = isMobile ? 'Get Support!' : 'Have question? Get Support';
    }
    if (currentRoute === '/products') {
        title = isMobile ? 'Our Products' : 'Our Best Selling Products';
    }

    return (
        <Container>
            <MainContainer>
                <Title>{title}</Title>
                <HeaderContainer>
                    <HeightLightImage>
                        <Image src={bookServiceHeightLight} alt="image" />
                    </HeightLightImage>
                </HeaderContainer>
            </MainContainer>
            {!isMobile && currentRoute === '/' && (
                <HeaderImage>
                    <Image src={bookServiceHeader} alt="image" />
                </HeaderImage>
            )}
            {!isMobile && currentRoute === '/products' && (
                <ProductHeaderImage>
                    <Image src={productHeader} alt="image" />
                </ProductHeaderImage>
            )}
        </Container>
    );
};

export default Header;
