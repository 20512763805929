import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { appColor } from '../../../common/appColor/appColor';
import LoadingButton from '@mui/lab/LoadingButton';

export const Container = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  background-color: ${appColor.white};
  border-radius: 50px;
  padding: 50px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 2.25rem;
  @media (max-width: 768px) {
    padding: 1.25rem;
    gap: 1.75rem;
  }
`;

export const Buttons = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;
`;

export const CancelButton = styled(Button)`
  display: flex;
  width: 9.375rem;
  padding: 0.875rem 0.9rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  color: #231f20;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 112.5% */
  letter-spacing: 0.02rem;

  @media (max-width: 768px) {
    width: 6.375rem;
    font-size: 0.875rem;
  }
`;

export const ConfirmButton = styled(LoadingButton)`
  display: flex;
  padding: 0.875rem 0.9rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  background: var(--yellow-pri, #fddc2e);
  color: #231f20;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 112.5% */
  letter-spacing: 0.02rem;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
  :hover {
    background: var(--yellow-pri, #fddc2e);
  }
`;
