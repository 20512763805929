import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            border: '2px dashed #14141E',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: '#d9d9d9',
    zIndex: 1,
    color: '#fff',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        background: 'rgba(20, 20, 30, 1)',
    }),
    ...(ownerState.completed && {
        background: 'rgba(46, 196, 106, 1)',
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        // 1: <CustomIcon name="calender" />,
        1: <Icon icon="ri:calendar-schedule-line" />,
        2: <Icon icon="ri:handbag-line" />,
        3: <Icon icon="ri:check-line" />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const steps = ['Order requested', 'order confirmed', 'order delivered'];

export default function CustomStepper() {
    const isMobile = useCheckMobileScreen();
    return (
        <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
            {steps.map(label => (
                <Step key={label}>
                    <StepLabel
                        sx={{
                            color: 'var(--Black, #14141E)',
                            fontVariantNumeric: 'lining-nums proportional-nums',
                            fontFeatureSettings: "'dlig' on",
                            fontFamily: 'Euclid-Circular-A-Regular',
                            fontSize: '0.875rem',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            letterSpacing: '0.03125rem',
                            textTransform: 'capitalize',
                        }}
                        StepIconComponent={ColorlibStepIcon}
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
