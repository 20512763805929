// export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_NAME = 'Sniffy Nose';
export const ORG_NAME = 'Rikoouu Technologies';
export const ORG_SITE = 'https://sniffynose.com';
export const APP_MODE = process.env.REACT_APP_ENV;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_URL_OLD = process.env.REACT_APP_API_BASE_URL_OLD;
// export const DATA_URL = process.env.REACT_APP_BUCKET_URL;
// export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
// export const CURRENCY = 'USD';
// export const CURRENCY_SIGN = '$';
export const CURRENCY = 'INR';
export const CURRENCY_SIGN = '₹';
export const MIN_TABLE_ROW = 10;

export const SESSION_TIME = 30; // In Days
