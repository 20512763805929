import LinearProgress from '@mui/material/LinearProgress';

const Stepper = ({ progress, margin }: any) => {
  return (
    <LinearProgress
      sx={{ width: '100%', backgroundColor: '#E8E8E8', margin: { margin } }}
      variant="determinate"
      value={progress}
    />
  );
};

export default Stepper;
