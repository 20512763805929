import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const IndianNumber = {
    color: '#000',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    borderRight: "1px solid #D9D9D9",
    paddingRight: "12px",
    marginRight: "5px",
}

export const WelcomeText = {
    color: 'var(--Black, #14141E)',
    fontFamily: 'Cambon-Bold',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
}

export const InputField = {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor:
            'rgba(20, 20, 30, 1)',
    },
}


export const UserLoginModalStyle = {
    background: '#FFF',
    borderRadius: '24px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '900px',
    height: "500px",
    boxShadow: 24,
    display: 'flex',
    '@media (max-width: 768px)': {
        width: '100%',
        height: "100vh",
        borderRadius: 0,
        position: "fixed",
        flexDirection: "column",
    },
};

export const UserLoginModal = {
    padding: '2.5rem',
    position: 'unset',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
        position: 'absolute',
        bottom:  0,
        background: '#fff',
        width: '100%',
    },
}

export const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '454px',
    background: '#FFF',
    boxShadow: 24,
    borderRadius: '24px',
    padding: '2.5rem',
    '@media (max-width: 768px)': {
        width: '90%',
  },
};


export const LogoBox = {
    display: 'flex',
    justifyContent: 'center',
};

export const OtpInfo = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '@media (max-width: 768px)': {
        width: '100%',
    },
};

export const UserLoginPhoneInfo = {
    color: '#5E5E5E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    
};

export const PhoneInfo = {
    color: 'var(--Black, #14141E)',
    textAlign: 'center',
    fontFamily: 'Cambon-Bold',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
    width: '20rem',
}

export const SpanInfo = {
    color: '#5E5E5E',
    textAlign: 'center',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const ResendBox = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
};

export const ResendSpan = {
    color: '#5C6370',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
};

export const ResendButton = {
    color: '#000',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
};


export const DisclaimerText = {
    color: '#484D56',
    textAlign: 'center',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.33px',
}

export const TermAndPolicy = {
    color: '#1059E9',
    textAlign: 'center',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.33px',
}

export const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#1059E9 !important',
}));

export const CloseBox = {
    position: 'absolute',
    right: '15px',
    top: '15px',
    cursor: 'pointer',
};

export const ModalTitle = {
    position: 'absolute',
    left: '20px',
    top: '15px',
    fontSize: '28px',
    fontWeight: '500',
    lineHeight: '36px',
    fontFamily: 'Cambon-Medium',
};

export const ChangePhoneBox = {
    fontSize: '14px',
    color: '#0A69D9',
    textDecorationLine: 'underline',
    cursor: 'pointer',
};

export const ChangePhoneIcon = {
    minWidth: 'auto',
    color: '#0A69D9',
    padding: '6px 4px',
};