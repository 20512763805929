import { Tooltip, tooltipClasses, TooltipProps, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import WhatsApp from '../assets/icons/tooltip-whatsapp.svg';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';

const linkStyle: React.CSSProperties = {
    position: 'fixed',
    right: '25px',
    zIndex: 1100,
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    zIndex: 1100,
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        borderRadius: 8,
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginRight: '5px !important',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#fff',
    },
}));

const StyledLink = styled(Link)(({ theme }) => ({
    color: '000',
    textDecoration: 'none',
    '&:hover': {
        color: '#133ff1',
    },
}));

export default function CustomTooltipComponent() {
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const currentRoute = location.pathname;
    const isMobile = useCheckMobileScreen();

    let title = 'Let’s Chat';
    if (currentRoute === '/products' || currentRoute === '/') {
        title = 'Did not find the product? Let’s Chat';
    }

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.innerHeight + window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight;

            if (scrollTop >= scrollHeight - 340) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const link =
        'https://api.whatsapp.com/send/?phone=+919359939912&text=Hey!%20Can%20you%20give%20me%20a%20paw-some%20assist?%20My%20human%20needs%20a%20hand!&type=phone_number&app_absent=0';

    return (
        <>
            {currentRoute.includes('order') ? (
                ''
            ) : (
                <CustomTooltip
                    title={
                        !isMobile && (
                            <React.Fragment>
                                <StyledLink to={link} target="_blank">
                                    {title}
                                </StyledLink>
                                <IconButton
                                    onClick={handleClose}
                                    sx={{
                                        color: '#000',
                                        margin: '-15px -10px 15px -20px',
                                        '&:hover': {
                                            backgroundColor: 'unset',
                                        },
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                            </React.Fragment>
                        )
                    }
                    placement="left"
                    open={open}
                >
                    <StyledLink
                        to={link}
                        target="_blank"
                        style={{
                            ...linkStyle,
                            bottom: currentRoute === '/order' ? '60px' : '40px',
                            width: isMobile ? '40px' : '60px',
                            height: isMobile ? '40px' : '60px',
                        }}
                        aria-label="Chat with us on WhatsApp"
                    >
                        <img
                            src={WhatsApp}
                            alt="WhatsApp"
                            style={{ width: '100%', height: '100%' }}
                        />
                    </StyledLink>
                </CustomTooltip>
            )}
        </>
    );
}
