import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import couponGroup from '../../assets/images/couponGroup.svg';
import star from '../../assets/images/star.svg';
import { CloseBox, LogoBox, ModalStyle, OtpInfo, PhoneInfo, SpanInfo } from '../auth/LoginStyle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CouponCodeStyle } from './CouponStyle';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { selectErrorMessage } from '../../service/selectors/cartSelectors';

export default function RedeemSuccessModal({
    handleClose,
    openModal,
    couponData,
    isShowButtons,
}: any) {
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);
    const isFailed = useSelector(selectErrorMessage);
    const handleCopy = () => {
        navigator.clipboard.writeText(couponData?.code).then(() => {
            setCopied(true);

            setTimeout(() => {
                setCopied(false);
            }, 2000);
        });
    };

    function formatDate() {
        const date = couponData?.expirationDate ? new Date(couponData?.expirationDate) : new Date();

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        // const targetDate = new Date(expirationDate);

        // Current date (you might want to use a fixed current date for consistent results)
        const currentDate = new Date();

        // Calculate the difference in months
        const monthDifference = getMonthDifference({ startDate: currentDate, endDate: date });

        return (
            <span color="textSecondary">
                Coupon expire in {monthDifference} months at {day} {month}, {year}
            </span>
        );
    }

    // Function to calculate the difference in months
    const getMonthDifference = ({ startDate, endDate }: any) => {
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    };

    return (
        <Box>
            <Modal
                disableEscapeKeyDown
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={({ children, ...props }) => (
                    <Box
                        {...props}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {children}
                    </Box>
                )}
            >
                <Fade in={openModal}>
                    <Box sx={ModalStyle}>
                        <Grid container gap={'1.5rem'}>
                            <Grid item xs={12} sx={LogoBox}>
                                <Box sx={CloseBox}>
                                    <CloseIcon
                                        sx={{ color: 'rgba(9, 18, 31, 1)' }}
                                        onClick={handleClose}
                                    />
                                </Box>
                                <Box position={'relative'}>
                                    <Grid display={'flex'} sx={{ justifyContent: 'center' }}>
                                        <img src={couponGroup} />
                                        <img
                                            src={star}
                                            style={{
                                                position: 'absolute',
                                                top: '50',
                                                right: '10',
                                                left: '10',
                                            }}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Box sx={{ ...OtpInfo, alignItems: 'center' }}>
                                    <Typography sx={PhoneInfo}>
                                        Woohoo! Your Coupon is Successfully Rewarded{' '}
                                        {!isShowButtons && !isFailed && 'and Applied'}
                                    </Typography>
                                    {isShowButtons && (
                                        <Typography sx={SpanInfo}>
                                            Find amazing products and apply your coupon code to
                                            enjoy happy shopping!
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                            {isShowButtons && (
                                <>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            width: '374px',
                                            alignItems: 'center',
                                            gap: '14px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: 'var(--Cart, 1px)',
                                                flex: '1 0 0',
                                                background:
                                                    'var(--Neutral-Grey-Grey---30, #D9D9D9)',
                                            }}
                                        ></Box>
                                        <Typography sx={{ ...SpanInfo, fontWeight: '600' }}>
                                            YOUR COUPON CODE
                                        </Typography>{' '}
                                        <Box
                                            sx={{
                                                height: 'var(--Cart, 1px)',
                                                flex: '1 0 0',
                                                background:
                                                    'var(--Neutral-Grey-Grey---30, #D9D9D9)',
                                            }}
                                        ></Box>
                                    </Grid>

                                    <Box
                                        sx={{
                                            ...CouponCodeStyle,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: 'bold', letterSpacing: 2 }}
                                            textAlign={'center'}
                                        >
                                            {couponData.code || ''}
                                        </Typography>

                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                right: '45px',
                                            }}
                                        >
                                            {copied ? (
                                                'Copied'
                                            ) : (
                                                <ContentCopyIcon
                                                    onClick={handleCopy}
                                                    sx={{ color: '#1059E9' }}
                                                />
                                            )}
                                        </IconButton>
                                    </Box>

                                    <Grid
                                        item
                                        xs={12}
                                        display={'flex'}
                                        sx={{ justifyContent: 'center' }}
                                    >
                                        <InfoOutlinedIcon
                                            sx={{
                                                color: '#5E5E5E',
                                                fontSize: '16px',
                                                marginTop: '2px',
                                            }}
                                        />
                                        <Typography sx={{ ...SpanInfo, marginLeft: '2px' }}>
                                            {formatDate()}
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            {isShowButtons ? (
                                <Grid item xs={12} display={'flex'} gap={2} mt={2}>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        size="large"
                                        fullWidth
                                        onClick={e =>
                                            navigate(
                                                `/products?coupon_code=${couponData.code || ''}`
                                            )
                                        }
                                    >
                                        View Products
                                    </Button>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={e => navigate('/profile/coupons')}
                                    >
                                        My coupons
                                    </Button>
                                </Grid>
                            ) : (
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClose}
                                >
                                    Got It
                                </Button>
                            )}
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}
