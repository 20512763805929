import { combineReducers } from "redux";
import { commonReducer } from "./common/commonReducer";
import cartReducer from "./common/cartReducer";
import userReducer from "./common/userReducer";


const appReducer = combineReducers({
    commonReducer,
    cart: cartReducer,
    user: userReducer
});

export const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
}