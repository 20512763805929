import { put, call } from 'redux-saga/effects';
import { appActions } from '../../action';
import { appApi } from '../../api';

export function* sagaEngine({ navigate, type, payload }: any): any {
    try {
        yield put({ type: `${appActions[type]}_START` });
        const response = yield call(appApi[type], payload);

        if (response?.data?.status === 401) {
            yield put({ type: `${appActions[type]}_FAIL` });
            navigate('/login');
        }

        if (response) {
            yield put({ type: `${appActions[type]}_SUCCESS`, payload: response.data });
            payload?.callBack?.(response);
        }
    } catch (error: any) {
        payload?.errorCallBack?.(error);
        yield put({
            type: `${appActions[type]}_FAIL`,
            message: error.message || 'Something went wrong, Please try again.',
        });
    }
}
