import request from './request';

export function getUser(params?: any) {
    return request({
        url: `users/me`,
        method: 'get',
        params,
    });
}
export function createUserAddress(data?: any) {
    return request({
        url: `addresses`,
        method: 'post',
        data,
        headers: localStorage.getItem('tempAccessToken')
            ? {
                  requiresTempAccessToken: true,
              }
            : { requiresTempAccessToken: false },
    });
}
