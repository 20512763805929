import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  padding: 50px;
  top: 40px;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 15px;
    top: 10px;
  }
`;

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  min-height: 91px;
  @media (max-width: 768px) {
    min-height: auto;
  }
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 11.8125rem;
  }
`;

export const Title = styled(Box)`
  color: #fff;
  font-family: Cambon-Medium;
  font-size: 3rem;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const HeightLightImage = styled(Box)`
  width: 20.93rem;
  height: 1.78156rem;
  flex-shrink: 0;

  @media (max-width: 768px) {
    height: 0.733rem;
    width: 11.93rem;
  }
`;

export const HeaderImage = styled(Box)`
  width: 17.43713rem;
  height: 8.90225rem;
  flex-shrink: 0;
  right: 5rem;
  position: absolute;
  top: 0.4rem;
  @media (max-width: 768px) {
    width: 6.86588rem;
    height: 4.00144rem;
    top: 4.3rem;
    right: 1rem;
    transform: rotate(1.9deg);
  }
`;

export const ProductHeaderImage = styled(Box)`
  position: absolute;
  right: 5.56rem;
  z-index: 1;
  @media (min-width: 1440px) {
    right: 10%;
  }
  @media (min-width: 1800px) {
    right: 20%;
  }
`;
