export const AllRewards = [
    {
        isEligible: false,
        is_active: true,
        combo: false,
        items: [],
        inspiration: false,
        name: 'Enjoy a 10% discount when you spend ₹599 or more on any grooming service.',
        points_value: 5000,
        redeem_type: 'discount_off',
        applies_on: 'all',
        discount_off: 5,
        _id: '66aca90dc7114ca91a632caa',
        created_at: '2024-08-02T09:38:21.376Z',
        updated_at: '2024-08-02T09:38:21.376Z',
    },
    {
        isEligible: false,
        is_active: true,
        combo: false,
        items: [
            {
                name: 'GROOMING ',
            },
        ],
        inspiration: false,
        name: 'Enjoy a 10% discount when you spend ₹599 or more on any grooming service.',
        points_value: 7500,
        redeem_type: 'discount_off',
        applies_on: 'single',
        discount_off: 10,
        _id: '66aca90dc7114ca91a632cad',
        created_at: '2024-08-02T09:38:21.437Z',
        updated_at: '2024-08-02T09:38:21.437Z',
    },
    {
        isEligible: false,
        is_active: true,
        combo: false,
        items: [],
        inspiration: false,
        name: 'Get an extra 10% off on your purchase, when you spend ₹2000 or more, maximum discount of ₹200',
        points_value: 10000,
        redeem_type: 'discount_off',
        applies_on: 'all',
        discount_off: 10,
        _id: '66aca90dc7114ca91a632cb0',
        created_at: '2024-08-02T09:38:21.493Z',
        updated_at: '2024-08-02T09:38:21.493Z',
    },
    {
        isEligible: false,
        is_active: true,
        combo: false,
        items: [
            {
                name: 'GROOMING ',
            },
        ],
        inspiration: false,
        name: 'Enjoy a 20% discount when you spend ₹699 or more on any grooming service.',
        points_value: 12500,
        redeem_type: 'discount_off',
        applies_on: 'single',
        discount_off: 20,
        _id: '66aca90dc7114ca91a632cb3',
        created_at: '2024-08-02T09:38:21.555Z',
        updated_at: '2024-08-02T09:38:21.555Z',
    },
    {
        isEligible: false,
        is_active: true,
        combo: false,
        items: [],
        inspiration: false,
        name: 'Get an extra 10% off on your purchase, when you spend ₹4000 or more, maximum discount of ₹500.',
        points_value: 15000,
        redeem_type: 'discount_off',
        applies_on: 'all',
        discount_off: 10,
        _id: '66aca90dc7114ca91a632cb6',
        created_at: '2024-08-02T09:38:21.625Z',
        updated_at: '2024-08-02T09:38:21.625Z',
    },
];
