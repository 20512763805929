import { Collapse, FormControlLabel, Radio } from '@mui/material';
import {
    AddressDetailsRadioStyle,
    GroomingAddress,
    GroomingAddressBorder,
    GroomingAddressDetails,
} from '../view/bookService/addressDetails/addressStyle';
import Image from './image/image';
import Group from '../assets/images/Group.svg';

function PickupAddress({
    values,
    setFieldValue,
    availableAddresses,
    selectedAddressId,
    setSelectedAddressId,
}: any) {
    const handleAddressChange = (e: any) => {
        setSelectedAddressId((pre: any) => {
            return { ...pre, selectedAddressId: e.target.value };
        });
    };

    return (
        <>
            {availableAddresses.length > 0 && (
                <>
                    <Collapse in={values.is_pickup}>
                        <GroomingAddress mt={2.5}>
                            {availableAddresses.map((item: any, i: any) => {
                                const { address1, address2, city, state, pinCode, country } = item;
                                const fullAddress = [
                                    address1,
                                    address2,
                                    city,
                                    state,
                                    pinCode,
                                    country,
                                ]
                                    .filter(Boolean)
                                    .join(', ');

                                return (
                                    <GroomingAddressDetails key={i} sx={GroomingAddressBorder}>
                                        <Image src={Group} alt="image" />
                                        <FormControlLabel
                                            value={item.id}
                                            control={
                                                <Radio
                                                    checked={selectedAddressId === item.id}
                                                    onChange={e => {
                                                        setFieldValue('address.id', item.id);
                                                        handleAddressChange(e);
                                                    }}
                                                    sx={AddressDetailsRadioStyle}
                                                />
                                            }
                                            label={fullAddress}
                                            labelPlacement="start"
                                            componentsProps={{
                                                typography: {
                                                    color: '#14141E',
                                                    fontFamily: 'Euclid-Circular-A-Regular',
                                                    fontSize: '0.875rem !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                },
                                            }}
                                        />
                                    </GroomingAddressDetails>
                                );
                            })}
                        </GroomingAddress>
                    </Collapse>
                </>
            )}
        </>
    );
}

export default PickupAddress;
