import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  box-sizing: border-box;
  width: 65.5rem;
  height: 15.5rem;
  flex-shrink: 0;
  border-radius: 2rem;
  background: #f8f4f1;
  padding-left: 3rem;
  display: flex;
  gap: 4rem;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding-left: 1rem;
    gap: 0;
  }
`;

export const Title = styled(Box)`
  color: #333;
  font-family: Euclid-Circular-A-Regular;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 3rem;
  @media (max-width: 768px) {
    word-break: break-word;
    font-size: 1.25rem;
    padding-top: 1rem;
  }
`;

export const ContactText = styled(Box)`
  color: var(--Black, #14141e);
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 133.333% */
  padding-top: 1.125rem;
  @media (max-width: 768px) {
    margin-bottom: 0.38rem;
    font-size: 0.75rem;
    padding-top: 0.44rem;
  }
`;

export const ContactBoxs = styled(Box)`
  display: flex;
  gap: 0.5rem;
  padding-top: 0.5rem;
`;

export const WhatsappButton = styled(Box)`
  border-radius: 0.5rem;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1.125rem 0.625rem 0.875rem;
  @media (max-width: 768px) {
    margin-bottom: 0.38rem;
    padding: 0.25rem;
    width: 8rem;
  }
`;

export const WhatsappImage = styled(Box)`
  width: 1.125rem;
  height: 1.125rem;
`;

export const WhatsappNumber = styled(Box)`
  color: #231f20;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 112.5% */
  letter-spacing: 0.02rem;
  @media (max-width: 768px) {
    font-size: 0.75rem;
    line-height: 0.67313rem; /* 89.754% */
    letter-spacing: 0.015rem;
  }
`;

export const EmailButton = styled(Box)`
  border-radius: 0.5rem;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1.125rem 0.625rem 0.875rem;
  @media (max-width: 768px) {
    max-width: 12rem;
    margin-bottom: 0.38rem;
    padding: 0.25rem;
    word-break: break-word;
  }
`;

export const EmailImage = styled(Box)`
  width: 1.125rem;
  height: 1.125rem;
`;

export const EmailId = styled(Box)`
  color: #231f20;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 112.5% */
  letter-spacing: 0.02rem;
  @media (max-width: 768px) {
    font-size: 0.75rem;
    line-height: 0.67313rem; /* 89.754% */
    letter-spacing: 0.015rem;
  }
`;

export const BookServiceFooterImage = styled(Box)`
  width: 22.8125rem;
  height: 22.8125rem;
  flex-shrink: 0;
  margin-top: -11.3rem;
  margin-left: -14rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-left: 0rem;
    // margin-top: -1.15rem;
    // margin-left: -1rem;
  }
`;

export const BookServiceFooterImg = styled('img')`
  @media (max-width: 768px) {
    width: 11.31819rem;
    height: 11.59094rem;
  }
`;
