import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React from 'react';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import { Heading } from '../view/order/OrderStyles';
import AddAddress from './AddAddress';
import PickupAddress from './PickupAddress';
import { Field } from 'formik';
import {
    AddressDetailsRadioStyle,
    errorStyle,
} from '../view/bookService/addressDetails/addressStyle';

function AddressDetails({
    touched,
    errors,
    values,
    setFieldValue,
    setAddressAndPaymentData,
    availableAddresses,
    selectedAddressId,
    setSelectedAddressId,
    phoneNumberRef,
}: any) {
    const isMobile = useCheckMobileScreen();

    return (
        <Grid
            container
            spacing={isMobile ? 0 : '3rem'}
            gap={isMobile ? '1.25rem' : 0}
            m={0}
            width={'100%'}
        >
            {!isMobile && (
                <Grid xs={12} p={'0 15px 8px'}>
                    <Heading>Shipping Details</Heading>
                </Grid>
            )}
            <Grid xs={12} sx={{ padding: isMobile ? '0px 0px' : '0px 15px' }}>
                <RadioGroup
                    aria-label="delivery method"
                    name="pickup_location"
                    value={values.is_pickup ? 'pickup' : 'delivery'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = (event.target as HTMLInputElement).value;
                        if (value === 'delivery') {
                            setFieldValue('is_delivery', true);
                            setFieldValue('is_pickup', false);
                            setAddressAndPaymentData((pre: any) => {
                                return { ...pre, deliveryAtMyPlace: true };
                            });
                        } else if (value === 'pickup') {
                            setFieldValue('is_delivery', false);
                            setFieldValue('is_pickup', true);
                            setAddressAndPaymentData((pre: any) => {
                                return { ...pre, deliveryAtMyPlace: false };
                            });
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: isMobile ? '0' : '25px',
                        }}
                    >
                        <FormControlLabel
                            value="delivery"
                            control={<Radio sx={AddressDetailsRadioStyle} />}
                            label="Delivery at My Place"
                        />
                        <FormControlLabel
                            value="pickup"
                            control={<Radio sx={AddressDetailsRadioStyle} />}
                            label="Pickup from SniffyNose stores"
                        />
                    </Box>

                    <Grid container sx={{ gap: isMobile ? '20px' : '', marginTop: '10px' }}>
                        <Grid xs={12} md={6} spacing={2} paddingRight={isMobile ? '' : '1rem'}>
                            <Field
                                name="user.firstName"
                                label="Name"
                                required
                                size={isMobile ? 'small' : 'medium'}
                                as={TextField}
                                sx={{ ...errorStyle, width: '100%' }}
                                error={touched.user?.firstName && Boolean(errors.user?.firstName)}
                                helperText={touched.user?.firstName && errors.user?.firstName}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Field
                                name="user.phoneNumber"
                                label="Phone Number"
                                required
                                inputProps={{
                                    maxLength: 15,
                                    pattern: '[0-9]*',
                                    inputMode: 'numeric',
                                    onKeyPress: (event: any) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault(); // Prevents non-numeric characters
                                        }
                                    },
                                }}
                                inputRef={phoneNumberRef}
                                as={TextField}
                                type="tel"
                                size={isMobile ? 'small' : 'medium'}
                                error={
                                    touched.user?.phoneNumber && Boolean(errors.user?.phoneNumber)
                                }
                                fullWidth
                                sx={errorStyle}
                                helperText={touched.user?.phoneNumber && errors.user?.phoneNumber}
                            />
                        </Grid>
                    </Grid>

                    <PickupAddress
                        values={values}
                        setFieldValue={setFieldValue}
                        availableAddresses={availableAddresses}
                        selectedAddressId={selectedAddressId}
                        setSelectedAddressId={setSelectedAddressId}
                    />
                    <AddAddress errors={errors} touched={touched} values={values} />
                </RadioGroup>
            </Grid>
        </Grid>
    );
}

export default AddressDetails;
