import { Box, Grid, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { Heading } from './OrderStyles';
import { PointsStyle, RewardPointsContainer } from '../cart/CartStyle';
import { Link } from 'react-router-dom';
import CartProductList from '../../component/CartProductList';
import PointsIcon from '../../assets/images/points.svg';
import { useSelector } from 'react-redux';
import { selectCartItems } from '../../service/selectors/cartSelectors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@emotion/styled';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
        display: 'inline-flex',
        padding: '6px 12px 6px 10px',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '12px',
        color: 'var(--Accent-White, #FFF)',
        textAlign: 'center',
        fontFamily: 'Euclid-Circular-A-Regular',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        cursor: 'pointer',
    },
}));

function ProductList() {
    const isMobile = useCheckMobileScreen();
    const cartItems = useSelector(selectCartItems);
    const earnableRewardPoints = Math.floor(cartItems?.earnableRewardPoints || 0);

    return (
        <>
            {!isMobile && (
                <Grid xs={12}>
                    <Heading>Product Details</Heading>
                </Grid>
            )}
            <Grid xs={12} sx={{ marginTop: '1rem' }}>
                <Box
                    sx={{
                        borderRadius: isMobile ? '' : '1rem',
                        border: isMobile ? '' : '1px solid rgba(204, 204, 204, 0.50)',
                    }}
                >
                    {earnableRewardPoints > 0 && (
                        <RewardPointsContainer
                            style={{ borderRadius: '1rem 1rem 0 0', padding: '12px 9px' }}
                        >
                            <img src={PointsIcon} alt="" />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: '14px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    Earn{' '}
                                    <PointsStyle>
                                        {earnableRewardPoints.toLocaleString('en-IN')}
                                    </PointsStyle>{' '}
                                    SN points with this purchase{' '}
                                </Typography>
                                <Link
                                    to="#"
                                    onClick={() => window.open('/rewards', '_blank')}
                                    style={{ height: '1rem' }}
                                >
                                    <BootstrapTooltip title={'Learn more'} placement="top">
                                        <InfoOutlinedIcon
                                            sx={{
                                                fontSize: '1rem',
                                                color: '#0A69D9',
                                            }}
                                        />
                                    </BootstrapTooltip>
                                </Link>
                            </Box>
                        </RewardPointsContainer>
                    )}

                    <CartProductList height={'auto'} padding={''} />
                </Box>
            </Grid>
        </>
    );
}

export default ProductList;
