import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
    IconButton,
    Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { forwardRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import ProductView from './ProductView';

export const getProductFromState = (Arr: any, id: any) => {
    const foundProduct = Arr.find((product: any) => product.id === id);
    return foundProduct || [];
};

export const updateCartItem = (cart: any, id: number, updatedItem: any) => {
    const updatedCart = cart.map((item: any) =>
        item.id === id ? { ...item, ...updatedItem } : item
    );
    return updatedCart;
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
    isOpen: boolean;
    productData: any;
    setOpenModal: any;
    productOptions: any;
    resetQueryFilter: any;
};

export default function ProductDetailsMod({
    isOpen,
    productData,
    setOpenModal,
    productOptions,
}: Props) {
    const [searchParams] = useSearchParams();
    const defaultProduct = searchParams.get('product');

    const isMobile = useCheckMobileScreen();
    const [previewImage, setPreviewImage] = useState('');
    const [sizeChartImages, setSizeChartImages] = useState([]);

    const closeModal = () => {
        if (previewImage) {
            setPreviewImage('');
        } else if (sizeChartImages.length) {
            setSizeChartImages([]);
        } else {
            setOpenModal(false);

            // if URL there is a product id, let's redirect the user to home page.
            if (defaultProduct) {
                window.location.href = window.location.pathname;
            }
        }
    };

    if (isMobile) {
        return (
            <Drawer
                id={`d-mob${productData?.id}`}
                anchor={'bottom'}
                open={isOpen}
                PaperProps={{
                    sx: {
                        borderRadius: '1.25rem 1.25rem 0 0',
                    },
                }}
            >
                <Box display={'flex'} justifyContent={'end'}>
                    <IconButton
                        aria-label="close"
                        sx={{
                            position: 'sticky',
                            overflow: 'visible',
                            mr: '0.5rem',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon sx={{ color: 'rgba(9, 18, 31, 1)' }} onClick={closeModal} />
                    </IconButton>
                </Box>
                <Paper
                    sx={{
                        height: '85vh',
                        overflowY: 'auto',
                    }}
                    id="scroll-container"
                >
                    <ErrorBoundary>
                        {productData && (
                            <ProductView
                                productData={productData}
                                openModal={isOpen}
                                setOpenModal={setOpenModal}
                                setPreviewImage={setPreviewImage}
                                previewImage={previewImage}
                                productOptions={productOptions}
                                sizeChartImages={sizeChartImages}
                                setSizeChartImages={setSizeChartImages}
                            />
                        )}
                    </ErrorBoundary>
                </Paper>
            </Drawer>
        );
    } else {
        return (
            <Dialog
                id="d-desk"
                open={isOpen}
                TransitionComponent={Transition}
                PaperProps={{ sx: { borderRadius: '1.5rem' } }}
                sx={{
                    '.MuiDialog-paper': {
                        maxWidth: sizeChartImages.length ? '45%' : '70%',
                    },
                }}
                keepMounted
                fullWidth
                onClose={closeModal}
            >
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 14,
                        color: theme => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ color: 'rgba(9, 18, 31, 1)' }} />
                </IconButton>
                <ErrorBoundary>
                    {productData && (
                        <ProductView
                            productData={productData}
                            setOpenModal={setOpenModal}
                            setPreviewImage={setPreviewImage}
                            previewImage={previewImage}
                            productOptions={productOptions}
                            sizeChartImages={sizeChartImages}
                            setSizeChartImages={setSizeChartImages}
                        />
                    )}
                </ErrorBoundary>
            </Dialog>
        );
    }
}
