import { useQuery } from '@tanstack/react-query';
import { getCategories } from '../../service/api/products';

export default function useGetCategories() {
  return useQuery<any>({
    queryKey: ['categories'],
    queryFn: async () => {
        return await getCategories().then(({ data }) => {
            const { categories } = data;
            return [
                {
                    id: 'test-id-all',
                    name: 'All Products',
                    shortDescription: 'All Products',
                    description: '<p>All Products</p>',
                    type: 'PRODUCT',
                    status: 'active',
                    media: {
                        id: 'test-id-all-media',
                        url: 'https://sniffynose.s3.ap-south-1.amazonaws.com/media/Eyzqj73hmabZbfsBeJE9e_1721201070044_allproductcatgoryimage.png',
                        type: 'png',
                    },
                },
                ...categories,
            ];
        });
    },
    refetchOnMount: false,
  });
}