import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Image from '../../component/image/image';
import confirmPage from '../../assets/images/consfirmPage.png';
import backArrow from '../../assets/images/backArrow.svg';
import service from '../../assets/images/service.svg';
import assigned from '../../assets/images/assigned.svg';
import complete from '../../assets/images/complete.svg';
import { useState, useEffect, ReactElement } from 'react';
import {
    Container,
    ImageBox,
    MainContainer,
    TextBox,
    ImageTextOne,
    ImageTextTwo,
    ImageStyle,
    StepperBox,
    BackButton,
    StepIconRoot,
    ColorlibConnector,
} from './confirmStyle';
import Header from '../bookService/serviceHeader/header';
import PetDetails from './petDetails';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';

const StepIcon = (props: any) => {
    const { active } = props;

    const icons: { [index: string]: ReactElement } = {
        1: <Image src={service} alt="image" />,
        2: <Image src={assigned} alt="image" />,
        3: <Image src={complete} alt="image" />,
    };

    return <StepIconRoot ownerState={{ active }}>{icons[String(props.icon)]}</StepIconRoot>;
};

const steps = ['Service Requested', 'Assigned', 'Complete'];

const Confirm = () => {
    const [serviceDetails, setServiceDetails] = useState<any>({});
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        try {
            const storedData = localStorage.getItem('serviceDetails');
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                setServiceDetails(parsedData);
                localStorage.removeItem('serviceDetails');
            }
        } catch (error) {
            console.error('Error accessing or parsing data from localStorage:', error);
        }
    }, []);

    const handleBackToHome = () => {
        window.location.href = '/';
    };

    return (
        <>
            {!isMobile && <Header />}
            <MainContainer>
                <Container>
                    <ImageBox>
                        <Image src={confirmPage} alt="image" style={ImageStyle} />
                        <TextBox>
                            <ImageTextOne>
                                Thank you!{' '}
                                {serviceDetails?.contact?.firstName +
                                    ' ' +
                                    serviceDetails?.contact?.lastName}
                            </ImageTextOne>
                        </TextBox>
                    </ImageBox>
                    <Box sx={StepperBox}>
                        <Stepper
                            activeStep={0}
                            alternativeLabel
                            sx={{ width: '100%' }}
                            connector={<ColorlibConnector />}
                        >
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <PetDetails serviceDetails={serviceDetails} isMobile={isMobile} />
                </Container>
                <BackButton onClick={handleBackToHome}>
                    <Image src={backArrow} alt="image" />
                    Back To Home
                </BackButton>
            </MainContainer>
        </>
    );
};

export default Confirm;
