import { Box, DialogContent, DialogTitle, Grid, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

export default function SizeChartGuide({ sizeChartImages }: any) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (_: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <DialogTitle
                sx={{
                    borderBottom: '1px solid #ECECEC',
                    background: '#F6F6F6',
                    color: 'var(--Black, #14141E)',
                    fontFamily: 'Cambon-Medium',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '36px',
                }}
            >
                Size Guide
            </DialogTitle>

            <DialogContent
                sx={{
                    padding: 0,
                    gap: '2rem',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        {sizeChartImages?.map((image: any, index: any) => (
                            <Tab
                                key={index}
                                label={image.label}
                                sx={{
                                    width: '50%',
                                    maxWidth: '50%',
                                    color: 'var(--Black, #14141E) !important',
                                    fontFamily:
                                        selectedTab === index
                                            ? 'Euclid-Circular-A-Medium'
                                            : 'Euclid-Circular-A-Regular',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: selectedTab === index ? 500 : 400,
                                    lineHeight: '20px',
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>
                <Grid container>
                    <Grid sx={{ top: '0rem', textAlign: 'center', width: '100%' }}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <img
                                src={sizeChartImages[selectedTab]?.mediaUrl?.url}
                                alt={sizeChartImages[selectedTab].label}
                                style={{
                                    borderRadius: '16px',
                                    maxWidth: '100%',
                                    width: '75%',
                                    height: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}
