import { FormControlLabel, Grid, Radio, Stack, Typography } from '@mui/material';
import { Heading, PaymentDetailsBox, PayOnDelivery, PayOnDeliverySpan } from './OrderStyles';
import Icon from '../../component/icon';
import { AddressDetailsRadioStyle } from '../bookService/addressDetails/addressStyle';

function PaymentMethod({ paymentMethod, setPaymentMethod }: any) {
    return (
        <>
            <Grid xs={12}>
                <Heading>Payment options</Heading>
            </Grid>

            <Grid xs={12} sx={{ paddingTop: '0px', marginTop: '1rem' }}>
                <Stack sx={PaymentDetailsBox}>
                    <Stack
                        sx={{
                            padding: '1rem',
                            gap: '18px',
                        }}
                    >
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Icon name={'Pay-on-delivery'} />
                            <FormControlLabel
                                checked={paymentMethod === 'POD'}
                                onChange={e => {
                                    setPaymentMethod((pre: any) => {
                                        return { ...pre, paymentMethod: 'POD' };
                                    });
                                }}
                                value="pay_on_delivery"
                                control={
                                    <Radio
                                        sx={{
                                            ...AddressDetailsRadioStyle,
                                            padding: '0',
                                        }}
                                    />
                                }
                                label
                                sx={{ margin: '0' }}
                            />
                        </Stack>
                        <Stack gap={'8px'}>
                            <Typography sx={PayOnDelivery}>Pay on Delivery</Typography>
                            <Typography sx={PayOnDeliverySpan}>
                                Pay in cash or pay online to delivery partner
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
        </>
    );
}

export default PaymentMethod;
