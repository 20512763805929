import { useState } from 'react';

interface PaginationData {
        total: number;
        limit: number;
        page: number;
    };

const useCheckIsMoreDataToLoad = (data: PaginationData) => {
    const [hasMore, setHasMore] = useState(false);

    const manageLoadMore = () => {
        const isMoreProducts = data.total / data.limit;
        if (Math.ceil(isMoreProducts) === data.page) {
            setHasMore(false);
        } else if (Math.ceil(isMoreProducts) > data.page) {
            setHasMore(true);
        }
    };

    return [hasMore, setHasMore, manageLoadMore] as const;
};


export default useCheckIsMoreDataToLoad;
