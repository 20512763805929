import { getDealsLabel } from "./dealsNavigationLabel";


export const navItem = [
  {
    label: 'Products',
    icon: 'bag-fill-mob',
    path: '/products',
  },
  {
    label: 'Grooming',
    icon: 'scissors-fill-mob',
    path: '/service',
  },
  {
    label: 'Stores',
    icon: 'map-mob',
    path: '/location',
  },
  {
    label: 'Support',
    icon: 'media-mob',
    path: '/support',
  },
  {
    label: `${getDealsLabel()}`,
    icon: 'hot-deals',
    path: '/products/deals',
  }, 
  
];

export const desktopNavItem = [
  {
    label: '',
    icon: 'logo-yellow',
    path: '/',
  },
  {
    label: 'Products',
    icon: 'desktop-shopping-bag',
    path: '/products',
  },
  {
    label: 'Grooming',
    icon: 'desktop-scissors-fill',
    path: '/service',
  },
  {
    label: 'Stores',
    icon: 'desktop-map',
    path: '/location',
  },
  {
    label: 'Support',
    icon: 'desktop-media',
    path: '/support',
  },  
  {
    label: `${getDealsLabel()}`,
    icon: 'hot-deals',
    path: '/products/deals',
  },  
];
