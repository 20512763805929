import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

export const HighlightedText = styled('span')(({ theme }) => ({
    color: '.green',
    fontWeight: 'bold',
}));

export const StyledLink = styled(RouterLink)(({ theme }) => ({
    textDecoration: 'none',
    fontSize: '14px',
    color: '#0073e6',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}));

export const CouponCodeStyle = {
    textAlign: 'center',
    mt: 2,
    width: '100%',
    background: 'rgba(242, 242, 242, 0.20)',
    border: `1px dashed #D3D4D9`,
    p: 2,
    padding: '7px',
    margin: '0px',
    borderRadius: '12px',
};

export const ModalStyle = {
    background: '#FFF',
    borderRadius: '24px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '454px',
    boxShadow: 24,
    padding: '1.5rem',
    display: 'flex',
    '@media (max-width: 768px)': {
        width: '90%',
    },
};

export const ModalTitle = {
    position: 'absolute',
    left: '20px',
    top: '15px',
    fontSize: '28px',
    fontWeight: '500',
    lineHeight: '36px',
    fontFamily: 'Cambon-Semi-Bold',
    color: 'var(--Black, #14141E)',
};

export const LogoBox = {
    display: 'flex',
    justifyContent: 'center',
};

export const CloseBox = {
    position: 'absolute',
    right: '15px',
    top: '15px',
    cursor: 'pointer',
};

export const CardContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '@media (min-width: 769px)': {
        minHeight: '243px',
        maxHeight: '243px',
    },
};

export const ExpiredLabelStyle = {
    background: 'rgba(255, 78, 22, 0.10)',
    marginBottom: 'auto',
    borderRadius: '11px',
    fontSize: '14px',
    padding: '2px 8px 2px 8px',
    color: '#FF4E16',
};

export const CardChipStyle = {
    background: '#ffddaf',
    borderRadius: '4px',
    fontSize: '14.51px',
    fontWeight: '500',
    gap: '2px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
};
export const LogoStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    color: '#ff5733',
    width: '10px',
    height: '9px',
};

export const CouponStyle = {
    color: 'var(--Black, #14141E)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '12.514px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    paddingLeft: '7px',
    borderLeft: '1px dashed #C8B4A9',
};

export const ShopNowBtn = {
    height: '100%',
    width: '100%',
    padding: '11px 18px',
    background: 'rgba(10, 105, 217, 0.03)',
    color: '#0A69D9',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontFamily: 'Euclid-Circular-A-Regular',
    '&:hover': {
        background: 'rgba(10, 105, 217, 0.03)',
    },
};

export const CouponTitle = {
    color: 'var(--Black, #14141E)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '29px',
    letterSpacing: '-0.22px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const CartContainer = {
    marginBottom: '30px',
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.14)',
    borderRadius: '18px',
};

export const CartContextBox = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
};

export const TermsAndConditionBox = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '3px',
};

export const CouponDesc = {
    color: '#5E5E5E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const CouponExpired = {
    color: '#5E5E5E',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
};

export const ModalCouponTitle = {
    color: 'var(--Black, #14141E)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    marginBottom: '-10px',
};

export const OfferMainContainer = {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    padding: '12px 16px',
};

export const OfferContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
};

export const OfferTextBox = {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
};

export const CodeStyleForOffer = {
    overflow: 'hidden',
    color: 'var(--Accent-Black, #000)',
    textOverflow: 'ellipsis',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px',
};

export const AvailableTitleText = {
    color: 'var(--Accent-Black, #000)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
};

export const AvailableCouponCode = {
    color: '#7B7979',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px;',
};

export const OfferDes = {
    color: '#106E44',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
};

export const SpanStyle = {
    ...OfferDes,
    color: '#7B7979',
    fontSize: '14px',
};

export const AddMore = {
    color: '#FA7921',
    marginTop: '10px',
    fontSize: '14px',
};
