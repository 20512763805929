import { Box, Grid, useTheme } from '@mui/material';
import CustomHero, { HeroWrapper } from '../component/CustomHero';
import { PageWrapper } from './products';
import ErrorBoundary from '../component/ErrorBoundary';
import FooterBanner from '../component/FooterBanner';
import ConfirmationView from '../view/confirmation/ConfirmationView';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';
import Layout from '../component/layout/layout';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import ProfilePreview from '../view/profilePreview/ProfilePreview';

export default function OrderPage({ page }: any) {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();
    const { orderId } = useParams();
    return (
        <Fragment>
            <Layout>
                <Box sx={{ backgroundColor: theme.palette.background.default }}>
                    <HeroWrapper
                        sx={{
                            paddingTop: isMobile ? 0 : '3.5rem',
                            paddingBottom: isMobile ? 0 : '3.5rem',
                        }}
                    >
                        <CustomHero title={page ? `My Account` : `Confirmation`} />
                    </HeroWrapper>
                    <PageWrapper maxWidth={false}>
                        <ErrorBoundary>
                            {page ? (
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    sx={{
                                        paddingRight: isMobile ? null : '2.5rem',
                                        paddingLeft: isMobile ? null : '2.5rem',
                                    }}
                                >
                                    <Grid
                                        display={'flex'}
                                        gap={'30px'}
                                        maxWidth={'1440px'}
                                        width={'100%'}
                                        paddingTop={'2.5rem'}
                                    >
                                        {!isMobile && <ProfilePreview />}
                                        <ConfirmationView
                                            orderId={orderId ? orderId : ''}
                                            page={page}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <ConfirmationView orderId={orderId ? orderId : ''} page={page} />
                            )}
                        </ErrorBoundary>
                        <Box pb="5rem" pt="5rem">
                            <FooterBanner />
                        </Box>
                    </PageWrapper>
                </Box>
            </Layout>
        </Fragment>
    );
}
