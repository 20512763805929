import Image from "../../component/image/image";
import invitePageHeader from "../../assets/images/invitePageHeader.png";
import {
  HeaderContainer,
  InviteBanner,
  HeaderTextBox,
  InviteDec,
  TextBox,
  HeaderImage,
} from "./invitePageStyle";
import titleHeighLighter from "../../assets/images/titleHeighLighter.png";
import titleStar from "../../assets/images/titleStar.png";

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderImage>
        <Image src={invitePageHeader} alt="Header" />
      </HeaderImage>
      <HeaderTextBox>
        <Image src={titleStar} alt="image" />
        <TextBox>
          <InviteBanner>Invitation to </InviteBanner>
          <InviteDec>Jingle Paws Celebration</InviteDec>
        </TextBox>
        <Image src={titleHeighLighter} alt="image" />
      </HeaderTextBox>
    </HeaderContainer>
  );
};

export default Header;
