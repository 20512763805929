export const checkCouponExpired = ({ expirationDate }: { expirationDate: string | Date }) => {
    const couponExpirationDate = new Date(expirationDate || new Date());

    // Zero out the time components for the expiration date
    couponExpirationDate.setHours(0, 0, 0, 0);

    // Get the current date and zero out the time components
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Compare the dates and return the result
    return currentDate > couponExpirationDate;
};
