import { Icon } from '@iconify/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
} from '@mui/material';
import { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

class MyErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { errorObj: '', errorInfo: '', hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
    this.setState({
      errorObj: error,
      errorInfo: errorInfo,
      hasError: true,
    });
    // return {errorObj: error}
    // console.log('ERR', error.toString(), errorInfo);
  }

  render() {
    const { navigate } = this.props;
    if (this.state.hasError) {
      // return <>error</>
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '5rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div className="text-center">
              <Icon icon="ri:emotion-sad-line" fontSize={100} />
            </div>
            <h4 className="text-center my-3">Something went wrong.</h4>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  navigate('/', { replace: true });
                }}
              >
                Reload & back to Dashboard
              </Button>
            </div>
            <h6>Please Contact the Support imediently</h6>
          </div>
          <Alert severity="error" color="error" >
            <AlertTitle>Error!</AlertTitle>
            {this.state.errorObj.toString()}
          </Alert>
          <div style={{ marginTop: '1rem' }}>
            <Accordion expanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Show Stack Trace</AccordionSummary>
              <AccordionDetails>
                <code dangerouslySetInnerHTML={{ __html: this.state.errorInfo.componentStack }} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary = props => {
  const navigate = useNavigate();
  return <MyErrorBoundary {...props} navigate={navigate} />;
};

export default ErrorBoundary;
