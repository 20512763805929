import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface MyStyledComponentProps {
  boxShadow?: string;
  gap?: string;
  borderRadius?: string;
  background?: string;
}

export const Container = styled("div")<MyStyledComponentProps>((props) => ({
  display: "flex",
  gap: props.gap || "0.7rem",
  flexDirection: "row",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: props.gap || "0.88rem",
  },
}));

export const Cards = styled("div")<MyStyledComponentProps>((props) => ({
  display: "flex",
  padding: "1.5rem 1.5rem 1.75rem 1.5rem",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.75rem",
  flex: "1 0 0",
  width: "290px",
  height: "158px",
  borderRadius: props.borderRadius,
  background: props.background,
  boxShadow: props.boxShadow,

  "@media (max-width: 768px)": {
    flexDirection: "row",
    padding: "1.12rem 1.5rem 1.12rem 0.75rem",
    gap: "1rem",
    width: "21rem",
  },
}));

export const CardInfoBox = styled('div')`
  display: flex;
  flex-direction: column; 
  align-items: center; 
  gap: 2px; 
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`

export const NavCard = styled(Box)`
  padding: 2rem 1rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const NavBorder = styled(Box)`
  width: var(--Cart, 0.0625rem);
  height: 7rem;
  flex-shrink: 0;
  background: #e6e6e6;
  @media (max-width: 768px) {
    width: 100%;
    height: 1px;
  }
`;

export const CardTitle = styled(Box)`
  color: var(--Black, #14141e);
  text-align: center;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  @media (max-width: 768px) {
    text-align: start;
  }
`;

export const CardSpan = styled(Box)`
  font-family: Euclid-Circular-A-Regular;
  color: #747474;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CardsSupport = styled(Box)`
  display: flex;
  transform: rotate(6.737deg);
  padding: 0.1875rem 0.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid #fff;
  background: var(--yellow-sec, #fddc2e);
  color: #000;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    margin-top: -66px;
    right: 40px;
    position: absolute;
  }
`;
