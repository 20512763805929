import { Box, Stack, Typography } from '@mui/material';
import {
    DeliveryDay,
    DeliveryInfoText,
    DeliveryInfoTitle,
    PolicyBox,
    PolicyContainer,
    PolicyText,
} from './ProductStyle';
import Icon from '../icon';
import SellerInfo from './SellerInfo';

function DeliveryInfo() {
    return (
        <Box>
            <SellerInfo />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Typography sx={DeliveryInfoTitle}>Delivery & Service Information</Typography>
                <Box sx={{ display: 'flex', gap: '9px', alignItems: 'center' }}>
                    <Icon name="Delivery-Time" />
                    <Typography sx={DeliveryInfoText}>
                        Usually delivers in{'  '}
                        <Typography sx={DeliveryDay}>{'  '} 3 - 5 days</Typography>
                    </Typography>
                </Box>
            </Box>
            <Stack sx={PolicyContainer}>
                <Box sx={PolicyBox}>
                    <Icon name="COD" />
                    <Typography sx={PolicyText}>Pay on delivery</Typography>
                </Box>
                <Box sx={PolicyBox}>
                    <Icon name="fast-delivery" />
                    <Typography sx={PolicyText}>Super Fast Delivery</Typography>
                </Box>
                <Box
                    sx={{ ...PolicyBox, cursor: 'pointer' }}
                    onClick={() => window.open('/terms-conditions', '_blank')}
                >
                    <Icon name="easy-return" />
                    <Typography sx={{ ...PolicyText, textDecoration: 'underline' }}>
                        Easy Return and Exchange
                    </Typography>
                </Box>
                <Box sx={PolicyBox}>
                    <Icon name="secure" />
                    <Typography sx={PolicyText}>Secure Payments</Typography>
                </Box>
            </Stack>
        </Box>
    );
}

export default DeliveryInfo;
