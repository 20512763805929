import Header from '../bookService/serviceHeader/header';
import {
    CardsLocation,
    Container,
    HeightLightImage,
    StoreCards,
    Title,
    TitleBox,
    TitleBoxSpan,
} from './locationStyle';
import LocationInfo from '../locationInfo/locationInfo';
import StoreInfo from '../storeInfo/storeInfo';
import Image from '../../component/image/image';
import supportHeightLight from '../../assets/images/supportHeightLight.png';

const Loaction = () => {
    return (
        <>
            <Header />
            <Container>
                <StoreCards>
                    <StoreInfo
                        boxShadow="0px 2px 5px 0px rgba(123, 122, 137, 0.17)"
                        gap="2rem"
                        borderRadius=" 1.375rem"
                        background="#FFF"
                    />
                </StoreCards>
                <TitleBox>
                    <Title>Get Support!</Title>
                    <HeightLightImage>
                        <Image src={supportHeightLight} alt="heightLight" />
                    </HeightLightImage>
                    <TitleBoxSpan>
                        Need assistance? Contact us for any questions or concerns about our services
                        and products.
                    </TitleBoxSpan>
                </TitleBox>
                <CardsLocation>
                    <LocationInfo />
                </CardsLocation>
            </Container>
        </>
    );
};

export default Loaction;
