import { Box, Grid, useTheme } from '@mui/material';
import { Fragment } from 'react';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import Layout from '../../component/layout/layout';
import CustomHero, { HeroWrapper } from '../../component/CustomHero';
import { PageWrapper } from '../../pages/products';
import ErrorBoundary from '../../component/ErrorBoundary';
import CouponsList from './CouponsList';
import ProfilePreview from '../profilePreview/ProfilePreview';

export default function MyCoupons() {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();
    return (
        <Fragment>
            <Layout>
                <Box sx={{ backgroundColor: theme.palette.background.default }}>
                    <HeroWrapper
                        sx={{
                            paddingTop: isMobile ? 0 : '1.5rem',
                            paddingBottom: isMobile ? 0 : '1.5rem',
                        }}
                    >
                        <CustomHero title="My Account" />
                    </HeroWrapper>
                    <PageWrapper maxWidth={false}>
                        <ErrorBoundary>
                            <Grid
                                container
                                justifyContent={'center'}
                                sx={{
                                    paddingRight: isMobile ? null : '0.5rem',
                                    paddingLeft: isMobile ? null : '0.5rem',
                                }}
                            >
                                <Grid
                                    display={'flex'}
                                    gap={'30px'}
                                    maxWidth={'1440px'}
                                    width={'100%'}
                                    sx={{ paddingTop: isMobile ? '1.75rem' : '2.5rem' }}
                                >
                                    {!isMobile && <ProfilePreview />}
                                    <CouponsList />
                                </Grid>
                            </Grid>
                        </ErrorBoundary>
                    </PageWrapper>
                </Box>
            </Layout>
        </Fragment>
    );
}
