import axios from "axios";
import moment from "moment";

export const createGroomingRequest = (data: any) => {
    console.log(data)
    let contact = data.contact
    let pets = data.pets
    let location = data.address
    let params = {
        name: contact?.firstName +' '+ contact.lastName,
        phone: contact.phoneNumber,
        petData: pets.map((pet: any) => ({
                "name": pet.name || "NA",
                "breed": pet.petBreed.name || 'na',
                "type": pet.petType.name || 'na',
                "timeSlot":pet.timeSlot.label +'('+pet.timeSlot.time+')' || 'na',
                "date": moment(pet.date).format('ll') || 'na',
                "service": pet.service.name+'('+pet.service.cost+')' || 'na',
                "addons": pet.addOns.join(',') || 'na',
                "notes": pet.notes || 'na'
        })),
        "location": {
            "type": location.locationType || 'na',
            "center": location.selectedAddress?.location || 'home' || 'na',
            "address1": location.address1 || "NA",
            "address2": location.locality || "NA",
            "city": location.city || "NA",
            "state": location.state || "NA",
            "country": "India",
            "pincode": location.pinCode || "NA",
        }
    }
    const grooming_request_url: any = process.env.REACT_APP_GROOMING_REQUEST
        return  axios.post(
            grooming_request_url,
            params,
            {headers: {'Content-Type': 'application/json'}}
        )

}