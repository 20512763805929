import { Box, Grid, Typography } from '@mui/material';
import { Heading } from './OrderStyles';
import CouponCard from './CouponCard';
import { useSelector } from 'react-redux';
import { selectCartItems, selectErrorMessage } from '../../service/selectors/cartSelectors';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Offer from '../coupon/Offer';

function CouponSection({ applyCoupon, removeCoupon, coupons, setOpenCouponModal }: any) {
    const cartItems = useSelector(selectCartItems);
    const handleOpenCouponModal = () => setOpenCouponModal(true);

    return (
        <>
            <Heading>Coupons</Heading>
            <Box
                sx={{
                    marginTop: '1rem',
                    border: '1px solid rgba(0, 0, 0, 0.14)',
                    borderRadius: '10px',
                }}
            >
                <>
                    {cartItems?.coupon?.applicableDiscountInr ? (
                        <Offer
                            coupon={cartItems.coupon}
                            isApplied={true}
                            applyCoupon={applyCoupon}
                            removeCoupon={removeCoupon}
                            isShowButtons={true}
                            index={1}
                        />
                    ) : (
                        coupons
                            .slice(0, 3)
                            .map((coupon: any, index: any) => (
                                <Offer
                                    coupon={coupon}
                                    key={index}
                                    isApplied={cartItems?.coupon?.id === coupon?.id}
                                    applyCoupon={applyCoupon}
                                    removeCoupon={removeCoupon}
                                    isShowButtons={true}
                                    index={index}
                                />
                            ))
                    )}
                </>
                {coupons.length > 3 && (
                    <Grid
                        sx={{
                            display: 'flex',
                            padding: '1rem 12px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            borderTop: '1px dashed rgba(204, 204, 204, 0.50)',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#0A69D9',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={handleOpenCouponModal}
                        >
                            View All
                            <NavigateNextIcon sx={{ color: '#0A69D9' }} />
                        </Typography>
                    </Grid>
                )}
            </Box>
        </>
    );
}

export default CouponSection;
