import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }: any) => {

    const token = localStorage.getItem('accessToken');
    if (!token) {
        return <Navigate to="/" />;
    }

    return children;
};

export default AuthGuard;
