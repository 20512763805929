import request from './request';

export function addToCart(data?: any) {
    return request({
        url: `cart-items`,
        method: 'post',
        data,
    });
}

export function getCart(params?: any) {
    return request({
        url: `carts`,
        method: 'get',
        params,
    });
}

export function updateCart(params?: any) {
    return request({
        url: `cart-items/${params.data.id}`,
        method: 'patch',
        data: params.data,
    });
}

export function deleteItem(params?: any) {
    return request({
        url: `cart-items/${params}`,
        method: 'delete',
    });
}

export function applyCoupon(data?: any) {
    return request({
        url: `carts/apply-coupon`,
        method: 'put',
        data
    });
}

export function removeCoupon(params?: any) {
    return request({
        url: `carts/remove-coupon`,
        method: 'delete',
    });
}

export const cartApi: any = {
  ADD_ITEM_TO_CART: async (payload:any) => await addToCart(payload.data) ,
  GET_CART: async() => await getCart(),
  UPDATE_CART_ITEM: async (payload:any) => await updateCart(payload),
  DELETE_CART_ITEM: async(payload:any) => await deleteItem(payload.data),
  APPLY_COUPON: async(payload:any) => await applyCoupon(payload.data),
  REMOVE_COUPON: async(payload:any) => await removeCoupon(),
}

/* export const cartApi: any = {
  ADD_ITEM_TO_CART: async (payload:any) => await service.post("/cart-items", payload.data) ,
  GET_CART: async() => await service.get("carts"),
  UPDATE_CART_ITEM: async (payload:any) => await service.patch(`cart-items/${payload.data.id}`, payload.data),
  DELETE_CART_ITEM: async(payload:any) => await service.delete(`cart-items/${payload.data}`),
  
} */
