export const linkStyle = {
    borderRadius: '8px',
    borderRight: '4px solid var(--yellow-pri, #FFD600)',
    background: 'rgba(255, 214, 0, 0.10)',
};

export const IconStyle = {
    minWidth: "auto"
}

export const PhoneNumber = {
    color: 'var(--Dark-Brown-Dark-Brown---100, #231F20)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '28px',
}

export const SNText = {
    color: 'rgba(255, 255, 255, 0.90)',
    fontFeatureSettings: "'salt' on, 'ss02' on",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}
