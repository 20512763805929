import { DialogContent, Grid } from '@mui/material';
import React from 'react';

const PreviewImage = ({ previewImage }: any) => {

    console.log({previewImage})
    return (
        <DialogContent sx={{ padding: { xs: '1rem', md: '1.5rem' } }}>
            <Grid container>
                <Grid sx={{ top: '0rem', textAlign: 'center', width: '100%' }}>
                    <img
                        src={previewImage}
                        alt="preview"
                        style={{
                            borderRadius: '16px',
                            aspectRatio: 'contain',
                        }}
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default PreviewImage;
