import Image from '../../component/image/image';
import {
    Cards,
    CardSpan,
    CardsSupport,
    CardTitle,
    Container,
    NavBorder,
    NavCard,
    CardInfoBox,
} from './locationInfoStyle';
import { Link, useLocation } from 'react-router-dom';

interface CardInfo {
    id: number;
    title: string;
    span: string;
    image: string;
    to: string;
}

const locationInfo: CardInfo[] = [
    {
        id: 1,
        title: 'support@sniffynose.com',
        span: 'We are always happy to help.',
        image: require('../../assets/images/support_email.png'),
        to: 'mailto:hello@sniffynose.com',
    },
    {
        id: 2,
        title: '+91 93599 - 39912',
        span: 'Call us to get your answer faster',
        image: require('../../assets/images/support_phone.png'),
        to: 'tel:9359939912',
    },
    {
        id: 3,
        title: 'WhatsApp Us',
        span: "Say, Hi and let's chat",
        image: require('../../assets/images/support_whatsApp.png'),
        to: 'https://api.whatsapp.com/send/?phone=+919359939912&text=Hey!%20Can%20you%20give%20me%20a%20paw-some%20assist?%20My%20human%20needs%20a%20hand!&type=phone_number&app_absent=0',
    },
];

const LocationInfo = ({ boxShadow, borderRadius, background }: any) => {
    const location = useLocation();
    const currentRoute = location.pathname;
    const hasDivider = currentRoute === '/location';

    return (
        <Container>
            {locationInfo.map(info => (
                <>
                    <Cards
                        boxShadow={boxShadow}
                        borderRadius={borderRadius}
                        background={background}
                    >
                        <Link to={info.to} target="_black">
                            <Image src={info.image} alt={info.title} />
                        </Link>
                        <CardInfoBox>
                            <Link style={{ textDecoration: 'none' }} to={info.to} target="_black">
                                <CardTitle>{info.title}</CardTitle>
                            </Link>
                            <CardSpan>{info.span}</CardSpan>
                        </CardInfoBox>
                        {info.id === 2 && <CardsSupport>9AM - 7PM support</CardsSupport>}
                    </Cards>
                    {(info.id === 1 || info.id === 2) && hasDivider && (
                        <NavCard>
                            <NavBorder />
                        </NavCard>
                    )}
                </>
            ))}
        </Container>
    );
};

export default LocationInfo;
