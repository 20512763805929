export const dealsNavigationNameByWeekDays: any = {
    1: {
        0: 'Self-Care Sunday',
        1: 'Mega Monday',
        2: 'Terrific Tuesday',
        3: 'Wholesome Wednesday',
        4: 'Thirsty Thursday',
        5: 'Fur-tastic Friday',
        6: 'Super Savings Saturday',
    },
    2: {
        0: 'Self-Care Sunday',
        1: 'Mega Monday',
        2: 'Terrific Tuesday',
        3: 'Wholesome Wednesday',
        4: 'Thirsty Thursday',
        5: 'Fur-tastic Friday',
        6: 'Super Savings Saturday',
    },
    3: {
        0: 'Self-Care Sunday',
        1: 'Mega Monday',
        2: 'Terrific Tuesday',
        3: 'Wholesome Wednesday',
        4: 'Thirsty Thursday',
        5: 'Fur-tastic Friday',
        6: 'Super Savings Saturday',
    },
    4: {
        0: 'Self-Care Sunday',
        1: 'Mega Monday',
        2: 'Terrific Tuesday',
        3: 'Wholesome Wednesday',
        4: 'Thirsty Thursday',
        5: 'Fur-tastic Friday',
        6: 'Super Savings Saturday',
    },
    5: {
        0: 'Self-Care Sunday',
        1: 'Mega Monday',
        2: 'Terrific Tuesday',
        3: 'Wholesome Wednesday',
        4: 'Thirsty Thursday',
        5: 'Fur-tastic Friday',
        6: 'Super Savings Saturday',
    },
};

export const dealsNavigationImagesByDays: any = {
    0: require('../../assets/images/deals-days/sunday.svg').default,
    1: require('../../assets/images/deals-days/monday.svg').default,
    2: require('../../assets/images/deals-days/tuesday.svg').default,
    3: require('../../assets/images/deals-days/wednesday.svg').default,
    4: require('../../assets/images/deals-days/thursday.svg').default,
    5: require('../../assets/images/deals-days/friday.svg').default,
    6: require('../../assets/images/deals-days/saturday.svg').default,
};

export const getDealsLabel = () => {
    const inputDate = new Date();

    // Get the day of the week
    const dayOfWeek = inputDate.getDay();

    // Calculate the week of the month
    const startOfMonth = new Date(inputDate.getFullYear(), inputDate.getMonth(), 1);
    const dayOfMonth = inputDate.getDate();
    const startDayOfWeek = startOfMonth.getDay();

    // Calculate the week number
    const weekOfMonth = Math.ceil((dayOfMonth + startDayOfWeek) / 7);

    return dealsNavigationNameByWeekDays[weekOfMonth][dayOfWeek];
};

export const getDealImageForDay = () => {
    const inputDate = new Date();

    // Get the day of the week
    const dayOfWeek = inputDate.getDay();
    return dealsNavigationImagesByDays[dayOfWeek];
};
