interface ImagePropsType {
    className?: string;
    onClick?: () => void;
    alt?: string;
    src: string;
    width?: string;
    height?: string;
    style?: any
}

const Image = (props: ImagePropsType) => <img {...props} alt={props.alt ? props.alt : "image"} style={props.style} />;

export default Image;