import { appActions } from '../../action';

const initialState: any = {
    user: {},
    totalPoints: 0,
    rewards: [],
    isLoading: false,
};
const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case `${appActions.GET_USER}_START`:
            return { ...state, isLoading: true };
        case `${appActions.GET_USER}_SUCCESS`:
            return { ...state, user: action.payload, isLoading: false };
        case `${appActions.GET_USER}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.GET_REWARDS}_START`:
            return { ...state, isLoading: true };
        case `${appActions.GET_REWARDS}_SUCCESS`:
            return {
                ...state,
                rewards: action?.payload?.rewards,
                totalPoints: action?.payload?.rewardPoints,
                isLoading: false,
            };
        case `${appActions.GET_REWARDS}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.UPDATE_POINTS}_START`:
            return { ...state, isLoading: true };
        case `${appActions.UPDATE_POINTS}_SUCCESS`:
            return { ...state, totalPoints: action.payload, isLoading: false };
        case `${appActions.UPDATE_POINTS}_FAIL`:
            return { ...state, isLoading: false };

        case `${appActions.LOGOUT}_SUCCESS`:
            return { ...state, user: {}, totalPoints: 0, rewards: [], isLoading: false };

        default:
            return state;
    }
};

export default userReducer;
