import { takeEvery, takeLatest, take } from "@redux-saga/core/effects";
import { appActions } from "../action";
import { sagaEngine } from "./sagaEngine/sagaEngine";

function* mySagas() {
    yield takeEvery(appActions.GET_PRODUCT_CATEGORY, sagaEngine)
    yield takeEvery(appActions.GET_PRODUCT, sagaEngine)
    yield takeEvery(appActions.GET_UNIQUE_PRODUCT, sagaEngine)
    yield takeEvery(appActions.GET_EXCLUSIVE_OFFERS, sagaEngine)

    yield takeLatest(appActions.ADD_ITEM_TO_CART, sagaEngine);
    yield takeLatest(appActions.GET_CART, sagaEngine);
    yield takeLatest(appActions.DELETE_CART_ITEM, sagaEngine);
    yield takeLatest(appActions.UPDATE_CART_ITEM, sagaEngine);
    yield takeLatest(appActions.APPLY_COUPON, sagaEngine);
    yield takeLatest(appActions.REMOVE_COUPON, sagaEngine);

    yield takeLatest(appActions.GET_USER, sagaEngine);
    yield takeLatest(appActions.GET_REWARDS, sagaEngine);
}

export default mySagas

