import { useState } from 'react';

export interface QueryParamFilters {
    defaultSearch: string | null;
    defaultCategory: string | null;
    defaultTag: string | null;
    defaultProduct: string | null;
    defaultCouponCode: string | null;
}

export type UseQueryParamFiltersReturn = [
    QueryParamFilters,
    React.Dispatch<React.SetStateAction<QueryParamFilters>>,
    () => void
];

const useQueryParamFilters = (initialFilters: QueryParamFilters): UseQueryParamFiltersReturn => {
    const [queryParamFilters, setQueryParamFilters] = useState<QueryParamFilters>(initialFilters);

    const resetQueryFilters = () => {
        setQueryParamFilters({
            defaultSearch: null,
            defaultCategory: null,
            defaultTag: null,
            defaultProduct: null,
            defaultCouponCode: null,
        });
    };

    return [queryParamFilters, setQueryParamFilters, resetQueryFilters];
};

export default useQueryParamFilters;
