import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import {
    Button,
    ButtonGroup,
    CardContent,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { CURRENCY_SIGN, CURRENCY } from '../common/constants';
import useCheckMobileScreen from '../common/hooks/useCheckMobileScreen';

export default function CartProductDetails({
    handleQuantityChange,
    removeProductFromCart,
    value,
    isLastItem,
}: any) {
    const theme = useTheme();
    const isMobile = useCheckMobileScreen();

    const secondary = value.product.productVariantOption
        ? value.product.productVariantOption
              .map((item: any) => {
                  return item.name + ': ' + item?.value?.name;
              })
              .join(', ')
        : null;

    return (
        <>
            {isMobile ? (
                <Box
                    key={value.id}
                    sx={
                        isMobile
                            ? {
                                  border: 'none !important',
                                  boxShadow: 'none !important',
                                  borderRadius: '0px !important',
                                  paddingTop: '16px',
                              }
                            : null
                    }
                >
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '1rem',
                            p: isMobile ? 0 : 'inherit',
                            borderBottom: isLastItem ? 'none' : '1px solid #ECECEC',
                        }}
                    >
                        <Stack
                            sx={{
                                border: '1px solid rgb(236, 236, 236)',
                                padding: '5px',
                                height: '4.8rem',
                            }}
                        >
                            <img
                                src={value.product?.media.url}
                                alt="#"
                                style={{
                                    aspectRatio: 1,
                                    borderRadius: '1rem',
                                    width: isMobile ? '4.375rem' : '5.5rem',
                                    height: isMobile ? '4.375rem' : '5.5rem',
                                    objectFit: 'contain',
                                }}
                            />
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                            <Stack direction={'row'}>
                                <ListItemText
                                    sx={{ margin: 0 }}
                                    primary={value.product.name}
                                    primaryTypographyProps={{
                                        color: 'var(--Black, #14141E)',
                                        fontFamily: 'Euclid-Circular-A-Medium',
                                        fontSize: '0.875rem',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        marginBottom: '0.25rem',
                                    }}
                                    secondary={secondary}
                                    secondaryTypographyProps={{
                                        overflow: 'hidden',
                                        color: '#747474',
                                        textOverflow: 'ellipsis',
                                        fontFamily: 'Euclid-Circular-A-Regular',
                                        fontSize: '0.75rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}
                                />
                                <Box>
                                    <IconButton
                                        onClick={e => {
                                            removeProductFromCart(e, value.id);
                                        }}
                                        sx={{ fontSize: '1.15rem' }}
                                    >
                                        <Icon
                                            color="rgba(241, 89, 70, 1)"
                                            icon="fluent:delete-48-regular"
                                        />
                                    </IconButton>
                                </Box>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={1}
                            >
                                <Stack>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'var(--Black, #14141E)',
                                                textOverflow: 'ellipsis',
                                                fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                                fontSize: '1rem',
                                                fontStyle: 'normal',
                                                lineHeight: '1.5rem',
                                            }}
                                        >
                                            {CURRENCY_SIGN}
                                            {value.sellingPrice}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                overflow: 'hidden',
                                                color: '#7B7979',
                                                textAlign: 'right',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                fontFamily: 'Euclid-Circular-A-Regular',
                                                fontSize: '0.825rem',
                                                fontStyle: 'normal',
                                                lineHeight: '1rem',
                                                leadingTrim: 'both',
                                                textEdge: 'cap',
                                                textDecorationLine: 'strikethrough',
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 2,
                                                textDecoration: 'line-through',
                                            }}
                                        >
                                            {CURRENCY_SIGN}
                                            {value.originalPrice}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        {value?.discountValue > 0 && (
                                            <Box
                                                sx={{
                                                    background: theme.palette.primary.main,
                                                    borderRadius: '0.5rem',
                                                    padding: '3px',
                                                }}
                                            >
                                                <Typography variant="body2" fontSize={'12px'}>
                                                    {value.discountMetric === 'INR'
                                                        ? CURRENCY
                                                        : ' '}{' '}
                                                    {value.discountValue}{' '}
                                                    {value.discountMetric === 'PERCENTAGE'
                                                        ? '% '
                                                        : ' '}
                                                    Off
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} m={'0 !important'}>
                                    <Box>
                                        <ButtonGroup
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            color="inherit"
                                            sx={{
                                                borderRadius: '0.5rem',
                                                border: '1px solid rgba(204, 204, 204, 0.50)',
                                            }}
                                        >
                                            <Button
                                                aria-label="reduce"
                                                variant="text"
                                                sx={{
                                                    borderRight: 'none !important',
                                                    minWidth: '20px !important',
                                                }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    const newQuant =
                                                        value.quantity > 0 ? value.quantity - 1 : 0;

                                                    handleQuantityChange(e, newQuant, value.id);
                                                }}
                                            >
                                                <Icon icon="ri:subtract-line" />
                                            </Button>
                                            <Button
                                                disableTouchRipple
                                                size="small"
                                                variant="text"
                                                sx={{
                                                    color: 'rgba(20, 20, 30, 1)',
                                                    borderRight: 'none !important',
                                                    minWidth: '20px !important',
                                                }}
                                            >
                                                {value.quantity}
                                            </Button>
                                            <Button
                                                aria-label="increase"
                                                variant="text"
                                                sx={{
                                                    minWidth: '20px !important',
                                                }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    const newQuant =
                                                        value.quantity > 0 ? value.quantity + 1 : 0;

                                                    handleQuantityChange(e, newQuant, value.id);
                                                }}
                                            >
                                                <Icon icon="ri:add-line" />
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Box>
            ) : (
                <Box
                    key={value.id}
                    sx={{
                        padding: '1.125rem 1.5rem 0 1.5rem',
                    }}
                >
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '1rem',
                            padding: '0',
                            paddingBottom: '1.125rem !important',
                            borderBottom: isLastItem ? 'none' : '1px solid #ECECEC',
                        }}
                    >
                        <Stack
                            sx={{
                                border: '1px solid rgb(236, 236, 236)',
                                padding: '5px',
                                height: '5rem',
                            }}
                        >
                            <img
                                src={value.product?.media.url}
                                alt="#"
                                style={{
                                    aspectRatio: 1,
                                    borderRadius: '1rem',
                                    width: isMobile ? '4.375rem' : '4.5rem',
                                    height: isMobile ? '4.375rem' : '4.5rem',
                                    objectFit: 'contain',
                                }}
                            />
                        </Stack>
                        <Stack sx={{ width: '100%', gap: '0.5rem' }}>
                            <Stack sx={{ flexDirection: 'row' }}>
                                <ListItemText
                                    sx={{ margin: 0 }}
                                    primary={value.product.name}
                                    primaryTypographyProps={{
                                        color: 'var(--Black, #14141E)',
                                        fontFamily: 'Euclid-Circular-A-Medium',
                                        fontSize: '0.875rem',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        textTransform: 'capitalize',
                                        paddingBottom: '2px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 400,
                                    }}
                                    secondary={secondary}
                                    secondaryTypographyProps={{
                                        fontFamily: 'Euclid-Circular-A-Regular',
                                        fontSize: '0.75rem',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        color: 'var(--Dark-Brown-Dark-Brown---60, #7B7979)',
                                        textOverflow: 'ellipsis',
                                    }}
                                />
                                <Box>
                                    <IconButton
                                        onClick={e => {
                                            removeProductFromCart(e, value.id);
                                        }}
                                        sx={{ fontSize: '1.15rem' }}
                                    >
                                        <Icon
                                            color="rgba(241, 89, 70, 1)"
                                            icon="fluent:delete-48-regular"
                                        />
                                    </IconButton>
                                </Box>
                            </Stack>
                            {value?.isAvailable === false && (
                                <Typography
                                    sx={{
                                        color: '#f01313',
                                        fontSize: '0.75rem',
                                        fontWeight: '400',
                                        lineHeight: '0.5',
                                    }}
                                >
                                    Sold Out
                                </Typography>
                            )}
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={0.5}
                            >
                                <Box>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                gap: '5px',
                                                flex: '1 0 0',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '4px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        color: 'var(--Black, #14141E)',
                                                        textAlign: 'right',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontFamily: 'Euclid-Circular-A-Semi-Bold',
                                                        fontSize: '1rem',
                                                        fontStyle: 'normal',
                                                        lineHeight: '1.5rem',
                                                    }}
                                                >
                                                    {CURRENCY_SIGN}
                                                    {value.sellingPrice}
                                                </Typography>
                                                {value.discountValue > 0 && (
                                                    <Typography
                                                        sx={{
                                                            overflow: 'hidden',
                                                            color: '#7B7979',
                                                            textAlign: 'right',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            fontFamily: 'Euclid-Circular-A-Regular',
                                                            fontSize: '0.75rem',
                                                            fontStyle: 'normal',
                                                            lineHeight: '1rem',
                                                            leadingTrim: 'both',
                                                            textEdge: 'cap',
                                                            textDecorationLine: 'strikethrough',
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 2,
                                                            textDecoration: 'line-through',
                                                        }}
                                                    >
                                                        {CURRENCY_SIGN}
                                                        {value.originalPrice}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {value?.discountValue > 0 && (
                                                    <Box
                                                        sx={{
                                                            background: theme.palette.primary.main,
                                                            padding: '3px',
                                                            borderRadius: '0.25rem',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--Black, #14141E)',
                                                                textOverflow: 'ellipsis',
                                                                fontSize: '10px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 500,
                                                                lineHeight: 'normal',
                                                            }}
                                                        >
                                                            {value.discountMetric === 'INR'
                                                                ? CURRENCY
                                                                : ' '}{' '}
                                                            {value.discountValue}
                                                            {value.discountMetric === 'PERCENTAGE'
                                                                ? '% '
                                                                : ' '}
                                                            Off
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box>
                                    <ButtonGroup
                                        variant="text"
                                        disableElevation
                                        size="small"
                                        color="inherit"
                                        sx={{
                                            borderRadius: '0.5rem',
                                            border: '1px solid rgba(204, 204, 204, 0.50)',
                                        }}
                                    >
                                        <Button
                                            aria-label="reduce"
                                            variant="text"
                                            sx={{
                                                borderRight: 'none !important',
                                                minWidth: '20px !important',
                                            }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                const newQuant =
                                                    value.quantity > 0 ? value.quantity - 1 : 0;
                                                handleQuantityChange(e, newQuant, value.id);
                                            }}
                                        >
                                            <Icon icon="ri:subtract-line" />
                                        </Button>
                                        <Button
                                            disableTouchRipple
                                            size="small"
                                            variant="text"
                                            sx={{
                                                color: 'rgba(20, 20, 30, 1)',
                                                borderRight: 'none !important',
                                            }}
                                        >
                                            {value.quantity}
                                        </Button>
                                        <Button
                                            aria-label="increase"
                                            variant="text"
                                            sx={{
                                                borderRight: 'none !important',
                                                minWidth: '20px !important',
                                            }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                const newQuant =
                                                    value.quantity > 0 ? value.quantity + 1 : 0;
                                                handleQuantityChange(e, newQuant, value.id);
                                            }}
                                        >
                                            <Icon icon="ri:add-line" />
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Box>
            )}
        </>
    );
}
