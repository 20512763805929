import { Fragment } from "react";
import Layout from "../component/layout/layout";
import InvitePage from "../view/home/invitePage";

const Invite = () => {
  return (
    <Fragment>
      <Layout>
        <InvitePage />
      </Layout>
    </Fragment>
  );
};

export default Invite;
