import { useQuery } from '@tanstack/react-query';
import { getProduct } from '../../service/api/products';

export default function useProductData(id: any) {
  return useQuery<any>({
    queryKey: ['productData'],
    queryFn: async () => {
      return await getProduct(id).then((data: any) => {
        const product = data?.data;
        return product;
      });
    },
    refetchOnMount: false,
  });
}
