import Box from '@mui/material/Box';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import pointCard from '../../assets/images/pointCard.svg';
import Icon from '../../component/icon';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { RedeemAndApplyBox } from './OrderStyles';

export default function PointsCard({ totalPoints, rewardDetails, handleModalAction }: any) {
    const isMobile = useCheckMobileScreen();

    return (
        <Card
            sx={{
                borderRadius: '24px',
                backgroundColor: '#1a1c1e',
                color: 'white',
                boxShadow: 3,
                marginTop: '1rem',
            }}
        >
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingBottom: '0.8rem !important',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <img
                            src={pointCard}
                            alt=""
                            style={{
                                width: '71.626px',
                                height: '58.384px',
                                flexShrink: 0,
                            }}
                        />
                        <Box>
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                color={'#FFE7AA'}
                                fontSize={'28px'}
                                sx={{ flexShrink: 0 }}
                            >
                                {totalPoints.toLocaleString('en-IN')}
                            </Typography>
                            {totalPoints > 0 && (
                                <>
                                    <Box
                                        display={'flex'}
                                        gap={'5px'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Typography variant="body2" fontSize={'18px'}>
                                            SN Points
                                        </Typography>
                                    </Box>

                                    {/* <Link
                                        to={'/rewards'}
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            color: '#FDDC2E',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        My Rewards
                                    </Link> */}
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        background: '#FFF',
                        borderRadius: '12px',
                        color: '#000000',
                        alignItems: 'center',
                        border: '1px solid rgba(204, 204, 204, 0.50)',
                    }}
                >
                    <Grid
                        display={'flex'}
                        gap={'16px'}
                        sx={{
                            flex: '1 0 0',
                            alignItems: isMobile ? 'start' : 'center',
                            padding: '14px 16px',
                        }}
                    >
                        <Box sx={{ marginTop: isMobile ? '5px' : '' }}>
                            <Icon name={'discount-coupon'} />
                        </Box>
                        <Box sx={RedeemAndApplyBox}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                <Typography
                                    sx={{
                                        color: '#6D39FF',
                                        fontFamily: 'Euclid-Circular-A-Regular',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                    }}
                                >
                                    FOR {rewardDetails.points_value.toLocaleString('en-IN')} SN
                                    POINTS
                                </Typography>
                                <Typography fontWeight={'500'}>{rewardDetails?.name}</Typography>
                                <Typography color={'#7B7979'} fontSize={'14px'}>
                                    Redeem coupon with your SN points & avail discount on your
                                    order.
                                </Typography>
                            </Box>
                            {!rewardDetails.name.includes('service') && (
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    size="large"
                                    sx={{
                                        minWidth: '9.375rem',
                                        borderRadius: '8px',
                                        padding: '7px 12px',
                                    }}
                                    onClick={handleModalAction}
                                >
                                    Redeem & Apply
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
}
