import { Card, CardContent, Typography, Box, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import getReward from '../../assets/images/getReward.svg';
import pointCard from '../../assets/images/pointCard.svg';
import { useSelector } from 'react-redux';
import { totalPointsSelector } from '../../service/selectors/userSelector';
import useCheckMobileScreen from '../../common/hooks/useCheckMobileScreen';
import { PointExp, Points } from './RewardStyle';

const RewardPoints = () => {
    const totalPoints = useSelector(totalPointsSelector);
    const isMobile = useCheckMobileScreen();

    return (
        <Card
            sx={{
                width: isMobile ? '100%' : '317px',
                borderRadius: '24px',
                backgroundColor: '#1a1c1e',
                color: 'white',
                padding: '12px 24px',
                boxShadow: 3,
                height: isMobile ? '100%' : '250px',
                marginTop: '1rem',
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    padding: '0',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Box display="flex" alignItems="center" gap={'6px'}>
                        <Typography
                            sx={{
                                color: 'rgba(255, 255, 255, 0.90)',
                                fontFeatureSettings: "'salt' on, 'ss02' on",
                                fontFamily: 'Euclid-Circular-A-Regular',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}
                        >
                            SN Points
                        </Typography>
                        <Tooltip title="Information about SN Points" arrow>
                            <HelpOutlineIcon
                                fontSize="small"
                                sx={{ color: 'grey.500', verticalAlign: 'middle' }}
                            />
                        </Tooltip>
                    </Box>
                    <Box>
                        <Typography sx={Points}>{totalPoints.toLocaleString('en-IN')}</Typography>
                    </Box>
                </Box>
                <img src={pointCard} alt="" />
            </CardContent>
            <Typography variant="body2" mb={isMobile ? 1.75 : 3} mt={1.5}>
                Get rewards and exclusive products as you shop with Sniffy nose
            </Typography>
            <Box
                width={isMobile ? '100%' : '365px'}
                position={isMobile ? 'unset' : 'absolute'}
                ml={isMobile ? '' : '-35px'}
            >
                <img src={getReward} alt="" style={{ width: isMobile ? '100%' : 'auto' }} />
            </Box>
        </Card>
    );
};

export default RewardPoints;
