import { Box, Button, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    Rating,
    ReviewButton,
    SellerInfoContainer,
    SellerInfoText,
    SellerInfoTitle,
} from './ProductStyle';
import Icon from '../icon/index';
import Logo from '../../assets/logos/logo.svg';

function SellerInfo() {
    return (
        <Box sx={SellerInfoContainer}>
            <Typography sx={SellerInfoText}>Seller Information</Typography>
            <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                <Box
                    sx={{
                        borderRadius: '16px',
                        background: 'var(--Yellow-Yellow---100, #FFD600)',
                        padding: '14.733px 10.683px 13.267px 10.317px',
                        height: '55px',
                    }}
                >
                    <img src={Logo} alt="" style={{ height: '100%' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Typography sx={SellerInfoTitle}>SniffyNose</Typography>
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        <Icon name="Rating" />
                        <Typography sx={Rating}>4.7</Typography>
                        <Button
                            sx={ReviewButton}
                            onClick={() =>
                                window.open('https://www.google.com/search?q=sniffynose', '_blank')
                            }
                        >
                            440+ reviews{' '}
                            <ArrowForwardIosIcon sx={{ fontSize: '0.75rem', marginTop: '3px' }} />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default SellerInfo;
