import Image from '../../component/image/image';
import Header from '../bookService/serviceHeader/header';
import LocationInfo from '../locationInfo/locationInfo';
import StoreInfo from '../storeInfo/storeInfo';
import {
    CardsLocation,
    Container,
    Title,
    TitleBox,
    TitleBoxSpan,
    HeightLightImage,
    StoreCards,
} from './supportStyle';
import locationHeightLight from '../../assets/images/locationHeightLight.png';
import Icon from '../../component/icon';

const SupportInfo = () => {
    return (
        <>
            <Header />
            <Container>
                <CardsLocation>
                    <LocationInfo
                        boxShadow="0px 2px 5px 0px rgba(123, 122, 137, 0.17)"
                        borderRadius="1rem"
                        background="#fff"
                    />
                </CardsLocation>
                <TitleBox>
                    <Title>Our Store locations</Title>
                    <HeightLightImage>
                        <Image src={locationHeightLight} alt="heightLight" />
                    </HeightLightImage>
                    <TitleBoxSpan>
                        Visit us at our physical store to say Hi.
                        <Icon name="fi-service" />
                    </TitleBoxSpan>
                </TitleBox>
                <StoreCards>
                    <StoreInfo />
                </StoreCards>
            </Container>
        </>
    );
};

export default SupportInfo;
