import { styled } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';

export const Container = styled(Box)`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled(Box)`
  color: var(--Black, #14141e);
  font-family: Euclid-Circular-A-Regular;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 1.88rem;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const StepSpan = styled(Box)`
  color: #7f808c;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AddressBox = styled(Box)`
  display: flex;
  margin-bottom: 0.62rem;
  align-items: center;
  gap: 0.25rem;
`;

export const RadioStyle = {
  justifyContent: 'start',
  display: 'flex',
  margin: 0,
  padding: 0,
  '&.Mui-checked': {
    color: '#FDDC2E',
  },
};

export const AddressDetailsRadioStyle = {
  '&.Mui-checked': {
    color: '#232220',
  },
};

export const GroomingAddressBorder = {
  borderBottom: '1px solid #CCCCCC80',
};

export const InputFieldMargin = {
  marginRight: '1rem',
};

export const InputFieldMarginAndWidth = {
  marginRight: '1rem',
  width: '70%',
};

export const GroomingTitle = styled(Box)`
  color: #333;
  font-family: Euclid-Circular-A-Regular;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const GroomingAddress = styled(Box)`
  border-radius: 0.5rem;
  border: 1px solid #cccccc80;
`;

export const GroomingAddressDetails = styled(Box)`
  display: flex;
  padding: 1.125rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  align-self: stretch;
  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
    gap: 0rem;
  }
`;

export const InputFieldContainer = styled(Box)`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

export const InputField = styled(TextField)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 100% */
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 1.25rem;
  }
`;

export const DropDownInputField = styled(Box)`
  color: var(--Black, #14141e);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: Euclid-Circular-A-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 100% */
  letter-spacing: 0.03125rem;
  text-transform: capitalize;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 1.25rem;
  }
`;

export const DropDownField = {
  width: '100%',
};

export const errorStyle= {
  "& .Mui-error":{
      marginLeft:"0"
  }
}
