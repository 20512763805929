import moment from 'moment/moment.js';

export const getCurrentDate = () => moment().startOf('day');

export const getWeekRange = (date: any, numberOfDays: any) => {
  const previousDay = moment(date).subtract(1, 'day');
  const firstDayOfTheWeek = previousDay.startOf('day');

  return Array.from({ length: numberOfDays }).map((_, day) =>
    firstDayOfTheWeek.clone().add(day, 'day')
  );
};

export const getWeekDay = (date: any) => moment(date).format('dddd');

export const getShortWeekDay = (date: any) => getWeekDay(date).slice(0, 3);

export const getDay = (date: any) => moment(date).format('DD');

export const getMonth = (date: any) => moment(date).format('MMMM');

export const getYear = (date: any) => moment(date).format('y');

export const isPast = (date: any) => date.isBefore(getCurrentDate(), 'day');

export const BreedType: {
  name: string;
  id: number;
  pet_id: number;
  type: string;
  category: string;
}[] = [
  { name: 'Pug', id: 1, pet_id: 1, type: 'dog', category: 'A' },
  { name: 'Beagle', id: 2, pet_id: 1, type: 'dog', category: 'A' },
  { name: 'French Bull Dog', id: 3, pet_id: 1, type: 'dog', category: 'A' },
  { name: 'Indian', id: 4, pet_id: 1, type: 'dog', category: 'B' },
  { name: 'Pit-bull', id: 5, pet_id: 1, type: 'dog', category: 'B' },
  { name: 'American Bully', id: 27, pet_id: 1, type: 'dog', category: 'B' },
  { name: 'Doberman', id: 6, pet_id: 1, type: 'dog', category: 'B' },
  { name: 'Dachshund', id: 7, pet_id: 1, type: 'dog', category: 'B' },
  { name: 'Bulldog', id: 8, pet_id: 1, type: 'dog', category: 'B' },
  { name: 'Labrador', id: 9, pet_id: 1, type: 'dog', category: 'B' },
  { name: 'Cocker Spaniel', id: 10, pet_id: 1, type: 'dog', category: 'C' },
  { name: 'Poodle', id: 11, pet_id: 1, type: 'dog', category: 'C' },
  { name: 'Pomeranian', id: 12, pet_id: 1, type: 'dog', category: 'C' },
  { name: 'Shih Tzu', id: 13, pet_id: 1, type: 'dog', category: 'C' },
  { name: 'Lhasa Apso', id: 14, pet_id: 1, type: 'dog', category: 'C' },
  { name: 'Maltese', id: 15, pet_id: 1, type: 'dog', category: 'C' },
  { name: 'Golden Retriever', id: 16, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Huskie', id: 17, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Dalmatian', id: 18, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'ChowChow', id: 19, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'German Shepherd', id: 20, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Great Dane', id: 28, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Samoyed', id: 21, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Rottweiler', id: 22, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Akita Inu', id: 23, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Great Dane', id: 24, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Other', id: 25, pet_id: 1, type: 'dog', category: 'D' },
  { name: 'Indian cat', id: 26, pet_id: 2, type: 'cat', category: 'E' },
  { name: 'Persian cat', id: 27, pet_id: 2, type: 'cat', category: 'F' },
];

export const PetType: { name: string; id: number }[] = [
  { name: 'Dog', id: 1 },
  { name: 'Cat', id: 2 },
];

interface AddOnsDTO {
  name: string;
  cost: number;
  categories: { name: string; price: number }[];
  currency: string;
  is_for_dog: string;
  is_for_cats: string;
  id: number;
}

export const AddOns: AddOnsDTO[] = [
  {
    name: 'Massage',
    cost: 399,
    currency: 'INR',
    categories: [
      { name: 'A', price: 399 },
      { name: 'B', price: 399 },
      { name: 'C', price: 399 },
      { name: 'D', price: 399 },
      { name: 'E', price: 399 },
      { name: 'F', price: 399 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'true',
    id: 1,
  },
  {
    name: 'Nail Cut',
    cost: 149,
    currency: 'INR',
    categories: [
      { name: 'A', price: 149 },
      { name: 'B', price: 149 },
      { name: 'C', price: 149 },
      { name: 'D', price: 149 },
      { name: 'E', price: 149 },
      { name: 'F', price: 149 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'true',
    id: 2,
  },
  {
    name: 'Ear Cleaning',
    cost: 99,
    currency: 'INR',
    categories: [
      { name: 'A', price: 99 },
      { name: 'B', price: 99 },
      { name: 'C', price: 99 },
      { name: 'D', price: 99 },
      { name: 'E', price: 99 },
      { name: 'F', price: 99 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'true',
    id: 3,
  },
  {
    name: 'De-shedding',
    cost: 399,
    currency: 'INR',
    categories: [
      { name: 'A', price: 299 },
      { name: 'B', price: 299 },
      { name: 'C', price: 399 },
      { name: 'D', price: 399 },
      { name: 'E', price: 299 },
      { name: 'F', price: 399 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'true',
    id: 4,
  },
  {
    name: 'De-Matting & Tangles Removal ',
    cost: 599,
    currency: 'INR',
    categories: [
      { name: 'A', price: 499 },
      { name: 'B', price: 499 },
      { name: 'C', price: 599 },
      { name: 'D', price: 599 },
      { name: 'E', price: 499 },
      { name: 'F', price: 599 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'true',
    id: 5,
  },
  {
    name: 'Anti Tick Application',
    cost: 199,
    currency: 'INR',
    categories: [
      { name: 'A', price: 199 },
      { name: 'B', price: 199 },
      { name: 'C', price: 199 },
      { name: 'D', price: 199 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'false',
    id: 6,
  },
  {
    name: 'Teeth Brushing',
    cost: 99,
    currency: 'INR',
    categories: [
      { name: 'A', price: 99 },
      { name: 'B', price: 99 },
      { name: 'C', price: 99 },
      { name: 'D', price: 99 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'false',
    id: 7,
  },
  {
    name: 'Paw Hair Cut',
    cost: 99,
    currency: 'INR',
    categories: [
      { name: 'A', price: 99 },
      { name: 'B', price: 99 },
      { name: 'C', price: 99 },
      { name: 'D', price: 99 },
    ],
    is_for_dog: 'true',
    is_for_cats: 'false',
    id: 8,
  },
];

interface ServicePlan {
  name: string;
  cost: string;
  currency: string;
  categories: { name: string; price: number }[];
  is_for_dog: boolean;
  is_for_cats: boolean;
  short_description: string;
  short_description_cat: string;
  usp: string;
  id: number;
  time: string;
  short_description_array: string[];
  short_description_cat_array: string[];
}

export const servicePlans: ServicePlan[] = [
  {
    name: 'Advance Grooming',
    cost: '799',
    currency: 'INR',
    categories: [
      { name: 'A', price: 799 },
      { name: 'B', price: 799 },
      { name: 'C', price: 799 },
      { name: 'D', price: 899 },
      { name: 'E', price: 899 },
      { name: 'F', price: 999 },
    ],
    is_for_dog: true,
    is_for_cats: true,
    short_description:
      'Double Shampoo/One Time Shampoo & One Time Conditioner, Blow Dry, Nail Cut, Ear Clean, Teeth Brushing, Paw Cream, Perfume ',
    short_description_cat:
      'Double Shampoo/One Time Shampoo & One Time Conditioner, Blow Dry, Nail Cut, Ear Clean',
    short_description_array: [
      'Double Shampoo/One Time Shampoo & One Time Conditioner',
      'Blow Dry',
      'Nail Cut',
      'Ear Clean',
      'Teeth Brushing',
      'Paw Cream',
      'Perfume',
    ],
    short_description_cat_array: [
      'Double Shampoo/One Time Shampoo & One Time Conditioner',
      'Blow Dry',
      'Nail Cut',
      'Ear Clean',
    ],
    id: 1,
    usp: 'This package is good for deep clean and removes buildup',
    time: '1hr to 1hr 30mins',
  },
  {
    name: 'Skin Care Grooming',
    cost: '999',
    currency: 'INR',
    categories: [
      { name: 'A', price: 999 },
      { name: 'B', price: 1099 },
      { name: 'C', price: 1099 },
      { name: 'D', price: 1199 },
    ],
    is_for_dog: true,
    is_for_cats: false,
    short_description:
      'Two times Shampoo (All natural- antibiotic and antioxidant-rich formula) , Conditioner, Nail Cut, Ear Clean, Teeth Brushing, Paw Cream, Perfume',
    short_description_cat:
      'Two times Shampoo (All natural- antibiotic and antioxidant-rich formula) , Conditioner, Nail Cut, Ear Clean, Teeth Brushing, Paw Cream, Perfume',
    short_description_array: [
      'Two times Shampoo (All natural- antibiotic and antioxidant-rich formula)',
      'Conditioner',
      'Nail Cut',
      'Ear Clean',
      'Teeth Brushing',
      'Paw Cream',
      'Perfume',
    ],
    short_description_cat_array: [
      'Two times Shampoo (All natural- antibiotic and antioxidant-rich formula)',
      'Conditioner',
      'Nail Cut',
      'Ear Clean',
      'Teeth Brushing',
      'Paw Cream',
      'Perfume',
    ],
    id: 2,
    usp: 'This package effectively removes buildup, targets dandruff, and tackles skin issues using specialized shampoo and conditioner',
    time: '1hr to 1hr 45mins',
  },
  {
    name: 'Basic Grooming',
    cost: '599',
    currency: 'INR',
    categories: [
      { name: 'A', price: 599 },
      { name: 'B', price: 599 },
      { name: 'C', price: 599 },
      { name: 'D', price: 599 },
      { name: 'E', price: 799 },
      { name: 'F', price: 799 },
    ],
    is_for_dog: true,
    is_for_cats: true,
    short_description: 'Shampoo, Blow Dry, Nail Cut, Ear Clean, Paw Cream, Perfume',
    short_description_cat: 'Shampoo, Blow Dry, Nail Cut, Ear Clean',
    short_description_array: [
      'Shampoo',
      'Blow Dry',
      'Nail Cut',
      'Ear Clean',
      'Paw Cream',
      'Perfume',
    ],
    short_description_cat_array: ['Shampoo', 'Blow Dry', 'Nail Cut', 'Ear Clean'],
    id: 3,
    usp: 'This is ideal for quick and efficient cleaning with impressive result',
    time: '45mins to 1hr',
  },
  {
    name: 'Hair Cut',
    cost: '699',
    currency: 'INR',
    categories: [
      { name: 'A', price: 699 },
      { name: 'B', price: 799 },
      { name: 'C', price: 899 },
      { name: 'D', price: 899 },
      { name: 'E', price: 800 },
      { name: 'F', price: 900 },
    ],
    is_for_dog: true,
    is_for_cats: true,
    short_description: 'Any type of Hair Cut',
    short_description_cat: 'Any type of Hair Cut',
    short_description_array: ['Any type of Hair Cut'],
    short_description_cat_array: ['Any type of Hair Cut'],
    id: 4,
    usp: 'Choose your preferred haircut and style with this service.',
    time: '1hr to 1hr 30mins',
  },
  {
    name: 'Relaxing Massage',
    cost: '1399',
    currency: 'INR',
    categories: [
      { name: 'A', price: 1399 },
      { name: 'B', price: 1499 },
      { name: 'C', price: 1499 },
      { name: 'D', price: 1599 },
    ],
    is_for_dog: true,
    is_for_cats: false,
    short_description:
      'Oil Massage, Two times Shampoo (All natural- antibiotic and steroid free) , Conditioner, Serum, Nail Cut, Ear Clean, Teeth Brushing, Paw Cream, Perfume',
    short_description_cat:
      'Oil Massage, Two times Shampoo (All natural- antibiotic and steroid free) , Conditioner, Serum, Nail Cut, Ear Clean',
    short_description_array: [
      'Oil Massage',
      'Two times Shampoo (All natural- antibiotic and steroid free)',
      'Conditioner',
      'Serum',
      'Nail Cut',
      'Ear Clean',
      'Teeth Brushing',
      'Paw Cream',
      'Perfume',
    ],
    short_description_cat_array: [
      'Oil Massage',
      'Two times Shampoo (All natural- antibiotic and steroid free)',
      'Conditioner',
      'Serum',
      'Nail Cut',
      'Ear Clean',
    ],
    id: 5,
    usp: "Alongside its effective cleansing features like skin care package, this package includes a calming oil massage for your pet's relaxation.",
    time: '1hr to 1hr 30mins',
  },
  {
    name: 'Basic Complete (Basic + Hair Cut)',
    cost: '1099',
    currency: 'INR',
    categories: [
      { name: 'A', price: 1099 },
      { name: 'B', price: 1199 },
      { name: 'C', price: 1299 },
      { name: 'D', price: 1299 },
      { name: 'E', price: 1199 },
      { name: 'F', price: 1299 },
    ],
    is_for_dog: true,
    is_for_cats: true,
    short_description: 'Basic Grooming and  Hair Cut',
    short_description_cat: 'Basic Grooming and  Hair Cut',
    short_description_array: ['Basic Grooming', 'Hair Cut'],
    short_description_cat_array: ['Basic Grooming', 'Hair Cut'],
    id: 6,
    usp: 'This option provides quick cleaning and a haircut for pets.',
    time: '1hr 30mins to 2hr',
  },
  {
    name: 'Skin Care Complete (Skin Care + Hair Cut)',
    cost: '1499',
    currency: 'INR',
    categories: [
      { name: 'A', price: 1499 },
      { name: 'B', price: 1599 },
      { name: 'C', price: 1699 },
      { name: 'D', price: 1799 },
    ],
    is_for_dog: true,
    is_for_cats: false,
    short_description: 'Skin Care Grooming and Hair Cut',
    short_description_cat: 'Skin Care Grooming and Hair Cut',
    short_description_array: ['Skin Care Grooming', 'Hair Cut'],
    short_description_cat_array: ['Skin Care Grooming', 'Hair Cut'],
    id: 7,
    usp: 'This comprehensive package tackles skin concerns, removes buildup, and offers various haircuts, all with specialized shampoo and conditioner.',
    time: '1hr 30mins to 2hr 30mins',
  },
];

export const timeSlot: { label: string; time: string }[] = [
  { label: 'Morning', time: '9am - 12pm' },
  { label: 'Afternoon', time: '12pm - 04pm' },
  { label: 'Evening', time: '04pm - 09pm' },
];

export const states: { name: string; id: any }[] = [{ name: 'Maharashtra', id: 'Maharastra' }];

export const cities: { name: string; id: any; state_id: any }[] = [
  { name: 'Pune', id: 'pune', state_id: 'Maharastra' },
];

export const availableAddresses: { address: string; location: string }[] = [
  {
    address:
      'Sniffynose, Survey 201, DP Rd, near Siddheshwar Foods, Chandan Nagar, Malwadi, Hadapsar, Pune, Maharashtra 411028',
    location: 'hadapsar',
  },
  {
    address:
      'Sniffynose, Gate 4, Ground Floor Meera Crystal Survey No,13/14 15/19 NIBM Annex, next to Hills and Dales, Undri, Pune, Maharashtra 411060',
    location: 'undri',
  },
];
