import Address from "../addressDetails/address";
import Contact from "../contactDetails/contact";
import Footer from "../serviceFooter/footer";
import ServiceDetails from "../serviceDetails/serviceDetails";
import {
  Container,
  Buttons,
  ConfirmButton,
  CancelButton,
} from "./detailsStyle";
import { useEffect, useState } from "react";
import {
  BreedType,
  PetType,
  timeSlot,
  getCurrentDate,
  servicePlans,
  availableAddresses,
  states,
  cities,
} from "../utils";
import useCheckMobileScreen from "../../../common/hooks/useCheckMobileScreen";
import { createGroomingRequest } from "../../../service/api/createGroomingRequest";

const defaultPetDetails: any = {
  isOpen: true,
  name: "",
  petType: PetType[0],
  petBreed: BreedType[0],
  date: getCurrentDate(),
  timeSlot: timeSlot[0],
  service: servicePlans[0],
  addOns: [],
  notes: "",
};

const defaultContact: any = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
};

const defaultAddresses: any = {
  selectedAddress: availableAddresses[0],
  locationType: "grooming_center",
  selectedState: states[0].id,
  selectedCity: cities[0].id,
  locality: "",
  address_1: "",
  pinCode: "",
};

const BookService = () => {
  const [contact, setContact] = useState({ ...defaultContact });
  const [pets, setPets] = useState([{ ...defaultPetDetails }]);
  const [address, setAddress] = useState({ ...defaultAddresses });
  const isMobile = useCheckMobileScreen();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({
    firstName: null,
    phoneNumber: null,
  });

  const handleCancel = () => {
    window.location.reload();
  };

  const isContactInfoValidate = () => {
    let isValid = true;
    let errs: { [key: string]: any } = { firstName: null, phoneNumber: null };

    if (!contact?.firstName) {
      isValid = false;
      errs.firstName = "Please Enter First Name";
    }

    let phoneno = /^(\+91-|\+91|0)?\d{10}$/;
    if (!contact?.phoneNumber.match(phoneno)) {
      isValid = false;
      errs.phoneNumber = "Please Enter Valid Number";
    }
    setErrors(errs);
    return isValid;
  };

  const isAddressInfoValidate = () => {
    let isValid = true;
    let errs: { [key: string]: any } = { firstName: null, phoneNumber: null };
    if (address.locationType === "my_place") {
      if (!address.address_1) {
        isValid = false;
        errs.address_1 = "Please Enter Valid Address";
      }
      if (!address.locality) {
        isValid = false;
        errs.locality = "Please Enter Valid Area";
      }
      if (!address.pinCode) {
        isValid = false;
        errs.pinCode = "Please Enter Valid Pin Code";
      }
    }
    setErrors(errs);
    return isValid;
  };

  const validateOrSetTheError = () => {
    return isContactInfoValidate() && isAddressInfoValidate();
  };

  const handleConfirm = async (e: any) => {
    e.preventDefault();
    if (validateOrSetTheError()) {
      setLoading(true);
      const dataToStore = {
        contact,
        pets,
        address,
      };

      const result = await createGroomingRequest(dataToStore);
      console.log(result);
      if (!result?.data?.errorType) {
        localStorage.setItem("serviceDetails", JSON.stringify(dataToStore));
        window.location.href = "/confirm";
      } else {
        alert("Error while requesting, Please connect at +91-9284268622");
      }
      setLoading(false);

      return;
    } else {
      window.scroll(0, 200);
    }
  };

  const addAnotherPet = () => {
    const existingPets = [...pets].map((p) => {
      return { ...p, isOpen: false };
    });
    const addedPets = [...existingPets, { ...defaultPetDetails }];
    setPets(addedPets);
    window.scroll(0, 200);
  };

  const handlePetFieldChange = (petIndex: number, field: any, value: any) => {
    const updatedPets = [...pets];
    const hasPet = updatedPets[petIndex];
    if (!hasPet) return;

    updatedPets[petIndex][field] = value;
    setPets(updatedPets);
  };

  const handleContactFieldChange = async (field: any, value: any) => {
    const updatedcontact = { ...contact };
    updatedcontact[field] = value;
    setContact(updatedcontact);
    // setErrors({...errors, [field]: (value ? null : "Please enter the value")})
  };

  useEffect(() => {
    console.log(errors);
    if (errors.firstName === null && errors.phoneNumber === null) {
      return;
    }
    isContactInfoValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const handleAddressFieldChange = (field: any, value: any) => {
    const updatedaddress = { ...address };
    updatedaddress[field] = value;
    setAddress(updatedaddress);
  };

  const scrollToRef = () => {
    window.scroll(0, 0);
  };

  const handleAddStep = () => {
    const isFormValid =
      step === 2
        ? isContactInfoValidate()
        : step === 3
        ? isAddressInfoValidate()
        : true;
    if (isFormValid) {
      setStep(step + 1);
    }
    let t = setTimeout(() => {
      scrollToRef();
      clearTimeout(t);
    }, 100);
  };

  const handleSubtactStep = () => {
    setStep(step - 1);
    scrollToRef();
  };

  return (
    <Container>
      <>
        {(step === 2 || !isMobile) && (
          <Contact
            contact={contact}
            handleContactFieldChange={handleContactFieldChange}
            isMobile={isMobile}
            step={step}
            errors={errors}
          />
        )}
        {(step === 3 || !isMobile) && (
          <Address
            address={address}
            handleAddressFieldChange={handleAddressFieldChange}
            isMobile={isMobile}
            step={step}
            errors={errors}
          />
        )}
        {(step === 1 || !isMobile) && (
          <ServiceDetails
            addAnotherPet={addAnotherPet}
            handlePetFieldChange={handlePetFieldChange}
            pets={pets}
            isMobile={isMobile}
            step={step}
            errors={errors}
          />
        )}
      </>

      <Buttons>
        {isMobile ? (
          <>
            {step === 1 && (
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            )}
            {step > 1 && (
              <CancelButton onClick={handleSubtactStep}>Back</CancelButton>
            )}
            {step < 3 && (
              <ConfirmButton onClick={handleAddStep}>Next</ConfirmButton>
            )}
            {step === 3 && (
              <ConfirmButton
                onClick={handleConfirm}
                loading={loading}
                disabled={loading}
              >
                Confirm Booking
              </ConfirmButton>
            )}
          </>
        ) : (
          <>
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            <ConfirmButton
              onClick={handleConfirm}
              loading={loading}
              disabled={loading}
            >
              Confirm Booking
            </ConfirmButton>
          </>
        )}
      </Buttons>
    </Container>
  );
};

export default BookService;
