import Header from "../../view/header/header"
import Footer from "../../view/footer/footer"
import { LayoutContainer } from "./layoutStyle"

const Layout = ({ children }: any) => {
    return (
        <LayoutContainer>
            <Header />
            {children}
            <Footer />
        </LayoutContainer>
    )
}

export default Layout;