import { useQuery } from '@tanstack/react-query';
import { getProducts } from '../../service/api/products';

// export default function useProductInfo() {
//   return useQuery<any>({
//     queryKey: ['products'],
//     queryFn: async () => {
//       return await getProducts().then((products: any) => {
//         let newArray = products?.data?.map((obj: any, index: number) => {
//           return { ...obj, pid: obj.id, id: index + 1 };
//         });
//         return newArray;
//       });
//     },
//     refetchOnMount: false,
//   });
// }
export default function useProductInfo(param: any) {
    const {
        page = null,
        limit = null,
        sortOrder = null,
        categories = null,
        tags = null,
        search_keyword = null,
        coupon_code = null,
    } = param;
    return useQuery<any>({
        queryKey: [
            'products',
            page,
            limit,
            sortOrder,
            categories,
            tags,
            search_keyword,
            coupon_code,
        ].filter(param => param !== null),
        queryFn: async () => {
            const params: any = {
                ...(page !== null && { page }),
                ...(limit !== null && { limit }),
                ...(sortOrder !== null && { sort_order: sortOrder }),
                ...(categories !== null && { categories }),
                ...(tags !== null && { tags }),
                ...(search_keyword !== null && { search_keyword }),
                ...(coupon_code !== null && { coupon_code }),
            };

            const queryString = new URLSearchParams(params);
            return await getProducts(queryString).then((products: any) => {
                let prodcutsArray = products?.data?.data?.map((obj: any, index: number) => {
                    return { ...obj, pid: obj.id, id: index + 1 };
                });
                return { prodcutsArray, pagination: products?.data?.pagination };
            });
        },
        refetchOnMount: false,
    });
}
