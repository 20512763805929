import { styled, Typography } from '@mui/material';

export const Headline = styled(Typography)`
    color: #333;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    justify-content: center;
`;
export const SubHeading = styled(Typography)`
    color: #4c4c4c;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;
    font-family: Euclid-Circular-A-Regular;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 114.286% */
    display: flex;
    justify-content: center;
`;

export const MRPTitle = styled(Typography)`
  overflow: hidden;
  color: var(--Dark-Brown-Dark-Brown---100, #231F20);
  text-overflow: ellipsis;
  white-space: nowrap;
  fontFamily: Euclid-Circular-A-Semi-Bold;
  font-size: isMobile ? 1rem : 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  align-self: stretch;
  line-height: normal
`;

export const SubMRPTitle = styled(Typography)`
    overflow: hidden;
    color: var(--Dark-Brown-Dark-Brown---100, #231f20);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    line-height: 16px;
`;

export const CouponStyle = styled('span')({
    padding: '7px',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid #ECECEC',
    color: '#106E44',
    background: '#FFF',
});

export const Paragraph = styled(Typography)`
    color: #4c4c4c;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;
    font-family: Euclid-Circular-A-Regular;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 0.03125rem;
`;

export const Title = {
    color: 'var(--Black, #14141E)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontWeight: '500',
};

export const UserContainer = {
    bgcolor: 'background.paper',
    gap: '18px',
    display: 'flex',
    flexDirection: 'column',
};

export const UserBoxes = { display: 'flex', gap: '14px' };

export const UserPhoneAndEmailBox = {
    display: 'flex',
    gap: '14px',
    '@media screen and (max-width: 768px)': {
        gap: '4px',
        flexDirection: 'column',
    },
};

export const UserDetails = {
    display: 'flex',
    gap: '4px',
};

export const UserDetailsTitle = {
    color: 'var(--Accent-Black, #000)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
};

export const UserAddress = {
    color: 'var(--Neutral-Grey-Grey---50, #5C6370)',
    fontFamily: 'Euclid-Circular-A-Regular',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px;',
    letterSpacing: '0.13px',
};

export const UserPersonalDetails = {
    color: 'var(--Neutral-Grey-Grey---50, #222A34)',
    fontFamily: 'Euclid-Circular-A-Medium',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

export const UserBox = {
    display: 'flex',
    gap: '6px',
    flexDirection: 'column',
};

export const DividerStyle = {
    width: '100%',
    borderStyle: 'dashed',
    marginY: 2,
};
